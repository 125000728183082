import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Modal,
  Steps,
  Spin,
} from "antd";
import "antd/dist/reset.css"; // Ensure Ant Design styles are loaded
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { useSelector } from "react-redux";

const { Option } = Select;
const { Step } = Steps;

const UpdateAnimalDetails = ({
  open,
  setOpen,
  animal = {}, // Default to an empty object if `animal` is undefined
  setRefresh,
  refresh,
}) => {
  const { breeds, types } = useSelector((state) => state.animals);
  const [breedTypes, setBreedTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [animalPurpose, setAnimalPurpose] = useState([]);
  const [animalLocations, setAnimalLocation] = useState([]);
  const [acquisitionOrigin, setAcquisitionOrigin] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const fetchGenders = () => {
    setLoading(true);
    apiRequest(
      "get",
      `${API_BASE_URL}animals/genders`,
      "",
      "",
      (response) => {
        setGenders(response.data);
        setLoading(false);
      },
      () => {
        toast("Error Fetching Gender data");
        setLoading(false);
      }
    );
  };

  const getAnimalPurposes = () => {
    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-purposes`,
      "",
      "",
      (response) => setAnimalPurpose(response.data),
      () => toast.error("Error Occurred Fetching Data")
    );
  };

  const getAnimallocations = () => {
    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-locations`,
      "",
      "",
      (response) => setAnimalLocation(response.data),
      () => toast.error("Error Occurred Fetching Data")
    );
  };

  const getAcquisitionOrigins = () => {
    apiRequest(
      "get",
      `${API_BASE_URL}animals/purchase-origins`,
      "",
      "",
      (response) => setAcquisitionOrigin(response.data),
      () => toast.error("Error Occurred Fetching Data")
    );
  };

  const fetchBreedTypes = () => {
    apiRequest(
      "get",
      `${API_BASE_URL}animals/breedtypes`,
      "",
      "",
      (response) => setBreedTypes(response.breedTypes),
      () => toast.error("Failed to Fetch Breed Types")
    );
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const formattedValues = {
      ...values,
      purchase_origin: values.purchase_origin_id
        ? String(values.purchase_origin_id)
        : null,
      previous_owner: values.previous_owner
        ? JSON.stringify(values.previous_owner)
        : null,
    };

    apiRequest(
      "put",
      `${API_BASE_URL}animals/${animal?.id}`,
      formattedValues,
      "",
      (response) => {
        toast.success("Animal details updated successfully");
        setRefresh(!refresh);
        setLoading(false);
        setOpen(false);
        form.resetFields();
      },
      (error) => {
        setLoading(false);
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          toast.error("Error updating animal details");
        }
      }
    );
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    fetchBreedTypes();
    getAnimalPurposes();
    getAnimallocations();
    getAcquisitionOrigins();
    fetchGenders();
  }, []);

  if (loading) return <Spin size="large" />;
  const steps = [
    {
      title: "Basic Details",
      content: (
        <>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item
            name="date_of_birth"
            label="Date Of Birth"
            initialValue={animal.date_of_birth}
          >
            <Input type="date" defaultValue={animal.date_of_birth} />
          </Form.Item>

          <Form.Item
            name="gender_id"
            label="Gender"
            initialValue={animal.gender_id} // Set initial value for pre-selection
          >
            <Select placeholder="Select gender">
              {genders.map((gender) => (
                <Option key={gender.id} value={gender.id}>
                  {gender.gender}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="animal_type_id"
            label="Animal Type"
            initialValue={animal.animal_type_id} // Set initial value for pre-selection
          >
            <Select placeholder="Select animal type" disabled>
              {types.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.animal_types}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="animal_breed_id"
            label="Breed"
            initialValue={animal.animal_breed_id}
          >
            <Select placeholder="Select breed" disabled>
              {breeds.map((breed) => (
                <Option key={breed.id} value={breed.id}>
                  {breed.animal_breeds}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="animal_breed_type_id"
            label="Breed Type"
            initialValue={animal.animal_breed_type_id}
          >
            <Select placeholder="Select breed type" disabled>
              {breedTypes.map((breedType) => (
                <Option key={breedType.id} value={breedType.id}>
                  {breedType.animal_breed_type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="animal_purpose_id"
            label="Animal Purpose"
            initialValue={animal.animal_purpose_id} // Set initial value for pre-selection
          >
            <Select
              placeholder="Select purpose"
              disabled={animal.gender_id === 2}
            >
              {animalPurpose.map((purpose) => (
                <Option key={purpose.id} value={purpose.id}>
                  {purpose.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="animal_location_id"
            label="Animal Location"
            initialValue={animal.animal_location_id} // Populate the default value
          >
            <Select placeholder="Select location">
              {animalLocations.map((location) => (
                <Option key={location.id} value={location.id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["animal_location_details", "location"]}
            label="location"
            rules={[
              {
                required: form.getFieldValue("animal_location_details"),
                message:
                  "location is required when animal location details are provided",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["animal_location_details", "person"]}
            label="Person"
            rules={[
              {
                required: form.getFieldValue("animal_location_details"),
                message:
                  "Person is required when animal location details are provided",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["animal_location_details", "contact"]}
            label="Contact"
            rules={[
              {
                required: form.getFieldValue("animal_location_details"),
                message:
                  "Contact is required when animal location details are provided",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Origin Details",
      content: (
        <>
          <Form.Item
            name="purchase_origin_id"
            label="Purchase Origin"
            initialValue={animal.purchase_origin?.id || null} // Populate the default value
          >
            <Select placeholder="Select Purchase Origin">
              {acquisitionOrigin.map((origin) => (
                <Option key={origin.id} value={origin.id}>
                  {origin.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {animal?.purchase_origin?.id === 2 && (
            <>
              <Form.Item
                name="DOP"
                label="Date Of Purchase"
                initialValue={animal.DOP}
              >
                <Input type="date" defaultValue={animal.DOP} />
              </Form.Item>
              <Form.Item
                name={"purchase_price"}
                label="Purchase Price"
                initialValue={animal.purchase_price}
              >
                <InputNumber className="w-full" />
              </Form.Item>
            </>
          )}
          <Form.Item
            name={["previous_owner", "farmName"]}
            label="Person"
            rules={[
              {
                required: form.getFieldValue("previous_owner"),
                message:
                  "Person is required when animal location details are provided",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={["previous_owner", "contact"]}
            label="Contact"
            rules={[
              {
                required: form.getFieldValue("previous_owner"),
                message:
                  "Contact is required when animal location details are provided",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {animal?.purchase_origin === 3 && (
            <Form.Item label="mother" name={["dam", "name"]}>
              <Input disabled readOnly />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: "Additional Details",
      content: (
        <>
          <Form.Item name="tag_number" label="Tag Number">
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="color_markings"
            label="Color Markings"
            initialValue={animal.color_markings} // Populate the default value
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="ear_tag"
            label="Ear Tag"
            initialValue={animal.ear_tag} // Populate the default value
          >
            <Input />
          </Form.Item>

          <Form.Item name="weight" label="Weight">
            <InputNumber />
          </Form.Item>

          <Form.Item name="feeding_regimen" label="Feeding Regimen">
            <Input />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Modal
      title="Update Animal Details"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          className="border border-red-500 text-red-500 px-3 py-1 hover:bg-red-500 hover:text-white"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          className="border border-blue-500 text-blue-500 px-3 py-1 hover:bg-blue-500 hover:text-white"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={animal}
      >
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <div>{steps[currentStep].content}</div>
        <div className="steps-action">
          {currentStep > 0 && (
            <Button
              style={{ margin: "0 8px" }}
              className="border border-red-500 text-red-500 px-3 py-1 hover:bg-red-500 hover:text-white"
              onClick={prevStep}
            >
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              className="border border-green-500 text-green-500 px-3 py-1 hover:bg-green-500 hover:text-white"
              onClick={nextStep}
            >
              Next
            </Button>
          )}
        </div>
      </Form>
      {errors && (
        <div className="error-list">
          {Object.keys(errors).map((field) => (
            <div key={field} className="error text-red-600 bg-red-200">
              {errors[field].join(", ")}
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default UpdateAnimalDetails;
