import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Select } from "antd";
import imageCompression from "browser-image-compression";
import Nav from "../nav";
import { apiRequest, apiRequestWithImage } from "../../common/common";

import { API_BASE_URL } from "../../common/constants";

function RegisterNewAnimal() {
  const { breeds, types } = useSelector((state) => state.animals);
  const [breedTypes, setBreedTypes] = useState([]);
  const { Option } = Select;
  const navigate = useHistory();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [animals, setAnimals] = useState([]);
  const [fatherAnimals, setFatherAnimals] = useState([]);
  const [motherAnimals, setMotherAnimals] = useState([]);
  const [genders, setGenders] = useState([]);
  const [animalPurpose, setAnimalPurpose] = useState([]);
  const [animalLocation, setAnimalLocation] = useState([]);
  const [acquisationOrigin, setAcquisitionOrigin] = useState([]);
  const [healthStatus, setHealthStatus] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [animalOwners, setAnimalOwners] = useState([]);
  const [formData, setFormData] = useState({
    basicInfo: {
      name: "",
      gender: "",
      animalTypeId: "",
      animalBreedId: "",
      animalBreedType: "",
      tagNumber: "",
      dateOfBirth: "",
      animal_purpose: "",
      animal_location: "",
      animal_location_details: {
        location: "",
        person: "",
        contact: "",
      },
      owner: "",
    },
    characteristics: {
      colorMarkings: "",
      earTag: "",
      weight: "",
      milkLitres: "",
    },
    healthInfo: {
      healthStatus: "",
      feedingRegimen: "",
    },
    purchaseInfo: {
      purchaseOrigin: "",
    },
    previousOwner: {
      farmName: "",
      contact: "",
    },
    parentInfo: {
      dam_id: "",
      sire_id: "",
    },
    notes: "",
    images: [],
    purchase_price: "",
    DOP: "",
  });
  const [basicInfoErrors, setBasicInfoErrors] = useState({
    name: "",
    gender: "",
    animalTypeId: "",
    animalBreedId: "",
    tagNumber: "",
    animal_location: "",
  });

  const [xticsErrors, setXticsErrors] = useState({
    colorMarkings: "",
    notes: "",
  });

  const [healthErrors, setHealthErrors] = useState({
    healthStatus: "",
  });

  const [acquisitionErrors, setAcquisitionErrors] = useState({
    purchaseOrigin: "",
    dam_id: "",
    sire_id: "",
    farmName: "",
    contact: "",
  });

  const handleChange = (e, section, subField) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      if (subField) {
        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [name]: {
              ...prevState[section][name],
              [subField]: value,
            },
          },
        };
      } else {
        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [name]: value,
          },
        };
      }
    });

    // Additional logic for setting purchaseOrigin to 3 if isChecked is true and owner is selected
    if (isChecked && name === "owner") {
      // Here, you can fetch detailed information about the selected animal owner
      const selectedOwner = animalOwners.find(
        (owner) => parseInt(owner.id) === parseInt(value)
      );
      if (selectedOwner) {
        setFormData((prevState) => ({
          ...prevState,
          purchaseInfo: {
            ...prevState.purchaseInfo,
            purchaseOrigin: "3", // Assuming '3' corresponds to the Purchase Origin for Animal Owner
          },
          previousOwner: {
            ...prevState.previousOwner,
            farmName: selectedOwner.name, // Assuming farmName is the field name in selectedOwner
            contact: selectedOwner.contact, // Assuming contact is the field name in selectedOwner
          },
        }));
      }
    }
  };
  const handlePrevoiusOwnerChange = (e, section, subField) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      if (subField) {
        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [subField]: {
              ...prevState[section][subField],
              [name]: value,
            },
          },
        };
      } else {
        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [name]: value,
          },
        };
      }
    });
  };
  const handleNotesChange = (e, section) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      notes: value,
    }));
  };
  const handleDOPChange = (e, section) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      DOP: value,
    }));
  };
  const handlePurchasePriceChange = (e, section) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      purchase_price: value,
    }));
  };
  const fetchGenders = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setGenders(response.data);
      setLoading(false);
    };

    const onError = () => {
      toast("Error Fetching Gender data");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/genders`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getAnimalOwners = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimalOwners(response.data);
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      toast.error("Error Fetching  data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-owners`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleMotherChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      parentInfo: {
        ...prevState.parentInfo,
        dam_id: value,
      },
    }));
  };

  const handleFatherChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      parentInfo: {
        ...prevState.parentInfo,
        sire_id: value,
      },
    }));
  };

  const handleNext = () => {
    // Your existing validation functions
    const validationFunctions = [
      validateBasicInfo,
      validateXticsInfo,
      validateHealthInfo,
      validateAcquisitionInfo,
    ];
    const validationMessages = [
      "Please fill in all required fields in Basic Info",
      "Please fill in all required fields in Characteristics Info",
      "Please fill in all required fields in Health Information",
      "Please fill in all required fields in Acquisition Origin",
    ];

    const isFinalStep = step === validationFunctions.length; // Check if this is the final step

    if (isFinalStep) {
      // This is the final step
      const isValid = validationFunctions[step - 1](); // Validate the final step

      if (isValid) {
        // Handle completion or submission if validation passes
        registerAnimal(); // Call your submit function here
      } else {
        toast.error(validationMessages[step - 1]); // Display error message if validation fails
      }
    } else {
      // This is not the final step
      const isValid = validationFunctions[step - 1](); // Validate the current step

      if (isValid) {
        setStep(step + 1); // Proceed to the next step if validation passes
      } else {
        toast.error(validationMessages[step - 1]); // Display error message if validation fails
      }
    }
  };

  const validateBasicInfo = () => {
    let isValid = true;
    const errorsCopy = { ...basicInfoErrors };

    // Validate each field in basicInfo
    Object.keys(formData.basicInfo).forEach((key) => {
      if (
        key !== "dateOfBirth" &&
        key !== "animalBreedType" &&
        key !== "animal_location_details" &&
        key !== "animal_purpose" &&
        key !== "owner" &&
        formData.basicInfo[key] === ""
      ) {
        errorsCopy[key] = "This field is required";
        isValid = false;
      } else {
        errorsCopy[key] = "";
      }
    });

    setBasicInfoErrors(errorsCopy);
    return isValid;
  };
  const validateXticsInfo = () => {
    let isValid = true;
    const xticsErrorsCopy = { ...xticsErrors };

    // Check if at least one of the fields has text
    if (
      formData.notes === "" &&
      formData.characteristics.colorMarkings === ""
    ) {
      xticsErrorsCopy.notes = "At least one of these fields is required";
      xticsErrorsCopy.colorMarkings =
        "At least one of these fields is required";
      isValid = false;
    } else {
      // Reset error messages if at least one field has text
      xticsErrorsCopy.notes = "";
      xticsErrorsCopy.colorMarkings = "";
    }
    setXticsErrors(xticsErrorsCopy);

    return isValid;
  };

  const validateHealthInfo = () => {
    let isValid = true;
    const healthErrorsCopy = { ...healthErrors };
    if (formData.healthInfo.healthStatus === "") {
      healthErrorsCopy.healthStatus = "Health Status Required";
      isValid = false;
    } else {
      healthErrorsCopy.healthStatus = "";
    }
    setHealthErrors(healthErrorsCopy);

    return isValid;
  };

  const validateAcquisitionInfo = () => {
    let isValid = true;
    const acquisitionErrorsCopy = { ...acquisitionErrors };

    // Check if Acquisition Origin is selected
    if (formData.purchaseInfo.purchaseOrigin === "") {
      acquisitionErrorsCopy.purchaseOrigin = "Acquisition Origin Required";
      isValid = false;
    } else {
      acquisitionErrorsCopy.purchaseOrigin = "";
    }

    // Check additional fields based on the selected Acquisition Origin
    if (formData.purchaseInfo.purchaseOrigin === "bornOnFarm") {
      // Check if both Mother and Father are selected
      if (
        formData.parentInfo.dam_id === "" ||
        formData.parentInfo.sire_id === ""
      ) {
        acquisitionErrorsCopy.dam_id = "Mother Details Required";
        acquisitionErrorsCopy.sire_id = "Father Details Required";
        isValid = false;
      } else {
        acquisitionErrorsCopy.dam_id = "";
        acquisitionErrorsCopy.sire_id = "";
      }
    } else if (formData.purchaseInfo.purchaseOrigin === "purchased") {
      // Check if Farm Name and Contact are filled in
      if (formData.previousOwner.farmName === "") {
        acquisitionErrorsCopy.farmName = "Farm Name Required";
        isValid = false;
      } else {
        acquisitionErrorsCopy.farmName = "";
      }
      if (formData.previousOwner.contact === "") {
        acquisitionErrorsCopy.contact = "Contact Required";
        isValid = false;
      } else {
        acquisitionErrorsCopy.contact = "";
      }
    }

    setAcquisitionErrors(acquisitionErrorsCopy);
    return isValid;
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...formData.images];

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const options = {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 800, // Maximum width or height
            useWebWorker: true, // Use multi-threading for better performance
          };

          const compressedFile = await imageCompression(file, options);
          const previewURL = URL.createObjectURL(compressedFile);

          updatedImages.push({ file: compressedFile, previewURL });

          if (index === files.length - 1) {
            setFormData((prevState) => ({
              ...prevState,
              images: updatedImages.slice(0, 3), // Limit to maximum 3 images
            }));
          }
        } catch (error) {
          console.error("Error while compressing the image:", error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const registerAnimal = () => {
    const data = new FormData();
    data.append("name", formData.basicInfo.name);
    data.append("gender_id", formData.basicInfo.gender);
    data.append("animal_type_id", formData.basicInfo.animalTypeId);
    data.append("animal_breed_id", formData.basicInfo.animalBreedId);
    data.append("animal_breed_type_id", formData.basicInfo.animalBreedType);
    data.append("animal_purpose_id", formData.basicInfo.animal_purpose);
    data.append("animal_location_id", formData.basicInfo.animal_location);
    if (parseInt(formData.basicInfo.animal_location) === 2) {
      data.append(
        "animal_location_details[location]",
        formData.basicInfo.animal_location_details.location
      );
      data.append(
        "animal_location_details[person]",
        formData.basicInfo.animal_location_details.person
      );
      data.append(
        "animal_location_details[contact]",
        formData.basicInfo.animal_location_details.contact
      );
    }
    data.append("tag_number", formData.basicInfo.tagNumber);
    data.append("date_of_birth", formData.basicInfo?.dateOfBirth);
    data.append("weight", formData.characteristics.weight);
    data.append("color_markings", formData.characteristics.colorMarkings);
    data.append("milk_litres_production", formData.characteristics.milkLitres);
    data.append("health_status", formData.healthInfo.healthStatus);
    data.append("feeding_regimen", formData.healthInfo.feedingRegimen);
    data.append("purchase_origin", formData.purchaseInfo.purchaseOrigin);
    data.append(
      "previous_owner",
      JSON.stringify({
        farmName: formData.previousOwner.farmName,
        contact: formData.previousOwner.contact,
      })
    );
    data.append("ear_tag", formData.characteristics.earTag);
    data.append("location", formData.healthInfo.location);
    data.append("notes", formData.notes);
    data.append("sire_id", formData.parentInfo.sire_id);
    data.append("dam_id", formData.parentInfo.dam_id);
    data.append("owner", formData.basicInfo.owner);
    data.append("DOP", formData.DOP);
    data.append("purchase_price", formData.purchase_price);

    Object.keys(formData).forEach((key) => {
      if (key === "images") {
        formData.images.forEach((image) => {
          data.append("images[]", image.file);
        });
      }
    });
    setLoading(true);

    const onSuccess = () => {
      toast.success("Animal Registered");
      const navigateAfterDelay = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
        navigate.push("/animals");
      };
      navigateAfterDelay();
    };

    const onError = (error) => {
      setLoading(false);
      console.log(error);
      toast.error(
        error?.response?.data?.message?.date_of_birth === undefined
          ? error.response.data.message
          : error?.response?.data?.message?.date_of_birth[0]
      );
    };

    apiRequestWithImage(
      "post",
      `${API_BASE_URL}animals/create`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const getAnimals = () => {
    const onSuccess = (response) => {
      setAnimals(response.animals);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest("get", `${API_BASE_URL}animals`, "", "", onSuccess, onError);
  };
  const getAnimalPurposes = () => {
    const onSuccess = (response) => {
      setAnimalPurpose(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-purposes`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const getAnimallocations = () => {
    const onSuccess = (response) => {
      setAnimalLocation(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-locations`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getAcquisationOrigins = () => {
    const onSuccess = (response) => {
      setAcquisitionOrigin(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/purchase-origins`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getHealthStatuses = () => {
    const onSuccess = (response) => {
      setHealthStatus(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/health-status`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const fetchBreedTypes = () => {
    const onSuccess = (response) => {
      setBreedTypes(response.breedTypes);
    };

    const onError = (error) => {
      toast.error("Failed to Fetch Breed Types");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/breedtypes`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getAnimals();
    fetchBreedTypes();
    getAnimalPurposes();
    getAnimallocations();
    getAcquisationOrigins();
    getHealthStatuses();
  }, []);
  useEffect(() => {
    // Filter animals based on gender and animal type
    const femaleAnimals = animals.filter(
      (animal) => animal.gender.gender === "FEMALE"
    );
    const maleAnimals = animals.filter(
      (animal) => animal.gender.gender === "MALE"
    );

    // Filter animals based on selected animal type from basicInfo
    const selectedAnimalType = parseInt(formData.basicInfo.animalTypeId);
    const filteredFemaleAnimals = femaleAnimals.filter(
      (animal) => parseInt(animal.type.id) === selectedAnimalType
    );
    const filteredMaleAnimals = maleAnimals.filter(
      (animal) => parseInt(animal.type.id) === selectedAnimalType
    );

    // Update state with filtered animals
    setMotherAnimals(filteredFemaleAnimals);
    setFatherAnimals(filteredMaleAnimals);
  }, [animals, formData.basicInfo.animalTypeId]);

  useEffect(() => {
    fetchGenders();
    getAnimalOwners();
  }, []);
  useEffect(() => {
    if (formData.basicInfo.owner) {
      setFormData((prevState) => ({
        ...prevState,
        basicInfo: {
          ...prevState.basicInfo,
          tagNumber: prevState.basicInfo.name,
        },
      }));
    }
  }, [formData.basicInfo.owner, formData.basicInfo.name, setFormData]);

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className="text-2xl justify-center items-center">
            Register New Animal Form
          </h1>
          <div className=" max-w-2xl mx-auto">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {/* step one capture basic info */}
              {step === 1 && (
                <>
                  <h2 className="text-xl mb-4">
                    Step {step}: Basic Information
                  </h2>
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        basicInfoErrors.name && "border-red-500"
                      }`}
                      id="name"
                      name="name"
                      type="text"
                      placeholder=" "
                      value={formData.basicInfo.name}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    />
                    {basicInfoErrors.name && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.name}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Name *
                    </label>
                  </div>
                  {/* Gender */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        basicInfoErrors.gender && "border-red-500"
                      }`}
                      id="gender"
                      name="gender"
                      value={formData.basicInfo.gender}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    >
                      <option value="">Select Gender</option>
                      {genders?.map((gender, key) => {
                        return (
                          <option key={key} value={gender.id}>
                            {gender.gender}
                          </option>
                        );
                      })}
                    </select>
                    {basicInfoErrors.gender && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.gender}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Gender *
                    </label>
                  </div>
                  {/* Animal Type ID */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Animal Type *
                    </label>
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        basicInfoErrors.animalTypeId && "border-red-500"
                      }`}
                      id="animalType"
                      name="animalTypeId"
                      value={formData.basicInfo.animalTypeId}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    >
                      <option value="">Select Animal Type</option>
                      {types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.animal_types}
                        </option>
                      ))}
                    </select>
                    {basicInfoErrors.animalTypeId && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.animalTypeId}
                      </span>
                    )}
                  </div>
                  {/* Animal Breed ID */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Animal Breed *
                    </label>
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        basicInfoErrors.animalBreedId && "border-red-500"
                      }`}
                      id="animalBreed"
                      name="animalBreedId"
                      value={formData.basicInfo.animalBreedId}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    >
                      <option value="">Select Animal Breed</option>
                      {/* Filter  based on selected animal type */}
                      {breeds
                        .filter(
                          (breed) =>
                            parseInt(breed.animal_type_id) ===
                            parseInt(formData.basicInfo.animalTypeId)
                        )
                        .map((breed) => (
                          <option key={breed.id} value={breed.id}>
                            {breed.animal_breeds}
                          </option>
                        ))}
                    </select>
                    {basicInfoErrors.animalBreedId && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.animalBreedId}
                      </span>
                    )}
                  </div>
                  {/* breed type */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Animal Breed Types
                    </label>
                    <select
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
                      id="animalBreedType"
                      name="animalBreedType"
                      value={formData.basicInfo.animalBreedType}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    >
                      <option value="">Select Animal Breed Type</option>
                      {breedTypes
                        .filter(
                          (breedType) =>
                            parseInt(breedType.breed_id) ===
                            parseInt(formData.basicInfo.animalBreedId)
                        )
                        .map((breedType) => (
                          <option
                            key={breedType.id}
                            value={breedType.breed_type_id}
                          >
                            {breedType.breed_type_name}
                          </option>
                        ))}
                    </select>
                    {basicInfoErrors.animalBreedId && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.animalBreedId}
                      </span>
                    )}
                  </div>
                  {/* animal purpose for male */}
                  {parseInt(formData.basicInfo.gender) === 1 && (
                    <div className="mb-4 relative h-11 w-full min-w-[200px]">
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Animal Purpose *
                      </label>
                      <select
                        className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                          basicInfoErrors.animal_purpose && "border-red-500"
                        }`}
                        id="animal_purpose"
                        name="animal_purpose"
                        value={formData.basicInfo.animal_purpose}
                        onChange={(e) => handleChange(e, "basicInfo")}
                      >
                        <option value="">Select Animal Purpose</option>
                        {animalPurpose.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                      {basicInfoErrors.animal_purpose && (
                        <span className="text-xs text-red-500 ">
                          {basicInfoErrors.animal_purpose}
                        </span>
                      )}
                    </div>
                  )}
                  {/* location of animal */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Animal Location *
                    </label>
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        basicInfoErrors.animal_location && "border-red-500"
                      }`}
                      id="animal_location"
                      name="animal_location"
                      value={formData.basicInfo.animal_location}
                      onChange={(e) => handleChange(e, "basicInfo")}
                    >
                      <option value="">Select Animal Location</option>
                      {animalLocation.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    {basicInfoErrors.animal_location && (
                      <span className="text-xs text-red-500 ">
                        {basicInfoErrors.animal_location}
                      </span>
                    )}
                  </div>
                  {/* location details */}
                  {parseInt(formData.basicInfo.animal_location) === 2 && (
                    <>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <input
                          type="text"
                          name="animal_location_details"
                          placeholder=" "
                          value={
                            formData.basicInfo.animal_location_details.location
                          }
                          onChange={(e) =>
                            handleChange(e, "basicInfo", "location")
                          }
                          className="mt-1 p-2 border-gray-300 peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                        />
                        <label className="block text-sm font-medium text-gray-700 before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Location
                        </label>
                      </div>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <input
                          type="text"
                          name="animal_location_details"
                          placeholder=""
                          value={
                            formData.basicInfo.animal_location_details.person
                          }
                          onChange={(e) =>
                            handleChange(e, "basicInfo", "person")
                          }
                          className="mt-1 p-2 border-gray-300 peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                        />
                        <label className="block text-sm font-medium text-gray-700 before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Name of Person/farm with the animal
                        </label>
                      </div>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <input
                          type="number"
                          name="animal_location_details"
                          placeholder=""
                          value={
                            formData.basicInfo.animal_location_details.contact
                          }
                          onChange={(e) =>
                            handleChange(e, "basicInfo", "contact")
                          }
                          className="mt-1 p-2 border-gray-300 peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                        />
                        <label className="block text-sm font-medium text-gray-700 before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Contact
                        </label>
                      </div>
                    </>
                  )}
                  {/* owner */}
                  <div className="p-4 mb-4 border border-gray-300 rounded-lg">
                    <div className="flex items-center mb-4">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-5 w-5 text-green-600 rounded transition duration-150 ease-in-out"
                      />
                      <span className="ml-2 text-gray-700 font-medium">
                        Animal Not Owned by Farm Owner
                      </span>
                    </div>
                    {isChecked && (
                      <div className="relative h-11 w-full min-w-[200px]">
                        <select
                          className="peer h-full w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-normal text-gray-700 shadow-sm outline-none transition duration-150 ease-in-out focus:border-green-500 focus:ring-2 focus:ring-green-500"
                          id="owner"
                          name="owner"
                          value={formData.basicInfo.owner}
                          onChange={(e) => handleChange(e, "basicInfo")}
                        >
                          <option value="">Select Animal Owner</option>
                          {animalOwners?.map((owner, index) => (
                            <option value={owner.id} key={index}>
                              {owner.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  {/* Tag Number */}
                  {!isChecked && (
                    <div className="mb-4 relative h-11 w-full min-w-[200px]">
                      <input
                        className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                          basicInfoErrors.tagNumber && "border-red-500"
                        }`}
                        id="tagNumber"
                        name="tagNumber"
                        type="text"
                        placeholder=" "
                        value={formData.basicInfo.tagNumber}
                        onChange={(e) => handleChange(e, "basicInfo")}
                      />
                      {basicInfoErrors.tagNumber && (
                        <span className="text-xs text-red-500 block ">
                          {basicInfoErrors.tagNumber}
                        </span>
                      )}
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Tag Number *
                      </label>
                    </div>
                  )}

                  {/* Date of Birth */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      value={formData.basicInfo.dateOfBirth}
                      onChange={(e) => handleChange(e, "basicInfo")}
                      placeholder=" "
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Date of Birth
                    </label>
                  </div>
                </>
              )}
              {/* step two capture characteristics */}
              {step === 2 && (
                <>
                  <h2 className="text-xl mb-4">Step {step}: Characteristics</h2>
                  {/* Color Markings */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        xticsErrors.colorMarkings && "border-red-500"
                      }`}
                      id="colorMarkings"
                      name="colorMarkings"
                      type="text"
                      placeholder=" "
                      value={formData.characteristics.colorMarkings}
                      onChange={(e) => handleChange(e, "characteristics")}
                    />

                    {xticsErrors.colorMarkings && (
                      <span className="text-xs text-red-500 ">
                        {xticsErrors.colorMarkings}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Color Markings
                    </label>
                  </div>
                  {/* Ear Tag */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                      id="earTag"
                      name="earTag"
                      type="text"
                      placeholder=" "
                      value={formData.characteristics.earTag}
                      onChange={(e) => handleChange(e, "characteristics")}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Ear Tag
                    </label>
                  </div>
                  {/* Weight */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                      id="weight"
                      name="weight"
                      type="text"
                      placeholder=" "
                      value={formData.characteristics.weight}
                      onChange={(e) => handleChange(e, "characteristics")}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Weight
                    </label>
                  </div>
                  {parseInt(formData.basicInfo.gender) === 2 && (
                    <div className="mb-4 relative h-11 w-full min-w-[200px]">
                      <input
                        className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                        id="milkLitres"
                        name="milkLitres"
                        type="number"
                        placeholder=" "
                        value={formData.characteristics.milkLitres}
                        onChange={(e) => handleChange(e, "characteristics")}
                      />
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Milk litres Produced Daily
                      </label>
                    </div>
                  )}
                  <div className="mb-4 relative h-32 w-full min-w-[200px]">
                    <textarea
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        xticsErrors.notes && "border-red-500"
                      }`}
                      placeholder=" "
                      value={formData.notes}
                      onChange={handleNotesChange}
                      name="notes"
                    ></textarea>
                    {xticsErrors.notes && (
                      <span className="text-xs text-red-500 ">
                        {xticsErrors.notes}
                      </span>
                    )}

                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Description
                    </label>
                  </div>
                </>
              )}
              {/* capture health info */}
              {step === 3 && (
                <>
                  <h2 className="text-xl mb-4">
                    Step {step}: Health Information
                  </h2>
                  {/* Health Status */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        healthErrors.healthStatus && "border-red-500"
                      }`}
                      id="healthStatus"
                      name="healthStatus"
                      value={formData.healthInfo.healthStatus}
                      onChange={(e) => handleChange(e, "healthInfo")}
                    >
                      <option value="">Select Health Status</option>
                      {healthStatus?.map((status) => (
                        <option value={status.id}>{status.name}</option>
                      ))}
                    </select>

                    {healthErrors.healthStatus && (
                      <span className="text-xs text-red-500 ">
                        {healthErrors.healthStatus}
                      </span>
                    )}

                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Health Status
                    </label>
                  </div>
                  {/* Feeding Regimen */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                      id="feedingRegimen"
                      name="feedingRegimen"
                      type="text"
                      placeholder=" "
                      value={formData.healthInfo.feedingRegimen}
                      onChange={(e) => handleChange(e, "healthInfo")}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Feeding Regimen
                    </label>
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <h2 className="text-xl mb-4">
                    Step {step}: Acquisition Origin
                  </h2>

                  {/* Purchase Origin */}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <select
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        acquisitionErrors.purchaseOrigin && "border-red-500"
                      }`}
                      id="purchaseOrigin"
                      name="purchaseOrigin"
                      value={formData.purchaseInfo.purchaseOrigin}
                      onChange={(e) => handleChange(e, "purchaseInfo")}
                    >
                      <option value="">Select Acquisition Origin</option>
                      {acquisationOrigin.map((origin) => (
                        <option value={origin.id}>{origin.name}</option>
                      ))}
                    </select>
                    {acquisitionErrors.purchaseOrigin && (
                      <span className="text-xs text-red-500 ">
                        {acquisitionErrors.purchaseOrigin}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Acquisition Origin
                    </label>
                  </div>
                  {parseInt(formData.purchaseInfo.purchaseOrigin) === 1 && (
                    <>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <label className="px-3">Mother Details :</label>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          className={`
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                             acquisitionErrors.dam_id && "border-red-500"
                           }`}
                          placeholder="Select Mother"
                          onChange={handleMotherChange}
                          filterOption={
                            (input, option) =>
                              option.value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 // Search by tag number
                          }
                        >
                          {/* Render options for mothers based on the selected gender and animal type */}
                          {motherAnimals.map((animal) => (
                            <Option key={animal.id} value={animal.tag_number}>
                              {animal.name} ({animal.tag_number})
                            </Option>
                          ))}
                        </Select>
                        {acquisitionErrors.dam_id && (
                          <span className="text-xs text-red-500 ">
                            {acquisitionErrors.dam_id}
                          </span>
                        )}
                      </div>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <label className=" px-3">Father Details :</label>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select father"
                          className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                            acquisitionErrors.sire_id && "border-red-500"
                          }`}
                          onChange={handleFatherChange}
                          filterOption={
                            (input, option) =>
                              option.value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 // Search by tag number
                          }
                        >
                          <Option value="Artificial Insemination">
                            Artificial Insemination
                          </Option>
                          <Option value="Outside Breeding">
                            Outside Breeding
                          </Option>
                          {fatherAnimals.map((animal) => (
                            <Option key={animal.id} value={animal.tag_number}>
                              {animal.name} ({animal.tag_number})
                            </Option>
                          ))}
                        </Select>
                        {acquisitionErrors.sire_id && (
                          <span className="text-xs text-red-500 ">
                            {acquisitionErrors.sire_id}
                          </span>
                        )}
                      </div>
                    </>
                  )}

                  {/* Farm Name - Displayed if Purchase Origin is 'Purchased' */}
                  {(parseInt(formData.purchaseInfo.purchaseOrigin) === 2 ||
                    parseInt(formData.purchaseInfo.purchaseOrigin) === 3) && (
                    <>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <input
                          className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                            acquisitionErrors.farmName && "border-red-500"
                          }`}
                          id="farmName"
                          name="farmName"
                          type="text"
                          placeholder=" "
                          value={formData.previousOwner.farmName}
                          onChange={(e) =>
                            handlePrevoiusOwnerChange(e, "previousOwner")
                          }
                        />
                        {acquisitionErrors.farmName && (
                          <span className="text-xs text-red-500">
                            {acquisitionErrors.farmName}
                          </span>
                        )}
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Farm Name
                        </label>
                      </div>
                      <div className="mb-4 relative h-11 w-full min-w-[200px]">
                        <input
                          className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                            acquisitionErrors.contact && "border-red-500"
                          }`}
                          id="contact"
                          name="contact"
                          type="text"
                          placeholder=" "
                          value={formData.previousOwner.contact}
                          onChange={(e) =>
                            handlePrevoiusOwnerChange(e, "previousOwner")
                          }
                        />
                        {acquisitionErrors.contact && (
                          <span className="text-xs text-red-500">
                            {acquisitionErrors.contact}
                          </span>
                        )}
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Contact
                        </label>
                      </div>
                      {(
                        <div className="mb-4 relative h-11 w-full min-w-[200px]">
                          <input
                            className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                            id="DOP"
                            name="DOP"
                            type="date"
                            value={formData.DOP}
                            onChange={handleDOPChange}
                            placeholder=" "
                          />
                          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                            Purchase Date / Date Recieved
                          </label>
                        </div>
                      )}
                      {parseInt(formData.basicInfo.gender) === 1 &&
                        parseInt(formData.purchaseInfo.purchaseOrigin) === 2 &&
                         (
                          <>
                            <div className="mb-4 relative h-11 w-full min-w-[200px]">
                              <input
                                className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                                id="purchase_price"
                                name="purchase_price"
                                type="number"
                                placeholder=" "
                                value={formData.purchase_price}
                                onChange={handlePurchasePriceChange}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Purchase Price
                              </label>
                            </div>
                          </>
                        )}
                    </>
                  )}
                  <h1 className="text-xl mb-4">Upload Animal Images</h1>
                  <div className="max-w-md mx-auto p-4">
                    <div className="mb-4">
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleImageChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                      />
                    </div>
                    <div className="mt-2 flex space-x-4">
                      {formData.images.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={image.previewURL}
                            alt={`Imag ${index}`}
                            className="h-24 w-24 object-cover rounded-md"
                          />
                          <button
                            type="button"
                            onClick={() => handleDeleteImage(index)}
                            className="absolute top-0 right-0 rounded-full bg-white p-1 shadow-sm"
                          >
                            <svg
                              className="h-4 w-4 text-red-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {step > 1 && (
                <button
                  className="mt-6 mr-2 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={handlePrevious}
                  disabled={loading}
                >
                  Previous
                </button>
              )}
              {step < 4 && (
                <button
                  className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={handleNext}
                >
                  Next
                </button>
              )}
              {step === 4 && (
                <button
                  type="button"
                  onClick={handleNext}
                  disabled={loading}
                  className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                >
                  {loading ? (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default RegisterNewAnimal;
