import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";

// Define async thunk for fetching user branches
export const fetchUserBranches = createAsyncThunk(
  "branches/fetchUserBranches",
  async (_, { getState }) => {
    try {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await axios.get(
        `${API_BASE_URL}farms/farm/1/branches`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Define async thunk for fetching all branches
export const fetchAllBranches = createAsyncThunk(
  "branches/fetchAllBranches",
  async (_, { getState }) => {
    try {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await axios.get(
        `${API_BASE_URL}farms/1/branches`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    userBranches: [],
    allBranches: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBranches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserBranches.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userBranches = action.payload;
      })
      .addCase(fetchUserBranches.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllBranches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBranches.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allBranches = action.payload;
      })
      .addCase(fetchAllBranches.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default branchesSlice.reducer;
