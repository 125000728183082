import React, { useState } from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);


  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images?.map((image, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg shadow-lg"
            onClick={() => openLightbox(index)}
          >
            <img
              src={`${process.env.REACT_APP_PROD_IMG_URL}${image}`}
              alt={`Animal ${index + 1}`}
              className="w-full h-full object-cover cursor-pointer"
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={`${process.env.REACT_APP_PROD_IMG_URL}${images[photoIndex]}`}
          nextSrc={`${process.env.REACT_APP_PROD_IMG_URL}${images[(photoIndex + 1) % images.length]}`}
          prevSrc={`${process.env.REACT_APP_PROD_IMG_URL}${images[(photoIndex + images.length - 1) % images.length]}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default ImageGallery;
