import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

const { Option } = Select;
function AnimalTransferModal(props) {
  const { farmBranches } = useSelector((state) => state.branches.allBranches);
  const [formData, setFormData] = useState({ animal_ids: [], branch_id: "" });
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    props.setOpen(false);
  };
  const animalOptions = props.animals !==undefined  &&  props.animals?.map((animal) => ({
    value: animal.id,
    label: `${animal.tag_number} - ${animal.name}`,
  }));
  const handleChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      branch_id: value,
    }));
  };

  const transferAnimals = () => {
    setLoading(true);
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({ animal_ids: [], branch_id: "" });
      setLoading(false);
      props.setRefresh(!props.refresh);
      props.setOpen(false);
    };

    const onError = (error) => {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error Occured"
      );
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}animals/transfer`,
      formData,
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      animal_ids: selectedAnimals,
    }));
  }, [selectedAnimals, setFormData]);
  return (
    <div>
      <Modal
        title="Transfer Animals to another Farm branch"
        open={props.open}
        footer={[
          <button
            type="button"
            onClick={transferAnimals}
            className=" text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Transfer Animals
          </button>,
          <button
            type="button"
            onClick={handleCancel}
            className=" text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Close
          </button>,
        ]}
      >
        <div className="p-4">
          <Select
            showSearch
            placeholder="Select branch"
            optionFilterProp="children"
            className="w-full"
            onChange={handleChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {farmBranches?.map((branch) => (
              <Option key={branch.id} value={branch.id}>
                {branch.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="p-4">
          <Select
            mode="multiple"
            showSearch
            placeholder="Select animals"
            optionFilterProp="label"
            className="w-full"
            options={animalOptions}
            onChange={setSelectedAnimals}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
      </Modal>
    </div>
  );
}

export default AnimalTransferModal;
