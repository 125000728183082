import React, { useState } from "react";
import { Modal } from "antd";

function BreedingModal(props) {
    const [loading, setLoading] = useState(false);
  const handleOk = () => {};
  const handleCancel = () => {
    props.setOpen(false);
  };
  return (
    <div>
      <Modal
        open={props.open}
        title="Add Breeding History"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
            <button
            key="back"
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            key="submit"
            className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </button>,
        ]}
      >
        <p className="text-2xl items-center">Breeding Form</p>
      </Modal>
    </div>
  );
}

export default BreedingModal;
