import React, { useState, useEffect } from "react";
import { Table, Skeleton, Empty } from "antd";
import { toast } from "react-toastify";
import Nav from "../nav";
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import AddOtherAnimalOwnersModal from "./AddOtherAnimalOwnersModal";

function ViewOtherAnimalOwners() {
  const [animalOwners, setAnimalOwners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openOtherAnimalOwnerModal, setOpenOtherAnimalOwnerModal] =
    useState(false);
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      width:30,
      render: (text, record, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Farm Name",
      dataIndex: "farm_name",
      key: "farm_name",
      width: 150,
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: 150,
    },
  ];

  const getAnimalOwners = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimalOwners(response.data);
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      toast.error("Error Fetching  data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-owners`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAnimalOwners();
  }, []);
  useEffect(() => {
    getAnimalOwners();
  }, [refresh]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className=" mt-14 p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
          <div className="flex flex-wrap justify-end">
            <div className="relative">
              <button  onClick={()=>{setOpenOtherAnimalOwnerModal(true)}}  className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
>
                + Add New Animal Owner
              </button>
            </div>
          </div>
          <div className="">
            <Table
              dataSource={loading ? [] : animalOwners}
              columns={columns}
              scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
              pagination={false}
              className=" whitespace-nowrap"
              locale={{
                emptyText: loading ? (
                  <Skeleton active paragraph={{ rows: 16 }} />
                ) : (
                  <Empty />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <AddOtherAnimalOwnersModal
        open={openOtherAnimalOwnerModal}
        setOpen={setOpenOtherAnimalOwnerModal}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
}

export default ViewOtherAnimalOwners;
