import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import Nav from "../nav";
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function RegisterBreedType() {
  const { breeds, types } = useSelector((state) => state.animals);
  const [formData, setFormData] = useState({
    animal_breed_id: "",
    name: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  /**
   * Transform roles data into options array
   * Find the corresponding animal type for the breed
   * Create the label including both the animal type and breed
   * @returns options object
   */
  const options = breeds?.map((breed) => {
    const animalType = types.find((type) => parseInt(type.id )=== parseInt(breed.animal_type_id));
    const label = `${animalType?.animal_types} - ${breed.animal_breeds}`;

    return {
      value: breed.id.toString(),
      label: label,
    };
  });

  /**
   * Filter `option.label` match the user type `input`
   * @param {*} input
   * @param {*} option
   * @returns search result
   */
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChange = (value) => {
    setFormData({
      ...formData,
      animal_breed_id: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    setLoading(true);

    if (!formData.animal_breed_id) {
      newErrors.animal_breed_id = "breed is Required";
    }
    if (!formData.name) {
      newErrors.name = "name of breed type is  required";
    }
    setErrorMessage(newErrors);
    setLoading(false);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let data = JSON.stringify(formData);

    const onSuccess = (response) => {
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(
        error?.response?.data?.error?.name
        ? error?.response?.data?.error?.name[0]
          : error?.response?.data?.error
      );
      setLoading(false);
    };

    await apiRequest(
      "post",
      `${API_BASE_URL}animals/create/breed-types`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  return (
    <div>
      <Nav />{" "}
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className="text-2xl">Register Type Of breed</h1>
          <div className="max-w-2xl mx-auto">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className=" mb-10 relative h-11 w-full min-w-[200px]">
                <label className="">Select User Type</label>
                <div className="relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Breed"
                    optionFilterProp="children"
                    className={` w-full ${
                      errorMessage.animal_breed_id && "border-red-500!important"
                    }`}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    size="large"
                    options={options}
                  />
                  {errorMessage.animal_breed_id && (
                    <span className="text-xs text-red-500 ">
                      {errorMessage.animal_breed_id}
                    </span>
                  )}
                </div>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 ${
                    errorMessage.name && "border-red-500"
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  placeholder=" "
                  onChange={handleChange}
                  value={formData.username}
                />
                {errorMessage.name && (
                  <span className="text-xs text-red-500 ">
                    {errorMessage.name}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Name *
                </label>
              </div>

              <button
                className="mt-6 block w-full select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                data-ripple-light="true"
                onClick={handleSubmit}
                disabled={loading} // Disable the button when loading is true
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                ) : (
                  "Register Breed"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>{" "}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default RegisterBreedType;
