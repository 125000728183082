import React, { useState, useEffect } from "react";
import Nav from "../nav";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import ViewAnimalDetails from "./ViewAnimalDetails";
import { Modal } from "antd";

function QuickAccess() {
  const [tagNumber, setTagNumber] = useState("");
  const [animal, setAnimal] = useState(null);
  const [openAnimalDetailModal, setOpenAnimalDetailModal] = useState(false);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [rollCallStatuses, setRollCallStatuses] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rollcallModal, setRollCallModal] = useState(false);
  const [comment, setComment] = useState("");
  const [isTagValid, setIsTagValid] = useState(false);
  const [hasBeenReminded, setHasBeenReminded] = useState(false);

  // Check if roll call has been initiated
  // Function to check if a roll call has been initiated
  const isRollCallInitiated = () => {
    // Check if any animal has a status other than "UNROLLCALLED"
    return statusUpdates.some(
      (update) => update.status_id !== getStatusId("UNROLLCALLED")
    );
  };

  // Format the current date as "Wed 20th October 2024"
  const getFormattedDate = () => {
    const date = new Date();
    const options = {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };

 


  // Function to check if entered tagNumber is valid
  const checkTagNumberValidity = () => {
    const isValid = animals.some((animal) => animal.tag_number === tagNumber);
    setIsTagValid(isValid);
  };

  const handleRollCallModal = () => {
    if (isTagValid) {
      setRollCallModal(true);
    } else {
      toast.info("Invalid Tagnumber ");
    }
  };
  const handleViewDetails = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimal(response.animal);
      // toast.success("Details Fetched");
      setLoading(false);

      // setIsTagValid(true);
    };
    const onError = (error) => {
      // toast.error(error?.response?.data?.message);
      // setIsTagValid(false);
      console.log(error);
      setLoading(false);
    };
    await apiRequest(
      "get",
      `${API_BASE_URL}animals/${tagNumber}`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const fetchRollCallStatuses = () => {
    const onSuccess = (response) => {
      setRollCallStatuses(response?.data);
    };

    const onError = (error) => {
      console.log(error);
      toast.error(error?.response?.data.message);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/rollcall/statuses`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const getStatusId = (statusText) => {
    const status = rollCallStatuses.find(
      (status) => status.status === statusText
    );
    return status ? parseInt(status.id) : null;
  };

  const getAnimals = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setAnimals(response.animals);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error.response.data.error);
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}animals`, "", "", onSuccess, onError);
  };

  const handleStatusChange = (tagNumber, statusId, comment) => {
    // const rollCallName = getFormattedDate();

    // Update status and comment in the statusUpdates state
    setStatusUpdates((prevStatusUpdates) => {
      const animalIndex = prevStatusUpdates.findIndex(
        (animal) => animal.tag_number === tagNumber
      );

      if (animalIndex !== -1) {
        const newStatusUpdates = [...prevStatusUpdates];
        newStatusUpdates[animalIndex] = {
          ...newStatusUpdates[animalIndex],
          status_id:
            statusId !== null
              ? statusId
              : getStatusId("UNROLLCALED")
              ? getStatusId("UNROLLCALED")
              : 3,
          comment: comment || newStatusUpdates[animalIndex].comment, // Update comment if provided
          // roll_call_name: rollCallName,
        };

        // Update localStorage with new status updates
        localStorage.setItem("rollcalllogs", JSON.stringify(newStatusUpdates));

        return newStatusUpdates;
      }
      return prevStatusUpdates;
    });
  };

  const handleEndRollcall = () => {
    setLoading(true);
    const data = JSON.stringify(statusUpdates);
    const onSuccess = (response) => {
      toast.success("Roll call performed");
      setLoading(false);
      setStatusUpdates([]);
      localStorage.removeItem("rollcalllogs");
    };

    const onError = (error) => {
      toast.error(
        error?.response
          ? error.response.data.error
          : "Something went wrong. Please try again later."
      );
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}animals/attendance`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const handleView = async () => {
    setLoading(true);
    if (tagNumber === "") {
      toast.error("No tag number was entered");
      setLoading(false);
    } else {


      await handleViewDetails();

      // Only open the modal if the animal has a valid ID
      if (animal?.id) {

         setOpenAnimalDetailModal(true);
      } else {
        toast.error("Animal details could not be fetched");
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    getAnimals();
    fetchRollCallStatuses();
    // checkTagNumberValidity();
  }, []);
  // Check validity when tag number changes
  useEffect(() => {
    checkTagNumberValidity();
  }, [tagNumber, animals]);
  useEffect(() => {
    /**
     * Check if there are any roll call logs stored in local storage
     * If roll call logs exist in local storage, set statusUpdates to those logs
     * If no roll call logs exist, Initialize statusUpdates with all animals having status ID for  (UNROLLCALLED)
     */

    const storedRollCallLogs = JSON.parse(localStorage.getItem("rollcalllogs"));

    if (storedRollCallLogs && storedRollCallLogs.length > 0) {
      setStatusUpdates(storedRollCallLogs);
    } else {
      const initialStatusUpdates = animals.map((animal) => ({
        tag_number: animal.tag_number,
        status_id: getStatusId("UNROLLCALED"),
        roll_call_name: getFormattedDate(),
        comment: "",
        date: new Date().toISOString().split("T")[0],
      }));
      setStatusUpdates(initialStatusUpdates);
    }
  }, [animals, rollCallStatuses]);
  useEffect(() => {
    // Notify user to end roll call if initiated
    if (isRollCallInitiated() && !hasBeenReminded) {
      toast.warn("Don't forget to end the roll call if you've conducted one!");
      setHasBeenReminded(true);
    }
  }, [statusUpdates, hasBeenReminded]);

  useEffect(() => {
    
   handleViewDetails()
  }, [tagNumber]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="fixed inset-0 bg-gradient-to-br from-gray-100 via-gray-200 to-gray-300 bg-opacity-20 z-10 flex justify-center items-center animate-fadeIn">
          <div className="bg-white p-8 rounded-xl mx-auto shadow-2xl max-w-md w-full animate-slideUp">
            <h2 className="text-2xl font-semibold text-center mb-6">
              Enter Animal Tag Number
            </h2>
            <div>
              <div className="mb-4">
                <label className="block text-gray-700 text-lg mb-2">
                  Tag Number
                </label>
                <input
                  id="tagNumber"
                  name="tagNumber"
                  type="tagNumber"
                  className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  value={tagNumber}
                  onChange={(e) => setTagNumber(e.target.value)}
                  required
                />
              </div>
              {
                <div className="flex flex-row space-x-1">
                  <button
                    type="button"
                    onClick={handleView}
                    className="w-1/2 py-3 rounded-lg text-white bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-600 hover:to-blue-700 transition-all duration-300 shadow-lg"
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
                    ) : (
                      "View Details"
                    )}
                  </button>

                  <button
                    type="button"
                    onClick={handleRollCallModal}
                    className="w-1/2 py-3 rounded-lg text-white bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 transition-all duration-300 shadow-lg"
                    disabled={loading}
                  >
                    Rollcall
                  </button>

                  <button
                    type="button"
                    onClick={handleEndRollcall}
                    className={`w-1/2 py-3 rounded-lg text-white ${
                      isRollCallInitiated()
                        ? "bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                        : "bg-gray-300 cursor-not-allowed"
                    } transition-all duration-300 shadow-lg`}
                    disabled={!isRollCallInitiated()}
                  >
                    End Rollcall
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {console.log(animal)}
     {animal?.id && <ViewAnimalDetails
        open={openAnimalDetailModal}
        setOpen={setOpenAnimalDetailModal}
        id={animal.id}
      />}
      <Modal
        open={rollcallModal}
        onCancel={() => setRollCallModal(false)}
        footer={
          <div className="flex justify-center mb-2">
            <button
              onClick={() => {
                handleStatusChange(tagNumber, getStatusId("PRESENT"), comment);
                setRollCallModal(false);
              }}
              className=" p-2 m-2 px-4 py-2 bg-green-500 rounded-md text-white"
            >
              Present
            </button>
            <button
              onClick={() => {
                handleStatusChange(tagNumber, getStatusId("ABSENT"), comment);
                setRollCallModal(false);
              }}
              className="p-2  m-2 px-4 py-2 bg-red-500 rounded-md text-white"
            >
              Absent
            </button>
          </div>
        }
      >
        <div className="text-center">
          <p>Mark attendance and leave a comment below:</p>
          <input
            type="text"
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full px-3 py-2 border rounded-md mt-4"
          />
        </div>
      </Modal>
    </div>
  );
}

export default QuickAccess;
