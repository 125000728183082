import React, { useState, useRef, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Modal, Button, Input, Space, Table, Checkbox, Select } from "antd";
import Highlighter from "react-highlight-words";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function AnimalsVaccinationModal(props) {
  const {
    open,
    lastsavedVaccinationLog,
    animals,
    setOpen,
    setLastSavedVaccinationLog,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [vaccinationName, setVaccinationName] = useState("");
  const [vaccinationStatuses, setVaccinationStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [step, setStep] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vaccineType, setVaccineType] = useState([]);
  const [selectedVaccineType, setSelectedVaccineType] = useState(null);
  const [animalsVaccinatedCount, setAnimalsVaccinatedCount] = useState(0);
  const [animalsNotVaccinated, setAnimalsNotVaccinated] = useState(
    statusUpdates.length
  );

  const [checked, setChecked] = useState(false);
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);

    const statusUpdates = animals.map((animal) => ({
      tag_number: animal.tag_number,
      status_id: getStatusId(checked ? "UNVACCINATED" : "VACCINATED"),
      vaccination_name: vaccinationName,
      vaccination_type_id: selectedVaccineType,
      comment: "",
      date: new Date().toISOString().split("T")[0],
    }));

    setStatusUpdates(statusUpdates);
    // Here, you can send the statusUpdates to your backend or handle them as needed.
  };

  // / Transform roles data into options array
  const options = vaccineType?.map((type) => ({
    value: type.id.toString(),
    label: type.name,
  }));
  const onChange = (value) => {
    setSelectedVaccineType(value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const handleCancel = () => {
    setOpen(false);
    setStep(1);
    setLastSavedVaccinationLog(localStorage.getItem("rollcalllogs"));
    localStorage.setItem("vaccinationlogs", JSON.stringify(statusUpdates));
  };

  const handleChange = (e) => {
    setVaccinationName(e.target.value); // Update rollCallName with the new value of the input field
  };

  const handleCommentChange = (tagNumber, comment) => {
    setStatusUpdates((prevStatusUpdates) => {
      return prevStatusUpdates.map((animal) => {
        if (animal.tag_number === tagNumber) {
          return {
            ...animal,
            comment: comment,
          };
        }
        return animal;
      });
    });
    localStorage.setItem("vaccinationlogs", JSON.stringify(statusUpdates));
  };

  const getStatusId = (statusText) => {
    const status = vaccinationStatuses.find(
      (status) => status.name === statusText
    );
    return status ? parseInt(status.id) : null;
  };

  useEffect(() => {
    /**
     * Check if there are any roll call logs stored in local storage
     * If roll call logs exist in local storage, set statusUpdates to those logs
     * If no roll call logs exist, Initialize statusUpdates with all animals having status ID for  (UNROLLCALLED)
     */

    const storedRollCallLogs = JSON.parse(
      localStorage.getItem("vaccinationlogs")
    );

    if (storedRollCallLogs && storedRollCallLogs.length > 0) {
      setStatusUpdates(storedRollCallLogs);
    } else {
      const initialStatusUpdates = animals.map((animal) => ({
        tag_number: animal.tag_number,
        status_id: getStatusId("UNVACCINATED"),
        vaccination_name: vaccinationName,
        vaccination_type_id: selectedVaccineType,
        comment: "",
        date: new Date().toISOString().split("T")[0],
      }));
      open && setStatusUpdates(initialStatusUpdates);
    }
  }, [animals, vaccinationName, selectedVaccineType]);

  /**
   *
   * @param {*} tagNumber
   * @param {*} statusId
   * @description Function to update status of a specific animal
   */

  const updateAnimalStatus = (tagNumber, statusId) => {
    setStatusUpdates((prevStatusUpdates) => {
      const animalIndex = prevStatusUpdates.findIndex(
        (animal) => animal.tag_number === tagNumber
      );

      if (animalIndex !== -1) {
        /**
         * Create a new array with the previous status updates
         */
        const newStatusUpdates = [...prevStatusUpdates];

        /**
         * Update the status of the specific animal
         */
        newStatusUpdates[animalIndex] = {
          ...newStatusUpdates[animalIndex],
          status_id: statusId !== null ? statusId : getStatusId("UNVACCINATED"), // Set status to UNROLLCALLED if statusId is null
          vaccination_name: vaccinationName,
          vaccination_type_id: selectedVaccineType,
        };
        return newStatusUpdates;
      }
      localStorage.setItem("vaccinationlogs", JSON.stringify(statusUpdates));
      return prevStatusUpdates;
    });
  };

  const handleStatusChange = (tagNumber, statusId, checked) => {
    setSelectedStatus((prevSelectedStatus) => ({
      ...prevSelectedStatus,
      [tagNumber]: checked ? statusId : null,
    }));
    const newStatusId = checked
      ? statusId
      : selectedStatus[tagNumber] === statusId
      ? getStatusId("UNVACCINATED")
      : statusId;
    updateAnimalStatus(tagNumber, newStatusId);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="secondary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const fetchVaccinationStatuses = () => {
    const onSuccess = (response) => {
      setVaccinationStatuses(response.data);
    };

    const onError = (error) => {
      toast.error(error.response.data.message);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/vaccination/statuses`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const fetchVaccinationTypes = () => {
    const onSuccess = (response) => {
      setVaccineType(response.data);
    };

    const onError = (error) => {
      toast.error(error.response.data.message);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/vaccination/types`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const columns = [
    {
      title: "Tag Number",
      dataIndex: "tag_number",
      key: "tag_number",
      width: "25%",
      ...getColumnSearchProps("tag_number"),
    },
    {
      title: "Vaccinated",
      dataIndex: "vaccinted",
      key: "vaccinted",
      width: "25%",
      render: (present, record) => (
        <>
          {" "}
          <Checkbox
            // checked={selectedStatus[record.tag_number] === 1}
            checked={
              statusUpdates.find(
                (animal) =>
                  animal.tag_number === record.tag_number &&
                  parseInt(animal.status_id) === getStatusId("VACCINATED")
              )
                ? true
                : false
            }
            disabled={record.absent}
            onChange={(e) =>
              handleStatusChange(
                record.tag_number,
                getStatusId("VACCINATED"),
                e.target.checked
              )
            }
          ></Checkbox>
        </>
      ),
    },
    {
      title: "Not Vaccinated",
      dataIndex: "unVaccinated",
      key: "unVaccinted",
      width: "25%",
      render: (absent, record) => (
        <Checkbox
          // checked={selectedStatus[record.tag_number] === 2}
          checked={
            statusUpdates.find(
              (animal) =>
                animal.tag_number === record.tag_number &&
                parseInt(animal.status_id) === getStatusId("UNVACCINATED")
            )
              ? true
              : false
          }
          disabled={record.present}
          onChange={(e) =>
            handleStatusChange(
              record.tag_number,
              getStatusId("UNVACCINATED"),
              e.target.checked
            )
          }
        ></Checkbox>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: "40%",
      render: (comments, record) => (
        <Input
          value={
            statusUpdates.find(
              (animal) => animal.tag_number === record.tag_number
            )?.comment
          }
          onChange={(e) =>
            handleCommentChange(record.tag_number, e.target.value)
          }
        />
      ),
    },
  ];

  const handleSubmit = () => {
    setLoading(true);
    const data = JSON.stringify(statusUpdates);
    const onSuccess = (response) => {
      toast.success("Vaccination Routine performed");
      setLoading(false);
      setStatusUpdates([]);
      setSelectedStatus({});
      setLastSavedVaccinationLog(null);
      localStorage.removeItem("vaccinationlogs");
      setOpen(false);
    };

    const onError = (error) => {
      toast.error(
        error?.response
          ? error?.response?.data?.error
          : "Something went wrong. Please try again later."
      );
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}animals/vaccinations`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const handleStep = () => {
    setStep(step + 1);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterData(query);
  };

  const filterData = (query) => {
    if (query === "") {
      setFilteredData(null);
    } else {
      const filtered = animals.filter((entry) =>
        entry.tag_number.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };
  const performCount = () => {
    let VaccinatedCount = 0;
    let unVaccinatedCount = statusUpdates.length;

    statusUpdates.forEach((animal) => {
      if (parseInt(animal.status_id) === 1) {
        VaccinatedCount++;
      }
    });

    // Update presentAbsentCount state using the functional form of setState
    setAnimalsVaccinatedCount(VaccinatedCount);

    // Update unrollCalledCount state by subtracting presentAbsentCount from the total count
    setAnimalsNotVaccinated(unVaccinatedCount - VaccinatedCount);
  };
  useEffect(() => {
    fetchVaccinationStatuses();
    fetchVaccinationTypes();
  }, []);

  useEffect(() => {
    vaccinationName &&
      localStorage.setItem("vaccinationlogs", JSON.stringify(statusUpdates));
  }, [statusUpdates, vaccinationName]);

  useEffect(() => {
    performCount();
  }, [statusUpdates]);

  return (
    <div>
      {" "}
      <Modal
        open={open}
        width={1000}
        title={
          <div className="bg-gradient-to-r text-2xl from-blue-500 via-red-500  to-green-500 text-transparent bg-clip-text font-bold rounded-full ">
            Perform Vaccination Routine on animals
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <>
            {step === 2 && (
              <button
                onClick={handleSubmit}
                key="finish"
                className="rounded-lg bg-green-600 py-3 px-6 text-center  mr-72 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                disabled={loading}
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                ) : (
                  "Finish Vaccination Routine"
                )}
              </button>
            )}
            <button
              className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
              key="back"
              onClick={handleCancel}
            >
              Cancel
            </button>
            ,
            {step === 1 && lastsavedVaccinationLog === null && (
              <button
                className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                data-ripple-light="true"
                key="create-button"
                onClick={handleStep}
                disabled={loading}
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                ) : (
                  "Start Vaccination Routine"
                )}
              </button>
            )}
            ,
          </>,
        ]}
      >
        {step === 1 &&
          (lastsavedVaccinationLog === null || statusUpdates.length === 0 ? (
            <>
              <div className="relative h-11 w-full min-w-[200px] mb-6">
                <Select
                  showSearch
                  placeholder="Select Vaccination Type"
                  optionFilterProp="children"
                  className=" w-full"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  size="large"
                  options={options}
                />

                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:}}">
                  Vacination Type
                </label>
              </div>
              <div className="relative h-11 w-full min-w-[200px] mb-6">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0`}
                  placeholder=" "
                  onChange={handleChange} // Bind handleChange to the onChange event
                  value={vaccinationName}
                  type="text"
                  name="display_name"
                  required
                />

                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:}}">
                  Vaccine/Chemical Name
                </label>
              </div>
            </>
          ) : (
            <div>
              <h1 className="m-5 text-2xl text-lg-start">
                You have one uncompleted vaccination session
              </h1>
              <p className=" font-semibold text-lg text-center text-blue-500 animate-flash ">
                Press Select Option To Continue
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => setStep(2)}
                  className="mt-6  select-none rounded-lg bg-gradient-to-r from-red-400 via-red-600 to-red-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-black/10 transition-all hover:shadow-lg hover:shadow-black/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                >
                  Continue to unfinished Vaccination session
                </button>
                <button
                  onClick={() => {
                    localStorage.removeItem("rollcalllogs");
                    setLastSavedVaccinationLog(null);
                    setStep(1);
                    setStatusUpdates([]);
                  }}
                  className="mt-6 ml-2 select-none rounded-lg bg-gradient-to-r from-green-400 via-green-600 to-green-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-black/10 transition-all hover:shadow-lg hover:shadow-black/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                >
                  Start new one
                </button>
              </div>
            </div>
          ))}
        {step === 2 && (
          <>
            {" "}
            <div className="relative h-11 w-full min-w-[200px] mb-6">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0`}
                placeholder=" "
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:}}">
                Search by Tag Number
              </label>
            </div>
            <div className="flex justify-center">
              <span className="p-2 text-lg text-green-500">
                <b>Animals Vaccinated </b>: {animalsVaccinatedCount}
              </span>
              <span className="p-2 text-lg text-red-500">
                <b> Animals Unvaccinated </b>: {animalsNotVaccinated}
              </span>
            </div>
            <Table
              columns={columns}
              dataSource={filteredData !== null ? filteredData : animals}
              scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
              pagination={false}
            />
            <div className="flex items-center space-x-2">
              <input
                checked={checked}
                onChange={handleCheckboxChange}
                type="checkbox"
                className="transform scale-150"
              />
              <label className="text-2xl">Mark all as vaccinated</label>
            </div>
          </>
        )}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

AnimalsVaccinationModal.propTypes = {
  open: PropTypes.bool,
  lastsavedVaccinationLog: PropTypes.array,
  animals: PropTypes.arrayOf(
    PropTypes.shape({
      tag_number: PropTypes.string,
      present: PropTypes.bool,
      absent: PropTypes.bool,
    })
  ).isRequired,
  setOpen: PropTypes.func,
  setLastSavedVaccinationLog: PropTypes.func,
};
export default AnimalsVaccinationModal;
