import React, { useEffect, useState } from "react";
import { Card, Divider } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {apiRequest} from "../../common/common";
import Nav from "../nav";
import CreateFarmBranchModal from "./CreateFarmBranchModal";
import ViewFarmBranch from "./ViewFarmBranch";
import { API_BASE_URL } from "../../common/constants";
import { checkPermissionForUsers } from "../../common/Utilities";

function FarmBranches() {
  const { id } = useParams();
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);

  const [farmBranches, setFarmBranches] = useState([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [ids, setId] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const showDetailsModal = () => {
    setOpenDetailsModal(true);
  };

  

  useEffect(() => {
    const getFarmBranches = async () => {
      const onSuccess = (response) => {
        setFarmBranches(response.branches);
      };
  
      const onError = (error) => {
        toast.error("Error occurred fetching data");
      };
  
      apiRequest(
        "get",
        `${API_BASE_URL}farms/farm/${id}/branches`,
        "",
      "",
        onSuccess,
        onError,
      );
    };
    getFarmBranches();
  }, [id, refresh, branchSwitch]);
  return (
    <div>
      {" "}
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 bg-gray-100 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className="text-2xl float-start">Farm Branches</h1>
         {checkPermissionForUsers(5 , userPermissions, permissionsList)&& <button
            type="button"
            className="text-white bg-gradient-to-r  from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={showModal}
          >
            + Create Farm Branch
          </button>}
          <Divider />
          <div className="flex flex-wrap justify-center">
            {farmBranches.length === 0 ? (
              <p>No farms</p>
            ) : (
              farmBranches.map((branch, key) => {
                return (
                  <div key={key} className="m-4">
                    <Card
                      title={branch.name}
                      bordered={false}
                      extra={
                        <button
                          onClick={() => {
                            showDetailsModal();
                            setId(branch.id);
                          }}
                          className="bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-2 px-4 border border-blue-200 hover:border-transparent rounded"
                        >
                          View
                        </button>
                      }
                      style={{
                        width: 300,
                      }}
                    >
                      <h3>Location: {branch.location}</h3>
                      <h5>Branch Manager: {branch.branch_manager}</h5>
                      <p>Contacts: {branch.contacts}</p>
                    </Card>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <CreateFarmBranchModal open={open} setRefresh={setRefresh} setOpen={setOpen} />
      <ViewFarmBranch
        open={openDetailsModal}
        setOpen={setOpenDetailsModal}
        setRefresh={(i) => setRefresh(i)}
        id={ids}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default FarmBranches;
