import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Divider, Drawer, Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import Nav from "../nav";
import CreateUserAccount from "./CreateUserAccount";
import EditDetailsModal from "./EditDetailsModal";
import { API_BASE_URL } from "../../common/constants";
import {apiRequest} from "../../common/common";
import { checkPermissionForUsers } from "../../common/Utilities";
import PasswordReset from "./PasswordReset";

function Accounts() {
  const { roles } = useSelector((state) => state.roles);
  const branches = useSelector((state) => state.branches.userBranches);
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);

  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [refresh, setRefresh] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passModal, setPassModal] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };
  const showEditModal = () => {
    setOpenEditModal(true);
  };

  const showPassModal=()=>{
    setPassModal(true);
  }
  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setBranchId(null);
    setRoleId(null);
  };
  //  Transform roles data into options array
  const options = roles?.map((role) => ({
    value: role.id.toString(),
    label: role.display_name,
  }));
  // Transform branches data into options array
  const branchOptions =
    branches && Array.isArray(branches)
      ? branches.map((branch) => ({
          value: branch.id.toString(),
          label: branch.name,
        }))
      : [];
  const onSearch = (value) => {
    console.log("search:", value);
  };
  //    Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterBranchOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  /**
   * @description function to fetch registered users from the database
   * @returns json array of users information
   */
  const fetchUsers = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUsers(response.users);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("Failed to fetch Users");
      setLoading(false);
    };
    let params = {
      branch_id: branchId,
      role_id: roleId,
    };

    await apiRequest(
      "get",
      `${API_BASE_URL}farms/users`,
      "",
      params,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    fetchUsers();
  }, [refresh, roleId, branchId, branchSwitch]);

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 bg-gray-100 border-dashed rounded-lg bg-gray dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className=" text-2xl float-start">User Accounts</h1>
          <div className="flex flex-wrap justify-end">
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="text-white bg-gradient-to-r from-gray-400 via--500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 lg:hidden"
        >
          Actions
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
            <button
              className="block px-4 py-2 m-4 w-40 hover:bg-gray-300 text-left text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200  font-medium rounded-lg text-sm me-2 mb-2"
              onClick={() => {
                toggleFilters();
                closeDropdown();
              }}
            >
              Filters
            </button>
           {checkPermissionForUsers(23, userPermissions, permissionsList)&& <button
              className="block px-4 py-2 m-4 w-40 hover:bg-gray-300 text-left text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm  me-2 mb-2"
              onClick={() => {
                showModal();
                closeDropdown();
              }}
            >
              + Add User
            </button>}
          </div>
        )}
      </div>
      <div className="hidden lg:flex float-end">
        <button
          onClick={toggleFilters}
          className="float-end text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Filters
        </button>
       {checkPermissionForUsers(23, userPermissions, permissionsList)  && <button
          type="button"
          className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          onClick={showModal}
        >
          + Create User Account
        </button>}
      </div>
    </div>
          <Divider />
          <div className=" flex flex-wrap justify-center">
            {loading ? (
             <div className="flex flex-col lg:flex-row space-y-2">
             {Array.from({ length: 5 }).map((_, index) => {
               return (
                 <div key={index} role="status" className="lg:max-w-sm w-full animate-pulse m-4">
                   <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                   <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                   <div className="h-2 bg-gray-300 rounded-full  mb-2.5"></div>
                   <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                   <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                   <div className="h-2 bg-gray-300 rounded-full  max-w-sm"></div>
                   <span className="sr-only">Loading...</span>
                 </div>
               );
             })}
           </div>
           
            ) : users.length === 0 ? (
              <p>No User Accounts Created</p>
            ) : (
              users.map((user, key) => {
                return (
                  <div key={key} className="lg:m-4">
                    <Card
                      title={user.name}
                      style={{ width: "100%", padding: "10px", margin: "10px" }}
                      extra={
                        checkPermissionForUsers(
                          8,
                          userPermissions,
                          permissionsList
                        ) && (
                          <button
                            onClick={() => {
                              showEditModal();
                              setUser(user);
                            }}
                            className="bg-transparent float-end   hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-1 px-2 border border-blue-200 hover:border-transparent rounded"
                          >
                            Edit
                          </button>
                        )
                      }
                    >
                      <h3 className="text-xl">
                        Role : {user.role.display_name}
                      </h3>
                      <p>Contact : {user.phonenumber}</p>
                      <Divider />
                      <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 ">
                        {user.branches.map((branch, key) => (
                          <li
                            key={key}
                            className="flex items-center text-black"
                          >
                            <svg
                              className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                            </svg>
                            {branch.name}
                          </li>
                        ))}
                      </ul>
                      <button onClick={showPassModal} className="text-red-500 border border-red-500 bg-white px-1 py-1 rounded-md ">
                        Reset Password
                      </button>
                    </Card>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div>
        <Drawer
          title="Filters"
          placement="right"
          closable={true}          
          onClose={onClose}
          open={showFilters}
          getContainer={false}
        >
          <div className="relative h-11 w-full min-w-[200px]">
            <label className="">Filter By Role</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Select a role"
                optionFilterProp="children"
                className=" w-full"
                onChange={(value, option) => {
                  // Update branchId state with selected branch ID
                  setRoleId(value);
                }}
                onSearch={onSearch}
                filterOption={filterOption}
                size="large"
                options={options}
              />
            </div>
          </div>
          <div className="relative  h-11 w-full min-w-[200px] mt-9">
            <label className="">Filter By Branch</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Assign To Branch"
                optionFilterProp="children"
                className=" w-full"
                onChange={(value, option) => {
                  // Update roleId state with selected role ID
                  setBranchId(value);
                }}
                onSearch={onSearch}
                filterOption={filterBranchOption}
                size="large"
                options={branchOptions}
              />
            </div>
          </div>
          <Divider />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
              onClick={() => {
                fetchUsers();
                setShowFilters(false);
              }}
            >
              Apply Filters
            </button>
            <button
              onClick={resetFilters}
              className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
            >
              Reset Filters
            </button>
          </div>
        </Drawer>
      </div>
      <EditDetailsModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        setRefresh={setRefresh}
      user={user}
      />
      <PasswordReset user={user} open={passModal} setOpen={setPassModal} />
      <CreateUserAccount
        open={open}
        setOpen={setOpen}
        setRefresh={setRefresh}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Accounts;
