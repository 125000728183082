import React, { useEffect, useState } from "react";
import { Table, DatePicker, Input, Divider, Tag, Drawer, Tabs } from "antd";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import Nav from "../nav/index";
import { API_BASE_URL } from "../../common/constants";
import {apiRequest} from "../../common/common";
import generatePDF from "../../common/PdfGenerator";
import AttendanceChart from "./charts/AttendanceChart";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function AttendanceLogs() {
  const { user } = useSelector((state) => state.auth);

  const [logsByDate, setLogsByDate] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [tagNumberFilter, setTagNumberFilter] = useState(null);
  const [, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [rollCallSummary, setRollCallSummary] = useState([]);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [activeTab, setActiveTab] = useState("2");

  const filterText = [
    startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
    endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
    tagNumberFilter && `Tag Number: ${tagNumberFilter}`,
  ]
    .filter(Boolean)
    .join(", ");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const resetFilters = () => {
    setEndDateFilter(null);
    setStartDateFilter(null);
    setTagNumberFilter(null);
  };

  const fetchLogs = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}animals/attendanceByDate`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
      params: {
        start_date: startDateFilter && startDateFilter.format("YYYY-MM-DD"),
        end_date: endDateFilter && endDateFilter.format("YYYY-MM-DD"),
        tag_number: tagNumberFilter,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLogsByDate(response.data.logs_by_date);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error Occured fetching data");
        setLoading(false);
      });
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Tag Number",
        dataIndex: "tag_number",
        key: "tag_number",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          let color = "";
          if (text.toLowerCase() === "absent") {
            color = "red";
          } else if (text.toLowerCase() === "present") {
            color = "green";
          }
          return (
            <Tag color={color} key={text}>
              {text}
            </Tag>
          );
        },
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
      },
      {
        title: "Date Performed",
        dataIndex: "date",
        key: "date",
      },
    ];
    const data = logsByDate[record.date];

    return <Table columns={columns} dataSource={data} />;
  };

  const parentColumns = [
    { title: "RollCall Name", dataIndex: "date", key: "roll_call_name" },
  ];

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDateFilter(dates[0]);
      setEndDateFilter(dates[1]);
    }
  };

  const handleTagNumberChange = (e) => {
    setTagNumberFilter(e.target.value);
  };

  const fetchRollCallSummary = () => {
    const onSuccess = (response) => {
      setRollCallSummary(response.data);
    };

    let params = {
      start_date: startDateFilter && startDateFilter.format("YYYY-MM-DD"),
      end_date: endDateFilter && endDateFilter.format("YYYY-MM-DD"),
    };
    const onError = () => {
      toast.error("Failed To fetch RollCall Summary");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/rollcall/summary`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const summaryTableColumns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text,record, index) => <strong>{index+1}</strong>,
    },
    {
      title: "Roll Call Name",
      dataIndex: "roll_call_name",
      key: "roll_call_name",
      width: 127,
    },
    {
      title: "Total No of Animals",
      dataIndex: "total_count",
      key: "total_count",
      width: 175,
    },
    {
      title: "No of Animals RolledCall",
      dataIndex: "rolled_called_count",
      key: "rolled_called_count",
      width: 250,
    },
    {
      title: "N0. of Animals Present ",
      dataIndex: "present_count",
      key: "present_count",
      width: 250,
    },
    {
      title: "N0. of Animals Absent",
      dataIndex: "absent_count",
      key: "absent_count",
      width: 250,
    },
    {
      title: "N0. of Animals Not RollCalled",
      dataIndex: "not_rolled_called_count",
      key: "not_rolled_called_count",
      width: 250,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("ddd DD MMM YYYY HH:mm:ss"),
    },
  ];
  useEffect(() => {
    fetchRollCallSummary();
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    fetchLogs();
  }, [startDateFilter, endDateFilter, tagNumberFilter]);

  /**
   * generating and downloading a pdf
   * @argument event
   * @argument items animals array
   * @description function responsible for generating  and creating a pdf
   * @fires generatePDF function
   */
  const onDownloadPdf = (event, items) => {
    event.preventDefault();

    if (typeof items !== "object" || items === null) {
      toast.error("Cannot generate Pdf");
      console.error("Items is not an Object or Array");
      return;
    }
    const filterText = [
      startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
      endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
      tagNumberFilter && `Tag Number: ${tagNumberFilter}`,
    ]
      .filter(Boolean)
      .join(", ");
    const tableRows = [];
    const tableColumns = ["No", "TagNumber", "Status", "Comment", "date"];

    Object.keys(items).forEach((key, index) => {
      // Access the array of items for the current key
      const itemArray = items[key];

      // Iterate over the array of items
      itemArray.forEach((item) => {
        const temp = { ...item };

        // Remove the status_id field
        delete temp.status_id;

        // Push relevant data to tableRows
        const rowData = [
          index + 1,
          temp.tag_number,
          temp.status,
          temp.comment || "",
          temp.date,
        ];
        tableRows.push(rowData);
      });
    });

    generatePDF(
      tableColumns,
      tableRows,
      "RollCall Logs",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };
  const onDownloadPdf2 = (event, items) => {
    event.preventDefault();
    if (!Array.isArray(items)) {
      toast.error("Cannot Generate PDF");
      console.error("Roll call data is not an array");
      return;
    }

    const filterText = [
      startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
      endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
    ]
      .filter(Boolean)
      .join("-");

    const tableColumns = [
      "No",
      "Roll Call Name",
      "Total Animals",
      "RollCalled Animals",
      "Animals Present",
      "Animals Absent",
      "Animals Not RollCalled ",
      "Date Performed"
    ];
    const tableRows = items.map((item, index) => [
      index + 1,
      item.roll_call_name,
      item.total_count,
      item.rolled_called_count,
      item.present_count,
      item.absent_count,
      item.not_rolled_called_count,
      moment(item.created_at,).format("ddd DD MMM YYYY HH:mm:ss")
    ]);

    // Generate and download the PDF
    generatePDF(
      tableColumns,
      tableRows,
      "RollCall Logs Summary",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed h-100 rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <button
            onClick={(e) => {
              parseInt(activeTab) === 1
                ? onDownloadPdf(e, logsByDate)
                : onDownloadPdf2(e, rollCallSummary);
            }}
            className="float-right ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
          >
            Generate PDf
          </button>
          <button
            onClick={toggleFilters}
            type="button"
            className=" float-end bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </button>
          <div>
            <Drawer
              title="Filters"
              placement="right"
              closable={true}
              onClose={onClose}
              open={showFilters}
              getContainer={false}
            >
              <div className="mt-4">
                <span className="text-xl float-start">Select Date Range</span>
                <RangePicker onChange={handleDateChange} className="w-full" />
              </div>

             {parseInt(activeTab) === 1 && <div className="mt-4">
                <span className="text-xl float-start">Input Tag Number</span>
                <Input
                  placeholder="Tag Number"
                  value={tagNumberFilter}
                  onChange={handleTagNumberChange}
                />
              </div>}
              <Divider />
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
                  onClick={() => {
                    fetchLogs();
                    setShowFilters(false);
                  }}
                >
                  Apply Filters
                </button>
                <button
                  onClick={resetFilters}
                  className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
                >
                  Reset Filters
                </button>
              </div>
            </Drawer>
          </div>
          <Tabs defaultActiveKey={activeTab} onChange={handleTabChange} centered className="w-full">
            <TabPane
              tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  logs
                </div>
              }
              key="1"
            >
              <div>
                <h1 className=" text-2xl mb-2 float-start bg-gradient-to-r from-blue-500 to-green-500 via-red-500 text-transparent bg-clip-text font-bold">
                  Animals Track Log
                </h1>

                <Table
                  columns={parentColumns}
                  expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ["0"],
                  }}
                  dataSource={Object.keys(logsByDate).map((date, index) => ({
                    key: index,
                    date,
                  }))}
                  scroll={{ x: true, y: 700 }}
                  pagination={false}
                  className=" whitespace-nowrap"
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  Summary
                </div>
              }
              key="2"
            >
              <h1 className=" text-2xl mb-2 float-start bg-gradient-to-r to-red-500 via-blue-500  from-green-500 text-transparent bg-clip-text font-bold">
                Summary Track Log
              </h1>
              <Table
                dataSource={rollCallSummary}
                scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
                pagination={false}
                className=" whitespace-nowrap"
                columns={summaryTableColumns}
              />
            </TabPane>
            <TabPane tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  Reports
                </div>
              }
              key="3">
              <AttendanceChart data={rollCallSummary}  user={user?.data?.user?.name} filters={filterText}/>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default AttendanceLogs;
