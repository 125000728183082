import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Table, Menu, Dropdown, Drawer, Divider, Skeleton, Empty } from "antd";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import Nav from "../nav";
import VaccinationModal from "./VaccinationModal";
import ViewAnimalDetails from "./ViewAnimalDetails";
import RollCallModal from "./RollCallModal";
import BreedingModal from "./BreedingModal";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import generatePDF from "../../common/PdfGenerator";
import AnimalsVaccinationModal from "./AnimalsVaccinationModal";
import ReportDeathModal from "./ReportDeathModal";
import ReportAnimalHealth from "./ReportAnimalHealth";
import ViewAnimalAlbumModal from "./ViewAnimalAlbumModal";
import AnimalTransferModal from "./AnimalTransferModal";
import { checkPermissionForUsers } from "../../common/Utilities";
import UpdateAnimalDetails from "./UpdateAnimalDetails";

function Animals() {
  const { user } = useSelector((state) => state.auth);
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);
  const navigate = useHistory();
  const location = useLocation();
  const [animalData, setAnimalData] = useState(location.state);
  const [animals, setAnimals] = useState([]);
  const [animal, setAnimal] = useState();
  const [openVaccineModal, setOpenVaccineModal] = useState(false);
  const [openAnimalDetailModal, setOpenAnimalDetailModal] = useState(false);
  const [openBreedingModal, setOpenBreedingModal] = useState(false);
  const [openReportDeathModal, setReportDeathModal] = useState(false);
  const [openRollCallModal, setOpenRollCallModal] = useState(false);
  const [openVaccinationModal, setOpenVaccinationModal] = useState(false);
  const [openReporthealthModal, setReportHealthModal] = useState(false);
  const [openAnimalAlbumModal, setOpenAnimalAlbumModal] = useState(false);
  const [openAnimalTransferModal, setAnimalTransferModal] = useState(false);
  const [openAnimalUpdate, setOpenAnimalUpdate] = useState(false);
  const [animalId, setAnimalId] = useState(0);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [animalBreeds, setAnimalBreed] = useState([]);
  const [animalTypes, setAnimalType] = useState([]);
  const [genders, setGenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [breedTypes, setBreedTypes] = useState([]);
  const [lastSavedRollCall, setLastSavedRollCall] = useState(
    localStorage.getItem("rollcalllogs")
  );
  const [lastsavedVaccinationLog, setLastSavedVaccinationLog] = useState(
    localStorage.getItem("vaccinationlogs")
  );
  const [acquisationOrigin, setAcquisitionOrigin] = useState([]);

  const [healthStatus, setHealthStatus] = useState([]);
  const [animalOwners, setAnimalOwners] = useState([]);
  const [formData, setFormData] = useState({
    gender_id: null,
    animalTypeId: null,
    animalBreedId: null,
    tagNumber: null,
    purchaseOrigin: null,
    animal_breed_type: null,
    status: null,
    health_status: null,
    owner: null,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  // const handleHealthStatusChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  const resetFilters = () => {
    setFormData({
      gender: null,
      animalTypeId: null,
      animalBreedId: null,
      tagNumber: null,
      purchaseOrigin: null,
    });
    setAnimalData(null);
  };
  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return null;
    const dob = moment(dateOfBirth);
    const diffDuration = moment.duration(moment().diff(dob));

    let years = diffDuration.years();
    let months = diffDuration.months();
    let days = diffDuration.days();

    let ageString = "";

    if (years > 0) {
      ageString += `${years} ${years > 1 ? "years" : "year"}`;
    }

    if (months > 0) {
      ageString += `${ageString.length > 0 ? ", " : ""}${months} ${
        months > 1 ? "months" : "month"
      }`;
    }

    if (days > 0) {
      ageString += `${ageString.length > 0 ? ", " : ""}${days} ${
        days > 1 ? "days" : "day"
      }`;
    }

    return ageString;
  };

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Gender",
      dataIndex: ["gender", "gender"],
      key: "gender",
      width: 150,
    },
    {
      title: "Tag Number",
      dataIndex: "tag_number",
      key: "tag_number",
      width: 150,
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 150,
    },
    {
      title: "Age",
      key: "age",
      width: 190,
      render: (text, record) => calculateAge(record.date_of_birth),
    },
    {
      title: "Weight(kgs)",
      dataIndex: "weight",
      key: "weight",
      width: 250,
    },
    {
      title: "Health",
      dataIndex: ["health_status", "name"],
      key: "health_status",
      width: 250,
    },
    {
      title: "Purchase Origin",
      dataIndex: ["purchase_origin", "name"],
      key: "purchase_origin",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: ["type", "animal_types"],
      key: "animal_type",
      width: 250,
    },
    {
      title: "Breed",
      dataIndex: ["breed_type", "name"],
      key: "breed_type",
      width: 250,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 250,
      render: (text, record) => (
        <Dropdown
          className="py-2 px-2 border border-blue-400 text-blue-700 font-semibold hover:bg-blue-500 hover:border-transparent rounded hover:text-white"
          overlay={
            <Menu>
              <Menu.Item
                className=" hover:text-blue-500"
                key="0"
                onClick={() => handleView(record)}
              >
                View
              </Menu.Item>
              <Menu.Item
                className=" hover:text-blue-500"
                key="1"
                onClick={() => handleUpdateAnimal(record)}
              >
                Update Animal Details
              </Menu.Item>
              <Menu.Item
                className=" hover:text-blue-500"
                key="2"
                onClick={() => handleViewAlbum(record)}
              >
                View Animal Album
              </Menu.Item>
              {checkPermissionForUsers(
                10,
                userPermissions,
                permissionsList
              ) && (
                <Menu.Item key="3" onClick={() => handleAddVaccination(record)}>
                  Add Vaccination / Treatment
                </Menu.Item>
              )}
              {checkPermissionForUsers(
                20,
                userPermissions,
                permissionsList
              ) && (
                <Menu.Item
                  key="4"
                  onClick={() => handleReportAnimalhealthModal(record)}
                >
                  Report Health Status
                </Menu.Item>
              )}
              {checkPermissionForUsers(
                19,
                userPermissions,
                permissionsList
              ) && (
                <Menu.Item
                  key="5"
                  onClick={() => HandleReportDeathView(record)}
                >
                  Report Death
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={["click"]}
        >
          <a
            className="ant-dropdown-link"
            href="###"
            onClick={(e) => e.preventDefault()}
          >
            Actions
          </a>
        </Dropdown>
      ),
    },
  ];

  const showRollCallModal = () => {
    setOpenRollCallModal(true);
  };

  const showAnimalTransferModal = () => {
    setAnimalTransferModal(true);
  };

  const showVaccinationModal = () => {
    setOpenVaccinationModal(true);
  };
  // Function to handle the view action
  const handleView = (record) => {
    setAnimalId(record.id);
    setOpenAnimalDetailModal(true);
  };

  const handleViewAlbum = (record) => {
    setAnimal(record);
    setOpenAnimalAlbumModal(true);
  };

  const handleUpdateAnimal = (record) =>{
    setAnimal(record);
    setOpenAnimalUpdate(true)
  }

  const HandleReportDeathView = (record) => {
    setReportDeathModal(true);
    setAnimal(record);
  };
  const handleAddVaccination = (record) => {
    setAnimal(record);
    setOpenVaccineModal(true);
  };

  const handleReportAnimalhealthModal = (record) => {
    setReportHealthModal(true);
    setAnimal(record);
  };

  const getAnimalBreeds = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}animals/breeds`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setAnimalBreed(response.data);
      })
      .catch((error) => {
        toast.error("error getting animal breeds");
      });
  };
  const getAnimalTypes = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}animals/types`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setAnimalType(response.data);
      })
      .catch((error) => {
        toast.error("error getting animal types");
      });
  };
  // const handleDelete = (record) => {
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${API_BASE_URL}animals/animal/${record.id}/delete`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("user")}`,
  //     },
  //   };

  //   // Show confirmation message using SweetAlert
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You are about to delete this record. This action cannot be undone.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // If user confirms, proceed with deletion
  //       axios(config)
  //         .then((response) => {
  //           // Optionally, show a success message using SweetAlert
  //           Swal.fire("Deleted!", "The record has been deleted.", "success");
  //           // Additional logic after successful deletion
  //           setRefresh(true);
  //         })
  //         .catch((error) => {
  //           // Handle deletion error
  //           console.error("Error deleting record:", error);
  //           // Optionally, show an error message using SweetAlert
  //           Swal.fire(
  //             "Error!",
  //             "Failed to delete the record. Please try again later.",
  //             "error"
  //           );
  //           // Additional error handling logic
  //         });
  //     }
  //   });
  // };
  const fetchGenders = () => {
    const onSuccess = (response) => {
      setGenders(response.data);
    };

    const onError = () => {
      toast.error("Error Fetching Gender data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/genders`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const getAnimals = () => {
    setLoading(true);
    let params = {
      gender_id: formData.gender_id,
      owner: formData.owner,
      animal_type_id: formData.animalTypeId,
      animal_breed_id: formData.animalBreedId,
      tag_number: formData.tagNumber,
      purchase_origin:
        formData.purchaseOrigin ||
        (animalData?.purchase_origin && animalData?.id),
      animal_breed_type_id: formData.animal_breed_type,
      status: formData.status || (animalData?.status && animalData?.status),
      health_status:
        formData.health_status || (animalData?.health_status && animalData?.id),
    };

    const onSuccess = (response) => {
      setAnimals(response.animals);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error.response.data.error);
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}animals`, "", params, onSuccess, onError);
  };

  const fetchBreedTypes = () => {
    const onSuccess = (response) => {
      setBreedTypes(response.breedTypes);
    };

    const onError = (error) => {
      toast.error("Failed to Fetch Breed Types");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/breedtypes`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const getAnimalOwners = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimalOwners(response.data);
      setLoading(false);
    };

    const onError = () => {
      setLoading(false);
      toast.error("Error Fetching  data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-owners`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  /**
   * generating and downloading a pdf
   * @argument event
   * @argument items animals array
   * @description function responsible for generating  and creating a pdf
   * @fires generatePDF function
   */

  const onDownloadPdf = (event, items) => {
    const genderMappings = {};
    genders.forEach((gender) => {
      genderMappings[gender.id] = gender.gender;
    });

    // Mapping for animal types
    const animalTypeMappings = {};
    animalTypes.forEach((type) => {
      animalTypeMappings[type.id] = type.animal_types;
    });

    // Mapping for breed types
    const breedTypeMappings = {};
    breedTypes.forEach((breed) => {
      breedTypeMappings[breed.breed_type_id] = breed.breed_type_name;
    });

    // Mapping for animal breeds
    const animalBreedMappings = {};
    animalBreeds.forEach((breed) => {
      animalBreedMappings[breed.id] = breed.animal_breeds;
    });
    event.preventDefault();
    /**
     * Check if data is an array
     *  */
    if (!Array.isArray(items)) {
      toast.error("Cannot Generate pdf");
      console.error("Data is not an array");
      return;
    }

    const filterText = Object.entries(formData)
      .filter(([key, value]) => value !== null && value !== "")
      .map(([key, value]) => {
        switch (key) {
          case "gender_id":
            return `Gender: ${genderMappings[value] || value}`;
          case "animalTypeId":
            return `Animal Type: ${animalTypeMappings[value] || value}`;
          case "animal_breed_type":
            return `Breed Type: ${breedTypeMappings[value] || value}`;
          case "animalBreedId":
            return `Animal Breed: ${animalBreedMappings[value] || value}`;
          default:
            return `${key}: ${value}`;
        }
      })
      .join(", ");

    const tableRows = [];
    const tableColumns = [
      "No",
      "Name",
      "Gender",
      "Tag No",
      "Age",
      "Health",
      "Weight",
      "Type",
      "Breed",
      "Breed Type",
    ];

    /**
     * Iterate over the data array
     * @fires calculateAge
     * @returns age from date of birth
     * Push relevant data to tableRows
     */
    items.forEach((item, index) => {
      const dob = item.date_of_birth;
      const age = calculateAge(dob);

      const rowData = [
        index + 1,
        item.name,
        item.gender.gender,
        item.tag_number,
        age,
        item.health_status.name,
        item.weight,
        item.type.animal_types,
        item.breed.animal_breeds,
        item.breed_type ? item.breed_type.name : "",
      ];
      tableRows.push(rowData);
    });

    generatePDF(
      tableColumns,
      tableRows,
      "Generated Animals PDF",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };

  const getHealthStatuses = () => {
    const onSuccess = (response) => {
      setHealthStatus(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/health-status`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getAcquisationOrigins = () => {
    const onSuccess = (response) => {
      setAcquisitionOrigin(response.data);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/purchase-origins`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getAnimals();
  }, [refresh, branchSwitch, animalData]);

  useEffect(() => {
    getAnimalBreeds();
    getAnimalTypes();
    fetchGenders();
    fetchBreedTypes();
    getHealthStatuses();
    getAnimalOwners();
    getAcquisationOrigins();
  }, []);

  return (
    <div>
      <Nav setBranchSwitch={setBranchSwitch} branchSwitch={branchSwitch} />{" "}
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className=" text-2xl float-start">Animals Table</h1>
          <div className="flex flex-wrap justify-end">
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 lg:hidden"
              >
                Actions
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                  <button
                    className="block px-4 py-2 hover:bg-gray-300 w-40 m-4 text-left  text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm me-2 mb-2"
                    onClick={(e) => {
                      onDownloadPdf(e, animals);
                      closeDropdown();
                    }}
                  >
                    Download Pdf
                  </button>
                  {console.log(animals)}
                  <button
                    className="block px-4 py-2 text-white hover:bg-gray-300 text-left m-4 w-40 bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200 font-medium rounded-lg text-sm me-2 mb-2"
                    onClick={() => {
                      toggleFilters();
                      closeDropdown();
                    }}
                  >
                    Filters
                  </button>
                  {checkPermissionForUsers(
                    18,
                    userPermissions,
                    permissionsList
                  ) && (
                    <button
                      type="button"
                      onClick={showVaccinationModal}
                      className="block px-4 py-2  hover:bg-gray-300 m-4 w-40 text-left text-white bg-gradient-to-r from-fuchsia-400 via-fuchsia-500 to-fuchsia-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-fuchsia-300 dark:focus:ring-fuchsia-200 font-medium rounded-lg text-sm  me-2 mb-2"
                    >
                      Perform Vaccination Routine
                    </button>
                  )}
                  {checkPermissionForUsers(
                    3,
                    userPermissions,
                    permissionsList
                  ) && (
                    <button
                      className="block px-4 py-2  hover:bg-gray-300 m-4 w-40 text-left text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200 font-medium rounded-lg text-sm  me-2 mb-2"
                      onClick={() => {
                        showRollCallModal();
                        closeDropdown();
                      }}
                    >
                      Perform RollCall
                    </button>
                  )}
                  {checkPermissionForUsers(
                    14,
                    userPermissions,
                    permissionsList
                  ) && (
                    <button
                      className="block px-4 py-2 m-4 hover:bg-gray-300 w-40 text-left text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm me-2 mb-2 "
                      onClick={() => {
                        navigate.push("/register-animal");
                        closeDropdown();
                      }}
                    >
                      + Add New Animal
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="hidden lg:flex float-end">
              <button
                className=" text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                onClick={(e) => {
                  onDownloadPdf(e, animals);
                }}
              >
                Download Pdf
              </button>
              <button
                onClick={toggleFilters}
                className=" text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Filters
              </button>
              {checkPermissionForUsers(
                18,
                userPermissions,
                permissionsList
              ) && (
                <button
                  type="button"
                  onClick={showVaccinationModal}
                  className=" text-white bg-gradient-to-r from-fuchsia-400 via-fuchsia-500 to-fuchsia-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-fuchsia-300 dark:focus:ring-fuchsia-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                  Perform Vaccination Routine
                </button>
              )}
              {checkPermissionForUsers(3, userPermissions, permissionsList) && (
                <button
                  type="button"
                  onClick={showRollCallModal}
                  className=" text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                  Perform RollCall
                </button>
              )}
              {checkPermissionForUsers(
                14,
                userPermissions,
                permissionsList
              ) && (
                <button
                  type="button"
                  onClick={() => navigate.push("/register-animal")}
                  className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
                >
                  + Register New Animal
                </button>
              )}
            </div>
          </div>
          <Table
            dataSource={loading ? [] : animals}
            columns={columns}
            scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
            pagination={ false }
            className=" whitespace-nowrap"
            locale={{
              emptyText: loading ? (
                <Skeleton active paragraph={{ rows: 16 }} />
              ) : (
                <Empty />
              ),
            }}
          />
          {checkPermissionForUsers(17, userPermissions, permissionsList) && (
            <button
              type="button"
              onClick={showAnimalTransferModal}
              className=" text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Transfer Animals
            </button>
          )}
        </div>
        {/* filters */}
        <Drawer
          title={
            <div className=" font-bold bg-gradient-to-r text-2xl from-blue-500  to-green-500 text-transparent bg-clip-text">
              Filters
            </div>
          }
          placement="right"
          closable={true}
          onClose={onClose}
          open={showFilters}
          getContainer={false}
        >
          {/* Gender */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="gender_id"
              name="gender_id"
              onChange={handleChange}
              value={formData.gender_id}
            >
              <option value="">Filter By Gender</option>
              {genders?.map((gender, key) => {
                return (
                  <option key={key} value={gender.id}>
                    {gender.gender}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
              id="health_status"
              name="health_status"
              onChange={handleChange}
              value={formData.health_status}
            >
              <option value="">Filter By Health Status</option>
              {healthStatus.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          {/* filter by alive / dead  */}
          {/* <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="status"
              name="status"
              onChange={handleChange}
              value={formData.status}
            >
              <option value="">Filter By Status</option>
              <option value="alive">Alive</option>
              <option value="dead">Dead</option>
            </select>
          </div> */}
          {/* Animal Type ID */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="animalType"
              name="animalTypeId"
              onChange={handleChange}
              value={formData.animalTypeId}
            >
              <option value="">Filter By Animal Type</option>
              {animalTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.animal_types}
                </option>
              ))}
            </select>
          </div>
          {/* Animal Breed ID */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="animalBreed"
              name="animalBreedId"
              value={formData.animalBreedId}
              onChange={handleChange}
            >
              <option value="">Filter By Animal Breed</option>
              {/* Filter breeds based on selected animal type */}
              {animalBreeds
                .filter(
                  (breed) =>
                    parseInt(breed.animal_type_id) ===
                    parseInt(formData.animalTypeId)
                )
                .map((breed) => (
                  <option key={breed.id} value={breed.id}>
                    {breed.animal_breeds}
                  </option>
                ))}
            </select>
          </div>
          {/* animal breed type */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="animal_breed_type"
              name="animal_breed_type"
              value={formData.animal_breed_type}
              onChange={handleChange}
            >
              <option value="">Filter By Animal Breed Type</option>
              {/* Filter breeds based on selected animal type */}
              {breedTypes.map((breed) => (
                <option key={breed.id} value={breed.breed_type_id}>
                  ({breed.animal_type_name}-{breed.breed_name}){" "}
                  {breed.breed_type_name}
                </option>
              ))}
            </select>
          </div>
          {/* Tag Number */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="tagNumber"
              name="tagNumber"
              type="text"
              placeholder=" "
              value={formData.tagNumber}
              onChange={handleChange}
            />
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Filter By Tag Number
            </label>
          </div>
          {/* how it was acquired */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
              id={`purchaseOrigin`}
              name={`purchaseOrigin`}
              onChange={handleChange}
              value={formData.purchaseOrigin}
            >
              <option value="">Filter By Acquisition Origin</option>
              {acquisationOrigin?.map((origin) => (
                <option value={origin.id}>{origin.name}</option>
              ))}
            </select>
          </div>

          {/* animal breed type */}
          <div className="mb-4 relative h-11 w-full min-w-[200px]">
            <select
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
              id="owner"
              name="owner"
              value={formData.owner}
              onChange={handleChange}
            >
              <option value="">Filter By Animal Owners</option>
              {/* Filter breeds based on selected animal type */}
              {animalOwners.map((owner) => (
                <option key={owner.id} value={owner.id}>
                  {owner.name}
                </option>
              ))}
            </select>
          </div>

          <Divider />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
              onClick={() => {
                getAnimals();
                setShowFilters(false);
              }}
            >
              Apply Filters
            </button>
            <button
              onClick={resetFilters}
              className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
            >
              Reset Filters
            </button>
          </div>
        </Drawer>
      </div>
      <RollCallModal
        open={openRollCallModal}
        setOpen={setOpenRollCallModal}
        animals={animals}
        lastSavedRollCall={lastSavedRollCall}
        setLastSavedRollCall={setLastSavedRollCall}
      />
      <VaccinationModal
        open={openVaccineModal}
        setOpen={setOpenVaccineModal}
        animal={animal}
        setRefresh={setRefresh}
      />
      <BreedingModal
        open={openBreedingModal}
        setopen={setOpenBreedingModal}
        animal={animal}
        setRefresh={setRefresh}
      />
      <ViewAnimalDetails
        open={openAnimalDetailModal}
        setOpen={setOpenAnimalDetailModal}
        id={animalId}
        setRefresh={setRefresh}
      />
      <ViewAnimalAlbumModal
        open={openAnimalAlbumModal}
        setOpen={setOpenAnimalAlbumModal}
        animal={animal}
      />
      <AnimalsVaccinationModal
        open={openVaccinationModal}
        setOpen={setOpenVaccinationModal}
        lastsavedVaccinationLog={lastsavedVaccinationLog}
        setLastSavedVaccinationLog={setLastSavedVaccinationLog}
        animals={animals}
      />
      <ReportAnimalHealth
        open={openReporthealthModal}
        setOpen={setReportHealthModal}
        animal={animal}
        setRefresh={setRefresh}
      />
      <ReportDeathModal
        open={openReportDeathModal}
        setOpen={setReportDeathModal}
        animal={animal}
        setRefresh={setRefresh}
      />
      <AnimalTransferModal
        open={openAnimalTransferModal}
        setOpen={setAnimalTransferModal}
        setRefresh={setRefresh}
        refresh={refresh}
        animals={animals}
      />
      <UpdateAnimalDetails
        open={openAnimalUpdate}
        setOpen={setOpenAnimalUpdate}
        animal={animal}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Animals;
