import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const VaccinationPieChart = ({ data, user, filters }) => {
  const chartRef = useRef(null);
  // Extracting data for visualization
  const vaccinatedCount = data.map((item) => item.vaccinated_animals);
  const UnVaccinatedCount = data.map((item) => item.not_vaccinated_animals);
  // Data for the pie chart
  const chartData = {
    labels: ["Vaccinated", "Unvaccinated"],
    datasets: [
      {
        label: "No. of animals",
        data: [vaccinatedCount, UnVaccinatedCount],
        backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"],
        borderColor: ["rgba(75, 192, 192)", "rgba(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  ChartJS.register(ArcElement, Tooltip, Legend);
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Pie Chart showing Variation in Vaccination Status",
        font: {
          size: 16,
        },
      },
    },
  };

  // Function to generate PDF
  const generatePDF = () => {
    // Get the chart canvas element
    const chartCanvas = chartRef?.current?.canvas;
    if (!chartCanvas) {
      console.error("Chart canvas not found");
      return;
    }

    // Convert the chart canvas to an image using html2canvas
    html2canvas(chartCanvas).then((canvas) => {
      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Calculate the width and height of the PDF page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio of the chart canvas
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the height of the PDF page based on the width and aspect ratio
      const height = pdfWidth / aspectRatio;


      const currentDate = new Date().toLocaleDateString();
      pdf.text(`Date Printed: ${currentDate}`, 10, height + 10).setFont("Helvetica").setFontSize(12);
   
      pdf.text(`User Logged In: ${user}`, 10, height + 20).setFontSize(12).setFont('Helvetica');
      pdf.text(`Filter(s): ${filters}`, 10, height + 30).setFontSize(12).setFont('Helvetica');

      // Add the chart image to the PDF
      pdf.addImage(
        canvas.toDataURL("image/jpeg"),
        "JPEG",
        0,
        0,
        pdfWidth,
        height
      );

      

      // Save the PDF
      pdf.save("attendance_chart.pdf");
    });
  };

  return (
    <div>
        <button
        className="ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
        onClick={generatePDF}
      >
        DownLoad Chart
      </button>
      <Pie data={chartData} ref={chartRef} options={options} />
      
    </div>
  );
};

VaccinationPieChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VaccinationPieChart;
