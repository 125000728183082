import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function CreateUserAccount(props) {
  const { roles } = useSelector((state) => state.roles);
  const { branches } = useSelector((state) => state.branches.userBranches);
  const [loading, setLoading] = useState(false);
  const [permissionsToRevoke, setPermissionsToRevoke] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [roleSelected, setRoleSelected] = useState(false); // Track if a role is selected

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    username: "",
    password: "",
    phonenumber: "",
    role_id: "",
    branch_ids: [],
  });
  const handleCancel = () => {
    props.setOpen(false);
  };

  const handleCheckboxChange = (permissionId) => {
    if (permissionsToRevoke.includes(permissionId)) {
      setPermissionsToRevoke((prevPermissions) =>
        prevPermissions.filter((id) => id !== permissionId)
      );
    } else {
      setPermissionsToRevoke((prevPermissions) => [
        ...prevPermissions,
        permissionId,
      ]);
    }
  };

  const getRoleDetails = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}roles/role/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setRolePermissions(response.data.role.permissions);
        setInitialPermissions(
          response.data.role.permissions.map((perm) => perm.id)
        );
      })
      .catch((error) => {
        toast.error("Error Occured fetching data");
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const onChange = (value) => {
    setFormData({
      ...formData,
      role_id: value,
    });
  };

  const onChangeBranch = (value) => {
    setFormData({
      ...formData,
      branch_ids: value,
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.password) {
      newErrors.password = "Password is Required";
    }
    if (!formData.username) {
      newErrors.username = "username required";
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   *  @description function that makes an api call to create a user account
   * @returns json response
   */
  const handleOk = async () => {
    setLoading(true);
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    const data = JSON.stringify({
      name: formData.name,
      email: formData.email,
      password: formData.password,
      username: formData.username,
      phonenumber: formData.phonenumber,
      role_id: formData.role_id,
      branch_ids: formData.branch_ids,
      permissions_to_revoke: permissionsToRevoke,
    });

    const onSuccess = () => {
      toast.success("User account created successfully!");
      setLoading(false);
      props.setOpen(false);
      props.setRefresh(true);
      setRoleSelected(false);
    };

    const onError = (error) => {
      console.log(error);
      toast.error("Error Occurred");
      setLoading(false);
    };

    await apiRequest(
      "post",
      `${API_BASE_URL}auth/register`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  // / Transform roles data into options array
  const options = roles?.map((role) => ({
    value: role.id.toString(),
    label: role.display_name,
  }));
  // Transform branches data into options array
  const branchOptions = branches?.map((branch) => ({
    value: branch.id.toString(),
    label: branch.name,
  }));

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterBranchOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // Fetch role permissions when role_id changes
  useEffect(() => {
    if (formData.role_id) {
      getRoleDetails(formData.role_id);
      setRoleSelected(true);
    } else {
      setRoleSelected(false);
      setRolePermissions([]);
      setInitialPermissions([]);
    }
  }, [formData.role_id]);
  return (
    <>
      <Modal
        open={props.open}
        title="Create User Account"
        width={500}
        onCancel={handleCancel}
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="button"
            key="create-button"
            data-ripple-light="true"
            onClick={handleOk}
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : (
              "Register"
            )}
          </button>,
        ]}
      >
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                  errorMessage.name && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.name}
                name="name"
                type="text"
              />
              {errorMessage.name && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.name}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Name
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                placeholder=" "
                onChange={handleChange}
                value={formData.email}
                name="email"
                type="email"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Email
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 ${
                  errorMessage.username && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.username}
                type="text"
                name="username"
              />
              {errorMessage.username && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.username}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                User Name
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 "
                placeholder=" "
                type="text"
                name="phonenumber"
                onChange={handleChange}
                value={formData.phonenumber}
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Phone number
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <label className="">Select User Type</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select a role"
                  optionFilterProp="children"
                  className=" w-full"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  size="large"
                  options={options}
                />
              </div>
            </div>
            {roleSelected && (
              <div className="grid grid-cols-2 gap-4">
                {rolePermissions.map((permission) => (
                  <div key={permission.id}>
                    <label className="block text-md">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-green-600 transition duration-150 ease-in-out"
                        value={permission.id}
                        checked={
                          !permissionsToRevoke.includes(permission.id) &&
                          initialPermissions.includes(permission.id)
                        }
                        onChange={() => handleCheckboxChange(permission.id)}
                      />
                     <span className="ml-2 text-blue-gray-700">{permission.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
            <div className="relative h-11 w-full min-w-[200px] mt-3">
              <label className="">Assign to branch</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Assign To Branch"
                  optionFilterProp="children"
                  className=" w-full"
                  onChange={onChangeBranch}
                  onSearch={onSearch}
                  filterOption={filterBranchOption}
                  size="large"
                  options={branchOptions}
                />
              </div>
            </div>
            <div className="relative h-11 w-full min-w-[200px] mt-3">
              <input
                id="password"
                name="password"
                type="password"
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0  ${
                  errorMessage.password && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.password}
              />
              {errorMessage.password && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.password}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Password
              </label>
            </div>
          </div>
        </form>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default CreateUserAccount;
