// store.js
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import animalReducer from "./slice/animalSlice";
import authReducer from "./slice/authSlice";
import roleReducer from "./slice/rolesSlice";
import branchReducer from "./slice/ BranchesSlice";
import activeBranchReducer from './slice/activeBranchSlice';
import userPermissionsSlice from './slice/userPermissionsSlice';
import permissionsListReducer from './slice/permissionsListSlice';


const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  animals: animalReducer,
  auth: authReducer,
  roles: roleReducer,
  branches: branchReducer,
  activeBranch: activeBranchReducer,
  userPermissions : userPermissionsSlice,
  permissionsList: permissionsListReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
