import React, { useEffect, useState } from "react";
import {
  FaUserCircle,
  FaUsers,
  FaSignOutAlt,
  FaCodeBranch,
} from "react-icons/fa";
import { SiHappycow } from "react-icons/si";
import { GiFarmTractor } from "react-icons/gi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdVaccines } from "react-icons/md";
import { MdOutlineVaccines } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { SiQuicklook } from "react-icons/si";
import { FaPaintRoller } from "react-icons/fa";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../redux/slice/authSlice";
import { API_BASE_URL } from "../../common/constants";
import { apiRequest } from "../../common/common";
import { checkPermissionForUsers } from "../../common/Utilities";
import RollCallModal from "../Animals/RollCallModal";
import AnimalsVaccinationModal from "../Animals/AnimalsVaccinationModal";

function Nav({ branchSwitch, setBranchSwitch }) {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { farmBranches } = useSelector((state) => state.branches.allBranches);
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [openbranchDropdown, setOpenBranchDropdown] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openAnimalDropDown, setOpenAnimalDropDown] = useState(false);
  const [openRollCallDropDown, setOpenrollCallDropDown] = useState(false);
  const [openVaccinationDropDown, setOpenVaccinationDropDown] = useState(false);


  // const [branchSwitch, setBranchSwitch] = useState(false);
  const [activeBranch, setActiveBranch] = useState();
  const [mobileDropDown, setMobileDropDown] = useState(false);
  const [subMenuMobileDropDown, setSubMenuMobileDropDown] = useState(false);

  const [lastSavedRollCall, setLastSavedRollCall] = useState(
    localStorage.getItem("rollcalllogs")
  );
  const [openRollCallModal, setOpenRollCallModal] = useState(false);
  const [animals, setAnimals] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openVaccinationModal, setOpenVaccinationModal] = useState(false);
 const [lastsavedVaccinationLog, setLastSavedVaccinationLog] = useState(
   localStorage.getItem("vaccinationlogs")
 );

  const getAnimals = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setAnimals(response.animals);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error.response.data.error);
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}animals`, "", "", onSuccess, onError);
  };

  const showRollCallModal = () => {
    setOpenRollCallModal(true);
  };

  const handleDropDown = () => {
    setDropDownOpen(!dropdownOpen);
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleBranchDropDown = () => {
    setOpenBranchDropdown(!openbranchDropdown);
  };

  const handleAnimalsDropDown = () => {
    setOpenAnimalDropDown(!openAnimalDropDown);
  };
  const handleRollCallDropDown = () => {
    setOpenrollCallDropDown(!openRollCallDropDown);
  };
  const handleVaccinationDropDown = () => {
    setOpenVaccinationDropDown(!openVaccinationDropDown);
  };

  const handleMobileDropDwon = () => {
    setMobileDropDown(!mobileDropDown);
  };

  const handleSubMenuMobileDropDown = () => {
    setSubMenuMobileDropDown(!subMenuMobileDropDown);
  };

  const showVaccinationModal = () => {
    setOpenVaccinationModal(true);
  };

  const getActiveBranch = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}farms/user/active-branch`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setActiveBranch(response.data.active_branch);
      })
      .catch((error) => {
        toast.error("Error Occured fetching active branch");
      });
  };
  // Function to handle branch click
  const handleBranchClick = (id) => {
    handleSwitchBranch(id);
    setOpenBranchDropdown(false);
    setSubMenuMobileDropDown(false);
  };

  /**
   * @function handleBranchReset
   * @description resetting to default branch
   */
  const handleBranchReset = () => {
    const onSuccess = async (response) => {
      toast.success("Switched to default branch");
      setBranchSwitch(branchSwitch);
      setOpenBranchDropdown(false);
      // window.location.reload(false);
    };

    const onError = (error) => {
      toast.error("Error Occurred");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}farms/user/unswitch-branch`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  /**
   * @function handleSwitchBranch
   * @description switching between branches
   * @param {*} id  branch id
   */
  const handleSwitchBranch = async (id) => {
    const onSuccess = async (response) => {
      toast.success("Switched to branched");
      setOpenBranchDropdown(false);
      setBranchSwitch(!branchSwitch);
    };

    const onError = (error) => {
      toast.error("Error Occured");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}farms/user/branch/${id}`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()); // Dispatch loginUser action with form data
      localStorage.removeItem("user");
      localStorage.removeItem("encryptedData");
      localStorage.removeItem("persist:root");
      navigate.push("/");
      toast.success("Logout Successful");
    } catch (error) {
      toast.error("error occured");
    }
  };

  useEffect(() => {
    getActiveBranch();
  }, [branchSwitch]);
  useEffect(() => {
    getAnimals();
  }, [branchSwitch]);

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 ">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={handleSidebarToggle}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/quick-access" className="flex ms-2 md:me-24">
                <img
                  src="https://flowbite.com/docs/images/logo.svg"
                  className="h-8 me-3"
                  alt="FlowBite Logo"
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text  bg-gray-100 rounded-full ">
                  Farms Mgmt
                </span>
              </Link>
            </div>
            <div className="lg:flex items-center float-right hidden">
              <div className="flex items-center ms-3">
                <button
                  id="dropdownAvatarNameButton"
                  data-dropdown-toggle="dropdownAvatarName"
                  className="flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:me-0 focus:ring-4 focus:ring-gray-100 "
                  type="button"
                  onClick={handleBranchDropDown}
                >
                  <span className="sr-only">Open branch menu</span>

                  <FaCodeBranch className="w-8  p-2 h-8 me-2 rounded-full" />
                  <span className="bg-gradient-to-r from-red-500 to-blue-500 via-green-500 text-transparent bg-clip-text font-bold">
                    {activeBranch === null || activeBranch === undefined
                      ? "Default Branch"
                      : activeBranch?.name}
                  </span>
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                {openbranchDropdown && (
                  <div
                    id="dropdownAvatarName"
                    className="z-10 bg-white divide-y divide-gray-100 mt-28 rounded-lg shadow w-44 absolute"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 "
                      aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
                    >
                      {parseInt(user?.data?.user?.role_id) === 1
                        ? farmBranches.map((branch, key) => {
                            return (
                              <li key={key}>
                                <span
                                  onClick={() => handleBranchClick(branch.id)}
                                  className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                                >
                                  {branch.name}
                                </span>
                              </li>
                            );
                          })
                        : user?.data?.branch_farms.map((branch, key) => {
                            return (
                              <li key={key}>
                                <span
                                  onClick={() => handleBranchClick(branch.id)}
                                  className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                                >
                                  {branch.name}
                                </span>
                              </li>
                            );
                          })}
                      {parseInt(user?.data?.user?.role_id) === 1 && (
                        <li>
                          <span
                            onClick={handleBranchReset}
                            className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                          >
                            Default Branch
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                <span className="m-2 bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-xl bg-gray-100 rounded-full">
                  {user?.data?.user?.name}
                </span>
                <button
                  type="button"
                  className="flex text-sm bg-gray-100 rounded-full focus:ring-4 focus:ring-gray-300 "
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown-user"
                  onClick={handleDropDown}
                >
                  <span className="sr-only">Open user menu</span>
                  <FaUserCircle className="w-8 h-8 rounded-full" />
                </button>

                {dropdownOpen && (
                  <div
                    className="absolute right-0 top-full z-50 mt-2 text-base bg-white divide-y divide-gray-100 rounded shadow"
                    id="dropdown-user"
                  >
                    <ul>
                      {/* <li>
                        <Link
                          to="##"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Settings
                        </Link>
                      </li> */}
                      <li>
                        <span
                          onClick={handleLogout}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign out
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="lg:hidden">
              <button
                type="button"
                id="multiLevelDropdownButton"
                data-dropdown-toggle="multi-dropdown"
                className=" bg-gray-100 rounded-full focus:ring-4 focus:ring-gray-300 text-sm text-center inline-flex items-center "
                aria-expanded="false"
                onClick={handleMobileDropDwon}
              >
                <span className="sr-only">Open user menu</span>
                <FaUserCircle className="w-8 h-8 rounded-full" />
              </button>
              {mobileDropDown && (
                <div
                  id="multi-dropdown"
                  class="z-10 absolute  right-3  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
                >
                  <ul
                    class="py-2 text-sm text-gray-700 "
                    aria-labelledby="multiLevelDropdownButton"
                  >
                    <li>
                      <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-100 bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-sm ">
                        {user?.data?.user?.name}
                      </span>
                    </li>
                    <li>
                      <button
                        id="doubleDropdownButton"
                        data-dropdown-toggle="doubleDropdown"
                        data-dropdown-placement="left"
                        type="button"
                        onClick={handleSubMenuMobileDropDown}
                        className="flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-100 "
                      >
                        <span className="bg-gradient-to-r from-red-500 to-blue-500 via-green-500 text-transparent bg-clip-text font-bold">
                          {" "}
                          {activeBranch === null || activeBranch === undefined
                            ? "Default Branch"
                            : activeBranch?.name}{" "}
                        </span>
                        <svg
                          className="w-2.5 h-2.5 ms-3 rtl:rotate-180"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                      </button>
                      {subMenuMobileDropDown && (
                        <div
                          id="dropdownLeft"
                          className="z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                        >
                          <ul
                            className="py-2 text-sm text-gray-700 "
                            aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
                          >
                            {parseInt(user?.data?.user?.role_id) === 1
                              ? farmBranches.map((branch, key) => {
                                  return (
                                    <li key={key}>
                                      <span
                                        onClick={() =>
                                          handleBranchClick(branch.id)
                                        }
                                        className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                                      >
                                        {branch.name}
                                      </span>
                                    </li>
                                  );
                                })
                              : user?.data?.branch_farms.map((branch, key) => {
                                  return (
                                    <li key={key}>
                                      <span
                                        onClick={() =>
                                          handleBranchClick(branch.id)
                                        }
                                        className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                                      >
                                        {branch.name}
                                      </span>
                                    </li>
                                  );
                                })}
                            {parseInt(user?.data?.user?.role_id) === 1 && (
                              <li>
                                <span
                                  onClick={handleBranchReset}
                                  className="block px-4 py-2 hover:bg-gray-100 hover:from-green-500 hover:to-blue-500 hover:text-transparent bg-gradient-to-r bg-clip-text "
                                >
                                  Default Branch
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </li>

                    <li>
                      <span
                        className="block px-4 py-2 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600"
                        onClick={handleLogout}
                      >
                        Sign out
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-5 lg:top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 md:translate-x-0 ${
          sidebarOpen ? "" : "-translate-x-full" // Conditional className to control visibility
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
          <ul className="space-y-2 font-medium">
            <li>
              <span
                onClick={handleLogout}
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
              >
                <FaSignOutAlt
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-red-900"
                />
                <span className="ml-3 text-gray-500 group-hover:text-red-900">
                  Sign Out
                </span>
              </span>
            </li>
            <li>
              <Link
                to="/quick-access"
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
              >
                <SiQuicklook
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-red-900"
                />
                <span className="ml-3 text-gray-500 group-hover:text-red-900">
                  QuickAccess
                </span>
              </Link>
            </li>
            {parseInt(user?.data?.user?.role_id) === 1 && (
              <li>
                <Link
                  to="/animalreports"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                >
                  <IoStatsChart
                    size={35}
                    className="text-gray-500 transition duration-75 group-hover:text-red-900"
                  />
                  <span className="ml-3 text-gray-500 group-hover:text-red-900">
                    Animal Statistics
                  </span>
                </Link>
              </li>
            )}
            {parseInt(user?.data?.user?.role_id) === 1 && (
              <li>
                <Link
                  to="/farms"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                >
                  <GiFarmTractor
                    size={35}
                    className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                  />
                  <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                    Farm Mgmt
                  </span>
                </Link>
              </li>
            )}
            <li>
              {checkPermissionForUsers(
                33,
                userPermissions,
                permissionsList
              ) && (
                <Link
                  to="/account-types"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                >
                  <MdOutlineManageAccounts
                    size={35}
                    className="flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-blue-900"
                  />
                  <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                    User Group Mgmt
                  </span>
                </Link>
              )}
            </li>
            <li>
              {checkPermissionForUsers(
                24,
                userPermissions,
                permissionsList
              ) && (
                <Link
                  to="/accounts"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                >
                  <FaUsers
                    size={35}
                    className="flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-blue-900"
                  />
                  <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                    Staff Account Mgmt
                  </span>
                </Link>
              )}
            </li>
            <li>
              <button
                type="button"
                onClick={handleAnimalsDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <SiHappycow
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                  Animal Mgmt
                </span>
                <svg
                  className="w-3 h-3 ml-auto"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openAnimalDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  {checkPermissionForUsers(
                    29,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/register-breed-type"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      Add Breed Type
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    30,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/breedTypes"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      View Breed Types
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    14,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/register-animal"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      Add Animal
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    27,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/animals"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      View Animals
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    16,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/animalreports"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      View Animal Reports
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    21,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/animal-giveaways"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      Animal Giveaway
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    21,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/animal-sale"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                    >
                      Animal Sale
                    </Link>
                  )}
                </li>
                <li>
                  {checkPermissionForUsers(
                    28,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      to="/other-owners"
                    >
                      View Other Owners
                    </Link>
                  )}
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                onClick={handleRollCallDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <FaPaintRoller
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                  Roll Call
                </span>
                <svg
                  className="w-3 h-3 ml-auto"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openRollCallDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  {checkPermissionForUsers(
                    31,
                    userPermissions,
                    permissionsList
                  ) && (
                    <span
                      onClick={showRollCallModal}
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100"
                    >
                      <span className="ml-3 text-gray-800 group-hover:text-blue-900">
                        Perform RollCall
                      </span>
                    </span>
                  )}
                </li>

                <li>
                  {checkPermissionForUsers(
                    31,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/rollcall-logs"
                      className="flex items-center hover:text-red-400 w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100"
                    >
                      <span className="ml-3 text-gray-800 group-hover:text-blue-900">
                        Roll Call Logs
                      </span>
                    </Link>
                  )}
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                onClick={handleVaccinationDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <MdVaccines
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="ml-3 text-gray-500 group-hover:text-blue-900">
                  Vaccination
                </span>
                <svg
                  className="w-3 h-3 ml-auto"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openVaccinationDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  {checkPermissionForUsers(
                    32,
                    userPermissions,
                    permissionsList
                  ) && (
                    <span className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                      <span
                        onClick={showVaccinationModal}
                        className="ml-3 text-gray-800 group-hover:text-blue-900"
                      >
                        Perform Vaccination
                      </span>
                    </span>
                  )}{" "}
                </li>{" "}
                <li>
                  {checkPermissionForUsers(
                    32,
                    userPermissions,
                    permissionsList
                  ) && (
                    <Link
                      to="/vaccination-logs"
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <span className="ml-3 text-gray-800 group-hover:text-blue-900">
                        Vaccination Logs
                      </span>
                    </Link>
                  )}{" "}
                </li>{" "}
              </ul>
            </li>
          </ul>
        </div>
      </aside>

      <RollCallModal
        open={openRollCallModal}
        setOpen={setOpenRollCallModal}
        animals={animals}
        lastSavedRollCall={lastSavedRollCall}
        setLastSavedRollCall={setLastSavedRollCall}
      />
      <AnimalsVaccinationModal
        open={openVaccinationModal}
        setOpen={setOpenVaccinationModal}
        lastsavedVaccinationLog={lastsavedVaccinationLog}
        setLastSavedVaccinationLog={setLastSavedVaccinationLog}
        animals={animals}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

Nav.propTypes = {
  branchSwitch: PropTypes.bool,
  setBranchSwitch: PropTypes.func,
  setOpenModal: PropTypes.func,
};
export default Nav;
