import React, { useState, useEffect } from "react";
import { Modal, Tabs } from "antd";
import {toast, ToastContainer} from 'react-toastify';
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function ViewFarmBranch( props) {
  const [loading, setLoading] = useState(false);
  const [,setFarmBranchInfo] = useState([])
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      props.setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    props.setOpen(false);
  };
  const getFarmBranchInfo = async (id) => {
    const onSuccess = (response) => {
      setFarmBranchInfo(response.branches);
    };

    const onError = (error) => {
      toast.error("Error occurred fetching details");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}farms/branches/${id}`,
      "",
      "",
      onSuccess,
      onError,
      
    );
  };

  useEffect(() => {
    props.id > 0 &&
      getFarmBranchInfo(props.id);
  }, [props.id]);
  return (
    <div>
      <Modal
        title="Farm Details"
        centered
        open={props.open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <button
            className="mt-6 mr-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-light="true"
            key="create-button"
            onClick={handleOk}
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : (
              "Ok"
            )}
          </button>,
        ]}
      >
       <Tabs  defaultActiveKey="1" tabPosition="left" size='large' items={[
      {
        label: 'Details',
        key: '1',
        children: 'Tab 1',
      },
      {
        label: 'Inventory',
        key: '2',
        children: 'Tab 2',
      },
      {
        label: 'Staff',
        key: '3',
        children: 'Tab 3',
      },
      
    ]}></Tabs>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewFarmBranch;
