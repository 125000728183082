import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Farms from "./components/Farms";
import Accounts from "./components/Account";
import FarmBranches from "./components/Farms/FarmBranches";
import UserGroups from "./components/Account/UserGroups";
import Animals from "./components/Animals";
import RegisterNewAnimal from "./components/Animals/RegisterNewAnimal";
import AttendanceLogs from "./components/Animals/AttendanceLogs";
import ResetPassword from "./components/Auth/ResetPassword";
import { logoutUser } from "./redux/slice/authSlice";
import { refreshToken } from "./redux/slice/authSlice";
import "./App.css";
import RegisterBreedType from "./components/Animals/RegisterBreedType";
import BreedTypes from "./components/Animals/BreedTypes";
import VaccinationLogs from "./components/Animals/VaccinationLogs";
import ViewAnimalHealthReport from "./components/Animals/ViewAnimalReport";
import ViewOtherAnimalOwners from "./components/Animals/ViewOtherAnimalOwners";
import ViewAnimalGiveAway from "./components/Animals/ViewAnimalGiveAway";
import AnimalSale from "./components/Animals/AnimalSale";
import QuickAccess from "./components/Animals/QuickAccess";

function App() {
  const [showTokenExpirationAlert, setShowTokenExpirationAlert] =
    useState(false);
  const navigate = useHistory();
  const dispatch = useDispatch();

  /**
   * @description Function to check if the token is about to expire
   * @param {*} token
   * @param {*} thresholdSeconds
   * @returns true / false
   */
  const isTokenAboutToExpire = (token, thresholdSeconds) => {
    const decodedToken = jwtDecode(token);
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    const expiryTimestamp = decodedToken.exp; // Expiry timestamp from the token

    // Calculate remaining time until expiry
    const remainingTime = expiryTimestamp - currentTimestamp;

    // Check if remaining time is less than the threshold
    return remainingTime <= thresholdSeconds;
  };

  // trigger expiration alert
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("user");
    if (token) {
      const aboutToExpire = isTokenAboutToExpire(token, 300);
      if (aboutToExpire) {
        setShowTokenExpirationAlert(true);
        showExpirationAlert();
      }
    }
  };

  // expiration alert
  const showExpirationAlert = () => {
    if (!showTokenExpirationAlert) {
      Swal.fire({
        title: "Your session is about to expire",
        text: "Please refresh your token.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Refresh Token",
      }).then((result) => {
        if (result.isConfirmed) {
          refreshUserToken();
        }
      });
    }
  };

  // refresh token
  const refreshUserToken = async () => {
    try {
      // Dispatch refreshToken action
      const response = await dispatch(refreshToken());
      if (response) {
        localStorage.removeItem("user");
      }

      Swal.fire({
        title: "Token refreshed successfully!",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate.push("/animals"); // Navigate to the /farms route after clicking OK
          setShowTokenExpirationAlert(false);
        }
      });
      localStorage.setItem("user", response.data.token);
    } catch (error) {
      // If token refresh fails, dispatch logoutUser action
      await dispatch(logoutUser());
      Swal.fire({
        title: "Token refreshed failed!",
        text: "You will be logged out & Redirected to login page",
        icon: "error",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate.push("/"); // Navigate to the /farms route after clicking OK
          dispatch(logoutUser());
          setShowTokenExpirationAlert(false);
        }
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 5 * 60 * 1000); // run every five minutes

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/farms" component={Farms} />
        <Route path="/accounts" component={Accounts} />
        <Route path="/farm/:id/branches" component={FarmBranches} />
        <Route path="/branches" component={FarmBranches} />
        <Route path="/account-types" component={UserGroups} />
        <Route path="/animals" component={Animals} />
        <Route path="/register-animal" component={RegisterNewAnimal} />
        <Route path="/register-breed-type" component={RegisterBreedType} />
        <Route path="/rollcall-logs" component={AttendanceLogs} />
        <Route path="/resetPassword/:username" component={ResetPassword} />
        <Route path="/breedTypes" component={BreedTypes} />
        <Route path="/vaccination-logs" component={VaccinationLogs} />
        <Route path="/animalreports" component={ViewAnimalHealthReport} />
        <Route path="/other-owners" component={ViewOtherAnimalOwners} />
        <Route path="/animal-giveaways" component={ViewAnimalGiveAway} />
        <Route path="/animal-sale" component={AnimalSale} />
        <Route path='/quick-access' component={QuickAccess} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
