import React, { useRef }  from 'react';
import { Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
import { Bar } from 'react-chartjs-2';
import PropTypes from "prop-types";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const AttendanceChart = ({ data, user, filters }) => {
  const chartRef = useRef(null);
  // Extracting data for visualization
  const rollCallNames = data.map(item => item.roll_call_name);
  const presentCounts = data.map(item => item.present_count);
  const absentCounts = data.map(item => item.absent_count);
  const notRolledCalledCounts = data.map(item => item.not_rolled_called_count);

  // Data for the chart
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartData = {
    labels: rollCallNames,
    datasets: [
      {
        id:1,
        label: 'Present',
        data: presentCounts,
        backgroundColor: 'rgba(75,192,192,0.6)',
      },
      {
        id:2,
        label: 'Absent',
        data: absentCounts,
        backgroundColor: 'rgba(255,99,132,0.6)',
      },
      {
        id:3,
        label: 'Not RollCalled',
        data: notRolledCalledCounts,
        backgroundColor: 'rgba(54,162,235,0.6)',
      },
    ],
  };

  // Chart options
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'RollCall Summary Chart ',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Roll Call Names',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Animal Count',
        },
      },
    },
  };

  // Function to generate PDF
  const generatePDF = () => {
    
    // Get the chart canvas element
    const chartCanvas = chartRef?.current?.canvas;

    // Convert the chart canvas to an image using html2canvas
    html2canvas(chartCanvas).then(canvas => {
        // Create a new jsPDF instance
        const pdf = new jsPDF();

        // Calculate the width and height of the PDF page
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Calculate the aspect ratio of the chart canvas
        const aspectRatio = canvas.width / canvas.height;

        // Calculate the height of the PDF page based on the width and aspect ratio
        const height = pdfWidth / aspectRatio;

        const currentDate = new Date().toLocaleDateString();
      pdf
        .text(`Date Printed: ${currentDate}`, 10, height + 10)
        .setFontSize(12)
        .setFont("Helvetica");

      pdf
        .text(`User Logged In: ${user}`, 10, height + 20)
        .setFontSize(12)
        .setFont("Helvetica");
      pdf
        .text(`Filter(s): ${filters}`, 10, height + 30)
        .setFontSize(12)
        .setFont("Helvetica");

        // Add the chart image to the PDF
        pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, pdfWidth, height);

        // Save the PDF
        pdf.save('attendance_chart.pdf');
    });
};
  
  return (
    <div>
      <button
        className="ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
        onClick={generatePDF}
      >
        Download Chart
      </button>
      <Bar options={options} data={chartData} ref={chartRef} />
    </div>
  );
};

AttendanceChart.propTypes= {
    data : PropTypes.object
}
export default AttendanceChart;
