import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../common/constants';


const initialState = {
  permissionsList: null,
    loading: false,
    error: null,
  };


  // Asynchronous thunk action
export const fetchPermissionsList = createAsyncThunk(
    'permissionsList/fetchPermissionsList',
    async (_, { getState, rejectWithValue }) => {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
  
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}roles/permissions`,
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      };
  
      try {
        const response = await axios.request(config);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const permissionsListSlice = createSlice({
    name: 'permissionsList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPermissionsList.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchPermissionsList.fulfilled, (state, action) => {
          state.loading = false;
          state.permissionsList = action.payload;
        })
        .addCase(fetchPermissionsList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default permissionsListSlice.reducer;