import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Nav from "../../nav";
import CreateUserGroupModal from "./CreateUserGroupModal";
import UpdatePermission from "./UpdatePermission";
import {apiRequest} from "../../../common/common";
import { API_BASE_URL } from "../../../common/constants";

function UserGroups() {
  const [userGroups, setUserGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [openDetails, setOpenDetails] = useState(false);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleDetailsModal = () => {
    setOpenDetails(!openDetails);
  };

  const getUserGroups = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setUserGroups(response.roles);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("Error Fetching Data.. Please try again");
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}roles`, "", "", onSuccess, onError);
  };
  useEffect(() => {
    getUserGroups();
  }, [refresh, branchSwitch]);

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-100 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className=" text-2xl float-start "> User Account Types</h1>
          <button
            type="button"
            onClick={handleModal}
            className=" text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 sm:ml-6"
          >
            + Create Account Type
          </button>
          <div>
            {loading ? (
              <div className="flex flex-col lg:flex-row space-y-2">
              {Array.from({ length: 6 }).map((_, index) => {
                return (
                  <div key={index} role="status" className=" max-w-lg animate-pulse m-4">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
                    <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                    <div className="h-2 bg-gray-300 rounded-full  mb-2.5"></div>
                    <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                    <div className="h-2 bg-gray-300 rounded-full  max-w-sm mb-2.5"></div>
                    <div className="h-2 bg-gray-300 rounded-full  max-w-sm"></div>
                    <span className="sr-only">Loading...</span>
                  </div>
                );
              })}
            </div>
            ) : userGroups.length === 0 ? (
              <p>You have no UserGroups</p>
            ) : (
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {userGroups.map((account, key) => {
                  return (
                    <div
                      key={key}
                      className="w-full h-44 flex flex-col justify-between  dark:bg-blue-100 bg-white dark:border-gray-300 rounded-lg border border-gray-400 mb-6 py-5 px-4"
                    >
                      <div>
                        <h4 className="text-gray-800  font-bold mb-3">
                          {account.display_name}
                        </h4>
                        <p className="text-gray-800  text-sm">
                          Number of Permissions/Rights : {account.permissions.length}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center justify-between text-gray-500 ">
                          <div>
                            {" "}
                            <p>Last Updated</p>
                            <p className="text-sm">
                              {new Date(account.updated_at).toLocaleString()}
                            </p>
                          </div>
                          <button
                            className="w-8 h-8 rounded-full bg-gray-800 dark:bg-gray-100 dark:text-gray-800 text-white flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-black hover:bg-black hover:text-white"
                            aria-label="edit note"
                            onClick={() => {
                              handleDetailsModal();
                              setId(account.id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-pencil"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z"></path>
                              <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                              <line
                                x1="13.5"
                                y1="6.5"
                                x2="17.5"
                                y2="10.5"
                              ></line>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateUserGroupModal
        open={open}
        setOpen={setOpen}
        setRefresh={setRefresh}
      />
      <UpdatePermission
        open={openDetails}
        setOpen={setOpenDetails}
        refresh={refresh}
        setRefresh={setRefresh}
        id={id}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default UserGroups;
