import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Table, Button, Result } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Nav from "../nav";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import { checkPermissionForUsers } from "../../common/Utilities";

function ViewAnimalReport() {
  const navigate = useHistory();
  const [animalStats, setAnimalStats] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deadAnimalsData, setDeadAnimalsData] = useState([]);
  const [selectedAnimalImages, setSelectedAnimalImages] = useState([]);
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false);
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);
  const [loading, setLoading] = useState(false);
  const [isLoginVisible, setIsLoginVisible] = useState(
    localStorage.getItem("dash_access")
  );
  const [password, setPassword] = useState("");
  const { user } = useSelector((state) => state.auth);

  const handleCardClick = (data) => {
    navigate.push("/animals", { state: data });
  };
  // console.log(localStorage.getItem("dash_access"));

  const getAnimalsStats = () => {
    const onSuccess = (response) => {
      setAnimalStats(response);
      toast.success(response.message);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.message);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-statistics`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedAnimalImages([]);
  };

  const handleImagesModalClose = () => {
    setIsImagesModalVisible(false);
  };

  const handleViewImagesClick = (record) => {
    const images = record.image_paths ? JSON.parse(record.image_paths) : [];
    setSelectedAnimalImages(Array.isArray(images) ? images : []);
    setIsImagesModalVisible(true);
  };
  const handleDeadAnimalCardClick = async () => {
    setIsModalVisible(true);
    setLoading(true);
    const onSuccess = (response) => {
      console.log(response);
      setDeadAnimalsData(response.dead_animals);
      toast.success(response.message);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}animals/dead-animals`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    {
      title: "Animal ID",
      dataIndex: ["animal", "tag_number"],
      key: "animal_id",
    },
    {
      title: "Cause of Death",
      dataIndex: "cause_of_death",
      key: "cause_of_death",
    },
    {
      title: "Date of Death",
      dataIndex: "date_of_death",
      key: "date_of_death",
    },
    {
      title: "View Images",
      key: "view_images",
      render: (text, record) => (
        <Button onClick={() => handleViewImagesClick(record)}>
          View Images
        </Button>
      ),
    },
  ];

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let userData = {
      username: user?.data?.user.username,
      password: password,
    };

    const onSuccess = (response) => {
      toast.success("Successful");
      setLoading(false);
      setIsLoginVisible(false);
      localStorage.setItem("dash_access", false);
    };
    const onError = (error) => {
      toast.error("Invalid Password");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}auth/login`,
      userData,
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (checkPermissionForUsers(16, userPermissions, permissionsList)) {
      getAnimalsStats();
    }
  }, []);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        {isLoginVisible && (
          <div className="fixed inset-0 bg-gradient-to-br from-gray-100 via-gray-200 to-gray-300 bg-opacity-20 z-10 flex justify-center items-center animate-fadeIn">
            <div className="bg-white p-8 rounded-xl mx-auto shadow-2xl max-w-md w-full animate-slideUp">
              <h2 className="text-2xl font-semibold text-center mb-6">
                You need Password to Access Content
              </h2>
              <div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-lg mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={handleLoginSubmit}
                  className="w-full py-3 rounded-lg text-white bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-600 hover:to-blue-700 transition-all duration-300 shadow-lg"
                >
                  Access
                </button>
              </div>
            </div>
          </div>
        )}{" "}
        (
        <div
          className={`p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14 ${isLoginVisible} ? "blur-lg" : ""`}
        >
          <button
            onClick={() => window.location.reload()}
            className="bg-red-500 text-white border border-red-500 rounded-md px-6 py-2"
          >
            Close Section
          </button>
          

          {checkPermissionForUsers(16, userPermissions, permissionsList) ? (
            <div className="container mx-auto px-4 py-8 rounded-lg shadow-lg bg-white dark:bg-gray-100">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="bg-gradient-to-tr from-blue-400 via-blue-600 to-blue-700 text-white rounded-lg p-6 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl">
                  <h2 className="text-xl font-semibold mb-2">
                    Animals Registered
                  </h2>
                  <p className="text-3xl font-bold">
                    {animalStats?.total_animals}
                  </p>
                </div>

                <div className="grid grid-cols-1 gap-4 md:col-span-2">
                  <div
                    onClick={() => {
                      navigate.push("/animals");
                    }}
                    className="bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700  text-white rounded-lg p-6 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl"
                  >
                    <h2 className="text-xl font-semibold mb-2">
                      Animals Available
                    </h2>
                    <p className="text-3xl font-bold">
                      {animalStats?.total_animals_alive}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      handleDeadAnimalCardClick();
                    }}
                    className="bg-gradient-to-r from-red-500 via-red-600 to-red-700  text-white rounded-lg p-6 flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:shadow-xl"
                  >
                    <h2 className="text-xl font-semibold mb-2">Animals Dead</h2>
                    <p className="text-3xl font-bold">
                      {animalStats?.total_dead_animals}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 md:col-span-2 lg:col-span-3">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                    {animalStats?.animals_by_health_status?.map(
                      (status, index) => (
                        <div
                          key={index}
                          onClick={() => handleCardClick(status)}
                          className=" bg-gradient-to-r from-green-700 via-green-600 to-blue-400 text-white rounded-lg p-6 flex flex-col items-center justify-center w-full transition-transform transform hover:scale-105 hover:shadow-xl"
                        >
                          <h2 className="text-xl font-semibold mb-2">
                            Animals With {status?.health_status} Health
                          </h2>
                          <p className="text-3xl font-bold">{status.total}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 md:col-span-2 lg:col-span-3">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                    {animalStats?.animals_by_purchase_origin?.map(
                      (origin, index) => (
                        <div
                          key={index}
                          onClick={() => handleCardClick(origin)}
                          className="bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 text-white rounded-lg p-6 flex flex-col items-center justify-center w-full transition-transform transform hover:scale-105 hover:shadow-xl"
                        >
                          <h2 className="text-xl font-semibold mb-2">
                            Animals {origin?.purchase_origin}
                          </h2>
                          <p className="text-3xl font-bold">{origin?.total}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Result
              status="403"
              title="Access Denied"
              subTitle="Sorry, you are not authorized to view this page."
              // extra={<button className=" hover:bg-blue-500 border-blue-500 text-blue-500  hover:text-white border px-1 py-2 rounded-lg">Go To Animals</button>}
            />
          )}
        </div>
        )
      </div>
      <Modal
        title="Dead Animals"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        <Table
          columns={columns}
          dataSource={deadAnimalsData}
          loading={loading}
          rowKey="id"
        />
      </Modal>
      <Modal
        title="Animal Images"
        open={isImagesModalVisible}
        onCancel={handleImagesModalClose}
        footer={null}
        width={900}
      >
        {selectedAnimalImages.length > 0 ? (
          <div className="flex flex-wrap">
            {selectedAnimalImages.map((img, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_PROD_IMG_URL}${img}`}
                alt={`animal-${index}`}
                className="w-64 h-64 mr-4 mb-4 rounded-md"
              />
            ))}
          </div>
        ) : (
          <p>No images available.</p>
        )}
      </Modal>
    </div>
  );
}

export default ViewAnimalReport;
