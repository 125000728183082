import React, { useEffect, useState } from "react";
import { Modal, Skeleton } from "antd";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";

import { API_BASE_URL } from "../../common/constants";
import { apiRequest, apiRequestWithImage } from "../../common/common";
import ImageGallery from "./ImageGallery";

function ViewAnimalAlbumModal({ open, setOpen, animal }) {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [uploadNew, setUploadNew] = useState(false);
  const [animalAlbum, setAnimalAlbum] = useState([]);
  const [formData, setFormData] = useState({
    date: "",
    images: [],
    comment: "",
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...formData.images];

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);
          const previewURL = URL.createObjectURL(compressedFile);

          updatedImages.push({ file: compressedFile, previewURL });

          if (index === files.length - 1) {
            setFormData((prevState) => ({
              ...prevState,
              images: updatedImages.slice(0, 3),
            }));
          }
        } catch (error) {
          console.error("Error while compressing the image:", error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleOk = () => {
    const data = new FormData();
    const onSuccess = (response) => {
      toast.success(response.message);
      setUploadNew(false);
      setFormData({
        date: "",
        images: [],
        comment: "",
      });
      setRefresh(!refresh);
    };
    const onError = (error) => {
      console.log(error);
      toast.error(error?.response?.data?.message);
    };

    data.append("animal_id", animal?.id);
    data.append("date_uploaded", formData.date);
    data.append("comment", formData.comment);
    formData.images.forEach((image) => {
      data.append("images[]", image.file);
    });

    apiRequestWithImage(
      "post",
      `${API_BASE_URL}animals/image-upload`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const fetchImages = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimalAlbum(response.images);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };
    let data = {
      animal_id: animal?.id,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-album`,
      "",
      data,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (animal?.id) {
      fetchImages();
    }
  }, [animal?.id]);

  useEffect(() => {
    fetchImages();
  }, [refresh]);

  useEffect(() => {
    if (
      animal?.id &&
      !animalAlbum.find(
        (item) =>
          item.animal_id === animal.id && item.created_at === animal.created_at
      )
    ) {
      const dateUploaded = new Date(animal?.created_at)
        .toISOString()
        .split("T")[0];
      const newRecord = {
        id: animalAlbum.length ? animalAlbum[0].id - 1 : 1,
        animal_id: animal?.id,
        date_uploaded: dateUploaded,
        images: animal?.image_paths,
        created_at: animal?.created_at,
        updated_at: animal?.updated_at,
      };

      const updatedImageData = [newRecord, ...animalAlbum];
      setAnimalAlbum(updatedImageData);
    }
  }, [animal, animalAlbum]);

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleCancel}
        footer={[
          <>
            {!uploadNew && (
              <button
                className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                key="upload-button"
                onClick={() => setUploadNew(true)}
              >
                Add New Images{" "}
              </button>
            )}
            ,
            <button
              onClick={handleCancel}
              className="mt-6 mr-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
              key="back"
            >
              Cancel
            </button>
            ,
            {uploadNew && (
              <button
                onClick={handleOk}
                className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                key="create-button"
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
                ) : (
                  "Upload Images"
                )}
              </button>
            )}
          </>,
        ]}
      >
        {uploadNew ? (
          <>
            <h1 className="text-xl mb-4">Upload Animal Images</h1>
            <div className="max-w-md mx-auto p-4">
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <input
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                  id="date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleChange}
                  placeholder=" "
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Date of Upload
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <textarea
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                  id="comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  placeholder="Add a comment"
                />
              </div>
              <div className="mb-4">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                />
              </div>
              <div className="mt-2 flex space-x-4">
                {formData.images?.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.previewURL}
                      alt={`animal ${index}`}
                      className="h-24 w-24 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleDeleteImage(index)}
                      className="absolute top-0 right-0 rounded-full bg-white p-1 shadow-sm"
                    >
                      <svg
                        className="h-4 w-4 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div>
            <h1 className="text-2xl bg-gradient-to-r from-red-500 via-blue-500 to-green-500 text-transparent bg-clip-text">Animal Album</h1>

            {loading ? (
              <Skeleton.Image active={loading} />
            ) : (
              animalAlbum?.map((animal) => (
                <div
                  key={animal.id}
                  className="mb-6 p-4 bg-slate-100 rounded-lg shadow-md"
                >
                  <div className="block text-lg font-medium mb-2">
                    Comment: {animal?.comment}
                  </div>
                  <ImageGallery images={JSON.parse(animal?.images)} />
                </div>
              ))
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}

ViewAnimalAlbumModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  animal: PropTypes.object,
};

export default ViewAnimalAlbumModal;
