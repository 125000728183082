import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { API_BASE_URL } from "../../common/constants";
import { useSelector } from "react-redux";
import { apiRequest } from "../../common/common";

function EditDetailsModal(props) {
  const { roles } = useSelector((state) => state.roles);
  const { branches } = useSelector((state) => state.branches.userBranches);
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phonenumber: "",
    role_id: "",
    role_name: "",
    email: "",
    branch_ids: [],
    permissions: [],
  });

  const fetchUserPermissions = async () => {
    if (!props.user) return;

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}roles/user-permissions?user_id=${props.user.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`, // Make sure this is the correct authorization header
      },
    };

    try {
      const response = await axios.request(config);
      setUserPermissions(response.data.permissions || []); // Ensure it is an array
    } catch (error) {
      toast.error("Error occurred while fetching user permissions.");
    }
  };

  const handleCheckboxChange = (event) => {
    const permissionId = parseInt(event.target.value);
    let updatedCheckedPermissions = [...checkedPermissions];

    if (event.target.checked) {
      // Add permissionId to checkedPermissions
      updatedCheckedPermissions.push(permissionId);
    } else {
      // Remove permissionId from checkedPermissions
      updatedCheckedPermissions = updatedCheckedPermissions.filter(
        (id) => id !== permissionId
      );
    }

    setCheckedPermissions(updatedCheckedPermissions);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (value, option) => {
    setFormData({
      ...formData,
      role_id: value,
      role_name: option.label,
    });
  };

  const handleBranchChange = (value) => {
    setFormData({
      ...formData,
      branch_ids: value,
    });
  };

  const options = roles?.map((role) => ({
    value: role.id.toString(),
    label: role.display_name,
  }));

  const branchOptions = branches?.map((branch) => ({
    value: branch.id.toString(),
    label: branch.name,
  }));

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleCancel = () => {
    props.setOpen(false);
  };

  const handleOk = async () => {
    setLoading(true);
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== "" && value !== null && key !== "role_name"
      )
    );

    const data = JSON.stringify(filteredFormData);


    const onSuccess = (response) => {
      toast.success(response.message);
      setLoading(false);
      props.setOpen(false);
      props.setRefresh(true);
    };

    const onError = (error) => {
      console.log(error)
      setLoading(false);
      toast.error("Error Occurred");
    };

       apiRequest(
        "post",
        `${API_BASE_URL}user/${props.user?.id}/update`,
        data,
        "",
        onSuccess,
        onError
      );

  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterBranchOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // Fetch user permissions when modal opens
  useEffect(() => {
    if (props.open) {
      fetchUserPermissions();
    }
  }, [props.open]);
  useEffect(() => {
    if (Array.isArray(userPermissions)) {
      setCheckedPermissions(userPermissions.map((permission) => permission.id));
    }
  }, [userPermissions]);

  useEffect(() => {
    if (props.user) {
      const userRole = roles.find((role) => role.id === props.user?.role_id);
      setFormData({
        name: props.user?.name || "",
        phonenumber: props.user?.phonenumber || "",
        role_id: props.user?.role_id || "",
        role_name: userRole ? userRole.display_name : "",
        email: props.user?.email || "",
        branch_ids:
          props.user?.branches?.map((branch) => branch.id.toString()) || [],
        permissions: checkedPermissions,
      });
    }
  }, [props.user, roles, checkedPermissions]);

  return (
    <div>
      <Modal
        open={props.open}
        title="Edit User Account"
        onCancel={handleCancel}
        width={600}
        footer={[
          <button
            className="mt-6  mr-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="button"
            key="create-button"
            data-ripple-light="true"
            onClick={handleOk}
            disabled={loading}
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
            ) : (
              "Edit Details"
            )}
          </button>,
        ]}
      >
        <div>
          <div className="mb-4 flex flex-col gap-6">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-64 lg:w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                placeholder=" "
                onChange={handleChange}
                value={formData.name}
                name="name"
                type="text"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Name
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-64 lg:w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                placeholder=" "
                onChange={handleChange}
                value={formData.email}
                name="email"
                type="email"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Email
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className="peer h-full w-64 lg:w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                placeholder=" "
                onChange={handleChange}
                value={formData.phonenumber}
                name="phonenumber"
                type="text"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Phone Number
              </label>
            </div>
            <Select
              placeholder="Select a Role"
              value={formData.role_id}
              onChange={handleRoleChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={options}
              style={{ width: "100%" }}
            />

            <div className="mb-4">
              <h3 className="text-sm font-medium text-gray-700">Permissions</h3>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {Array.isArray(props.user?.role?.permissions) &&
                  props.user.role.permissions.map((permission) => (
                    <div key={permission.id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        value={permission.id}
                        className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                        checked={checkedPermissions.includes(permission.id)}
                        onChange={handleCheckboxChange}
                      />
                     <span className="ml-2 text-sm text-gray-900">{permission.display_name}</span>
                    </div>
                  ))}
              </div>
            </div>
            <Select
              mode="multiple"
              placeholder="Select Branches"
              value={formData.branch_ids}
              onChange={handleBranchChange}
              onSearch={onSearch}
              filterOption={filterBranchOption}
              options={branchOptions}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default EditDetailsModal;
