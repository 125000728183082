import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import JSPDF from "jspdf";
import "jspdf-autotable";

function ViewSales({ open, setOpen }) {
  const [animalSales, setAnimalSales] = useState({
    animals: [],
    total_profit: 0,
    total_purchase: 0,
    total_sales: 0,
  });
   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");

  const handleCancel = () => {
    setOpen(false);
  };

 const firstDateOfCurrentMonth = new Date();
 firstDateOfCurrentMonth.setDate(1);

  const currentDate = new Date();
  const endDateOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  const getAnimalSales = () => {
    const onSuccess = (response) => {
      setAnimalSales(response);
    };
    const onError = (error) => {
      toast.error("Error Occurred", {
        theme: "colored",
      });
    };
   let params = {
     start_date:
       startDate || firstDateOfCurrentMonth.toISOString().split("T")[0],
     end_date: endDate || endDateOfCurrentMonth.toISOString().split("T")[0],
   };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/sales`,
      "",
      params,
      onSuccess,
      onError
    );
  };

   const generatePDF = () => {
     const doc = new JSPDF('landscape');
     const tableColumn = [
       "Name",
       "Tag Number",
       "Date of Purchase",
       "Date of Birth",
       "Date of Sale",
       "Days On Farm (POR)",
       "Purchase Price (UGX)",
       "Sale Price (UGX)",
     ];
     const tableRows = [];

     animalSales.animals.forEach((animal) => {
       const animalData = [
         animal.name,
         animal.tag_number,
         animal.DOP || "-",
         animal.DOB || "-",
         animal.DOS || "-",
         animal.POR || "-",
         parseInt(animal.purchase_price || 0).toLocaleString() || "-",
         parseInt(animal.sale_price).toLocaleString(),
       ];
       tableRows.push(animalData);
     });

     // Add totals row
     tableRows.push([
       "Totals",
       "",
       "",
       "",
       "",
       "",
       parseInt(animalSales.total_purchase || 0).toLocaleString() || "-",
       parseInt(animalSales.total_sales).toLocaleString(),
     ]);

     // Add profits row
     tableRows.push([
       "Profits",
       "",
       "",
       "",
       "",
       "",
       "",
       parseInt(animalSales.total_profit).toLocaleString(),
     ]);

     doc.autoTable(tableColumn, tableRows, { startY: 20 });
     doc.text("Animal Sales Report", 14, 15);
     doc.save("animal_sales.pdf");
   };

   useEffect(() => {
     if (open) {
       getAnimalSales();
     }
   }, [open, startDate, endDate]);

  return (
    <Modal open={open} onCancel={handleCancel} footer={null} width={1000}>
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Animal Sales</h2>
        <div className=" flex justify-end space-x-4 mb-4 ">
          <span className="px-2 py-1 text-2xl font-bold font-mono">Filters :</span>
          <input
            type="date"
            className="border rounded px-2 py-1"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
          />
          <input
            type="date"
            className="border rounded px-2 py-1"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={getAnimalSales}
          >
            Filter
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={generatePDF}
          >
            Download PDF
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tag Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date of Purchase
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date of Birth
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date of Sale
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Days On Farm (POR)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Purchase Price (UGX)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sale Price (UGX)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {animalSales.animals.map((animal, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {animal.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {animal.tag_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {animal.DOP || "-"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {animal.DOB || "-"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {animal.DOS}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {animal.POR}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-red-500">
                    {parseInt(animal.purchase_price || 0).toLocaleString() ||
                      "-"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-green-500">
                    {parseInt(animal.sale_price).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-gray-50">
              <tr>
                <td
                  colSpan="6"
                  className="px-6 py-3 text-sm font-medium text-gray-500"
                >
                  Totals:
                </td>
                <td className="px-6 py-3 text-sm text-red-500">
                  {parseInt(animalSales.total_purchase || 0).toLocaleString() ||
                    "-"}
                </td>
                <td className="px-6 py-3 text-sm text-green-500">
                  {parseInt(animalSales.total_sales).toLocaleString()}
                </td>
              </tr>
              <tr className="bg-gray-800 text-white">
                <td className="px-6 py-3 text-sm font-medium " colSpan="7">
                  Profits:
                </td>
                <td className="px-6 py-3 text-sm">
                  {parseInt(animalSales.total_profit).toLocaleString()}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Modal>
  );
}

export default ViewSales;
