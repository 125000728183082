import React, {useState, useEffect} from 'react';
import { Table, Skeleton, Empty } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {apiRequest} from '../../common/common';
import { API_BASE_URL } from '../../common/constants';
import Nav from '../nav';
import AnimalGiveAwayModal from "./AnimalGiveAwayModal"
import { checkPermissionForUsers } from '../../common/Utilities';

function ViewAnimalGiveAway() {
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [animals, setAnimals] = useState([]);
  const [openGiveAwayModel, setOpenGiveAwayModal] = useState(false);

    const [animalGiveAway, setAnimalGiveAway] = useState([]);
    const getAnimals = () => {
        setLoading(true);
       
    
        const onSuccess = (response) => {
          setAnimals(response.animals);
          setLoading(false);
        };
    
        const onError = (error) => {
          toast.error(error.response.data.error);
          setLoading(false);
        };
    
        apiRequest("get", `${API_BASE_URL}animals`, "", "", onSuccess, onError);
      };
    const columns = [
        {
            title: "No",
            dataIndex: "id",
            key: "id",
            width:30,
            render: (text, record, index) => <strong>{index + 1}</strong>,
          },
          {
            title: "Name",
            dataIndex: "name_of_receiver",
            key: "name",
            width: 250,
          },
          {
            title: "Farm Name",
            dataIndex: "farm_name",
            key: "farm_name",
            width: 150,
          },
          {
            title: "Contact",
            dataIndex: "contact",
            key: "contact",
            width: 150,
          },
          {
            title:"GiveAway Nature" ,
            dataIndex:"giveaway_type",
            key: "giveaway type",
            width: 150,
          },
          {
            title:"Animal",
            dataIndex:["animal", "tag_number"], 
            key: "tag_number",
            width: 150,
          },
          {
            title:"Date",
            dataIndex:"created_at",
            key:"created_at",
            width: 150,
            render: (text) => moment(text).format('MMMM Do YYYY, h:mm:ss a'),
          }
    ]

    const getAnimalGiveAways = async () => {
        setLoading(true);
        const onSuccess = (response) => {
            setAnimalGiveAway(response.data);
          setLoading(false);
        };
    
        const onError = () => {
          setLoading(false);
          toast.error("Error Fetching  data");
        };
    
        apiRequest(
          "get",
          `${API_BASE_URL}animals/animal-giveaway`,
          "",
          "",
          onSuccess,
          onError
        );
      };
    
      useEffect(() => {
        getAnimalGiveAways();
      }, []);
      useEffect(() => {
        getAnimalGiveAways();
      }, [refresh]);
      useEffect(() => {
        getAnimals();
      }, [refresh]);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className=" mt-14 p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
          <div className="flex flex-wrap justify-end">
            <div className="relative">
              {checkPermissionForUsers(21, userPermissions, permissionsList)&&<button  onClick={()=>{setOpenGiveAwayModal(true)}}  className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
>
                + Give Away Animal
              </button>}
            </div>
          </div>
          <div className="">
            <Table
              dataSource={loading ? [] : animalGiveAway}
              columns={columns}
              scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
              pagination={false}
              className=" whitespace-nowrap"
              locale={{
                emptyText: loading ? (
                  <Skeleton active paragraph={{ rows: 16 }} />
                ) : (
                  <Empty />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <AnimalGiveAwayModal
        open={openGiveAwayModel}
        setOpen={setOpenGiveAwayModal}
        setRefresh={setRefresh}
        animals={animals}
        refresh={refresh}
      />
    </div>
  )
}

export default ViewAnimalGiveAway