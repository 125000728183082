import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiRequest } from "../../common/common";
import Nav from "../nav";
import CreateFarmModal from "./CreateFarmModal";
import { API_BASE_URL } from "../../common/constants";
import { checkPermissionForUsers } from "../../common/Utilities";

function Farms() {
  const { permissionsList } = useSelector((state) => state.permissionsList);
  const { userPermissions } = useSelector((state) => state.userPermissions);
  const [farms, setFarms] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [branchSwitch, setBranchSwitch] = useState(false);

  const navigate = useHistory();
  const handleFarmClick = (farmId) => {
    navigate.push(`/farm/${farmId}/branches`);
  };

  const handleModal = () => {
    setOpenModal(true);
  };
  const getUserFarms = () => {
    const onSuccess = (response) => {
      setFarms(response.farms);
    };

    const onError = (error) => {
      toast.error("Error Occured");
    };

    apiRequest(
      "get",
      `${API_BASE_URL}farms/user/farms`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getUserFarms();
  }, [refresh]);

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          {checkPermissionForUsers(34, userPermissions, permissionsList) && (
            <button
              type="button"
              data-modal-target="crud-modal"
              data-modal-toggle="crud-modal"
              onClick={handleModal}
              // disabled
              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              {" "}
              + Create Farm
            </button>
          )}
          {farms.length === 0 ? (
            <p>You have no farms</p>
          ) : (
            <>
              {farms.map((farm, key) => {
                return (
                  <div
                    key={key}
                    className="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 "
                    onClick={() => handleFarmClick(farm.id)}
                  >
                    <h5 className="mb-2 text-3xl font-bold text-gray-900 ">
                      {farm.name}
                    </h5>
                    <p className="mb-5 text-base text-gray-500 sm:text-lg">
                      {farm.location}
                    </p>
                    <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
                      <span className="w-full sm:w-auto bg-gray-200 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300  rounded-lg inline-flex items-center justify-center px-4 py-2.5 ">
                        {farm.manager}
                      </span>
                      <span className="w-full sm:w-auto bg-gray-200 hover:bg-gray-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300  rounded-lg inline-flex items-center justify-center px-4 py-2.5 ">
                        {farm.contacts}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <CreateFarmModal
        open={openModal}
        setOpen={setOpenModal}
        setRefresh={setRefresh}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default Farms;
