// animalSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL } from '../../common/constants';


export const fetchAnimalBreeds = createAsyncThunk('animals/fetchBreeds', async (_, { getState }) => {
  try {
    const { user } = getState().auth;
    const accessToken = user?.data?.access_token;
    const response = await axios.get(`${API_BASE_URL}animals/breeds`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchAnimalTypes = createAsyncThunk('animals/fetchTypes', async (_, { getState }) => {
  try {
    const { user } = getState().auth;
    const accessToken = user?.data?.access_token;
    const response = await axios.get(`${API_BASE_URL}animals/types`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

const animalSlice = createSlice({
  name: 'animals',
  initialState: {
    breeds: [],
    types: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnimalBreeds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAnimalBreeds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.breeds = action.payload;
      })
      .addCase(fetchAnimalBreeds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAnimalTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAnimalTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.types = action.payload;
      })
      .addCase(fetchAnimalTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default animalSlice.reducer;
