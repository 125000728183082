import React, { useState, useEffect } from "react";
import { Table, DatePicker, Input, Divider, Tag, Drawer, Tabs } from "antd";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import Nav from "../nav/index";
import { API_BASE_URL } from "../../common/constants";
import {apiRequest} from "../../common/common";
import generatePDF from "../../common/PdfGenerator";
import VaccinationChart from "./charts/VaccinationChart";
import VaccinationPieChart from "./charts/VaccinationPieChart";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
function VaccinationLogs() {
  const { user } = useSelector((state) => state.auth);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [tagNumberFilter, setTagNumberFilter] = useState(null);
  const [, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [vaccinationSummary, setVaccinationSummary] = useState([]);
  const [vaccinationLogs, setVaccinationLogs] = useState([]);
  const [branchSwitch, setBranchSwitch] = useState(false);
  const [activeTab, setActiveTab] = useState("2");
  const [selectedChart, setSelectedChart] = useState("chart");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const resetFilters = () => {
    setEndDateFilter(null);
    setStartDateFilter(null);
    setTagNumberFilter(null);
  };

  const fetchLog = () => {
    const onSuccess = (response) => {
      setVaccinationLogs(response);
    };
    const onError = (error) => {
      toast.error("Error Occurred Fetching Logs");
    };
    let params = {
      start_date: startDateFilter && startDateFilter.format("YYYY-MM-DD"),
      end_date: endDateFilter && endDateFilter.format("YYYY-MM-DD"),
      tag_number: tagNumberFilter,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/vaccinations`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const fetchVaccinationSummary = () => {
    const onSuccess = (response) => {
      setVaccinationSummary(response.data);
    };
    const onError = (error) => {
      toast.error("Error Occurred Fetching Logs");
    };
    let params = {
      start_date: startDateFilter && startDateFilter.format("YYYY-MM-DD"),
      end_date: endDateFilter && endDateFilter.format("YYYY-MM-DD"),
    };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/vaccination/summary`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const summaryTableColumns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("ddd DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Total No of Animals",
      dataIndex: "total_animals",
      key: "total_animals",
      width: 175,
    },
    {
      title: "No of Animals Vaccinated",
      dataIndex: "vaccinated_animals",
      key: "vaccinated_animals",
      width: 250,
    },
    {
      title: "N0. of Animals UnVaccinated ",
      dataIndex: "not_vaccinated_animals",
      key: "not_vaccinated_animals",
      width: 250,
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Tag Number",
      dataIndex: "tag_number",
      key: "tag_number",
    },
    {
      title: "Vaccine Name",
      dataIndex: "vaccination_name",
      key: "vaccination_name",
    },
    {
      title: "Vaccine Type",
      dataIndex: ["type", "name"],
      key: "type",
    },
    {
      title: "Status",
      dataIndex: ["status", "name"],
      key: "status",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("ddd DD MMM YYYY HH:mm:ss"),
    },
  ];
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDateFilter(dates[0]);
      setEndDateFilter(dates[1]);
    }
  };

  const handleTagNumberChange = (e) => {
    setTagNumberFilter(e.target.value);
  };
  const onDownloadPdf = (event, items) => {
    event.preventDefault();

    if (typeof items !== "object" || items === null) {
      toast.error("Cannot generate Pdf");
      console.error("Items is not an Object or Array");
      return;
    }
    const filterText = [
      startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
      endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
      tagNumberFilter && `Tag Number: ${tagNumberFilter}`,
    ]
      .filter(Boolean)
      .join(", ");
    const tableRows = [];
    const tableColumns = [
      "No",
      "TagNumber",
      "Vaccine Type",
      "Vaccine Name ",
      "Status",
      "Comment",
      "date",
    ];

    Object.keys(items).forEach((key, index) => {
      // Access the array of items for the current key
      const itemArray = items[key];

      // Iterate over the array of items
      itemArray.map((item) => {
        const temp = { ...item };

        // Push relevant data to tableRows
        const rowData = [
          index + 1,
          temp.tag_number,
          temp?.type?.name,
          temp?.vaccination_name,
          temp?.status?.name,
          temp.comment || "",
          moment(temp.created_at).format("ddd DD MMM YYYY HH:mm:ss"),
        ];
        tableRows.push(rowData);
      });
    });

    generatePDF(
      tableColumns,
      tableRows,
      "Vaccination Logs",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };
  const filterText = [
    startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
    endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
  ]
    .filter(Boolean)
    .join("-");
  const onDownloadPdf2 = (event, items) => {
    event.preventDefault();
    if (!Array.isArray(items)) {
      toast.error("Cannot Generate PDF");
      console.error("Vaccination data is not an array");
      return;
    }

    const filterText = [
      startDateFilter && `Start Date: ${startDateFilter.format("YYYY-MM-DD")}`,
      endDateFilter && `End Date: ${endDateFilter.format("YYYY-MM-DD")}`,
    ]
      .filter(Boolean)
      .join("-");

    const tableColumns = [
      "No",
      "Date Performed",
      "Total Animals",
      "Vaccinated Animals",
      "Unvaccinated Animals",
    ];
    const tableRows = items.map((item, index) => [
      index + 1,
      moment(item.created_at).format("ddd DD MMM YYYY HH:mm:ss"),
      item.total_animals,
      item.vaccinated_animals,
      item.not_vaccinated_animals,
    ]);

    // Generate and download the PDF
    generatePDF(
      tableColumns,
      tableRows,
      "Vaccination Logs Summary",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };
  useEffect(() => {
    fetchLog();
  }, [startDateFilter, endDateFilter, tagNumberFilter]);

  useEffect(() => {
    fetchVaccinationSummary();
  }, [startDateFilter, endDateFilter]);

  return (
    <div>
      <Nav branchSwitch={branchSwitch} setBranchSwitch={setBranchSwitch} />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed h-100 rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <button
            onClick={(e) => {
              parseInt(activeTab) === 1
                ? onDownloadPdf(e, vaccinationLogs)
                : onDownloadPdf2(e, vaccinationSummary);
            }}
            className="float-right ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
          >
            Generate PDf
          </button>
          <button
            onClick={toggleFilters}
            type="button"
            className=" float-end bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </button>
          <div>
            <Drawer
              title="Filters"
              placement="right"
              closable={true}
              onClose={onClose}
              open={showFilters}
              getContainer={false}
            >
              <div className="mt-4">
                <span className="text-xl float-start">Select Date Range</span>
                <RangePicker onChange={handleDateChange} className="w-full" />
              </div>

              {parseInt(activeTab) === 1 && (
                <div className="mt-4">
                  <span className="text-xl float-start">Input Tag Number</span>
                  <Input
                    placeholder="Tag Number"
                    value={tagNumberFilter}
                    onChange={handleTagNumberChange}
                  />
                </div>
              )}
              <Divider />
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
                  onClick={() => {
                    setShowFilters(false);
                  }}
                >
                  Apply Filters
                </button>
                <button
                  onClick={resetFilters}
                  className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
                >
                  Reset Filters
                </button>
              </div>
            </Drawer>
          </div>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={handleTabChange}
            centered
            className="w-full"
          >
            <TabPane
              tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  logs
                </div>
              }
              key="1"
            >
              <div>
                <h1 className=" text-2xl mb-2 float-start bg-gradient-to-r from-blue-500 to-green-500 via-red-500 text-transparent bg-clip-text font-bold">
                  Animals Track Log
                </h1>

                <Table
                  columns={columns}
                  dataSource={vaccinationLogs}
                  scroll={{ x: true, y: 700 }}
                  pagination={false}
                  className=" whitespace-nowrap"
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  Summary
                </div>
              }
              key="2"
            >
              <h1 className=" text-2xl mb-2 float-start bg-gradient-to-r to-red-500 via-blue-500  from-green-500 text-transparent bg-clip-text font-bold">
                Summary Track Log
              </h1>
              <Table
                dataSource={vaccinationSummary}
                scroll={{ x: true, y: 700 }} // Set scroll properties for fixed header
                pagination={false}
                className=" whitespace-nowrap"
                columns={summaryTableColumns}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="bg-gradient-to-r from-red-500 to-blue-500  text-transparent bg-clip-text font-bold text-2xl">
                  Reports
                </div>
              }
              key="3"
            >
              <div className="flex justify-evenly">
                <button
                  className="button"
                  onClick={() => setSelectedChart("chart")}
                >
                  Show Chart
                </button>
                <button onClick={() => setSelectedChart("pieChart")}>
                  Show Pie Chart
                </button>
              </div>
              {/* Conditional rendering based on selectedChart state */}
              {selectedChart === "chart" && (
                <VaccinationChart data={vaccinationSummary}  user={user?.data?.user?.name} filters={filterText} />
              )}
              {selectedChart === "pieChart" && (
                <VaccinationPieChart data={vaccinationSummary}  user={user?.data?.user?.name} filters={filterText} />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default VaccinationLogs;
