import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Divider, Drawer, Select, Table, Skeleton, Empty } from "antd";
import { useSelector } from "react-redux";
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";
import generatePDF from "../../common/PdfGenerator";

function BreedTypes({ open, setOpen }) {
  const { breeds, types } = useSelector((state) => state.animals);
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [breedTypes, setBreedTypes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [animalType, setAnimalType] = useState(null);
  const [animalBreed, setAnimalBreed] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useHistory();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const fetchBreedTypes = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setBreedTypes(response.breedTypes);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occured Fetching Breed Types...");
      setLoading(false);
    };
    let params = {
      animal_type_id: animalType,
      breed_id: animalBreed,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/breedtypes`,
      "",
      params,
      onSuccess,
      onError
    );
  };
  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setAnimalBreed(null);
    setAnimalType(null);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "breed_type_id",
      key: "breed_type_id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
      width: 10,
    },
    {
      title: "Name",
      dataIndex: "breed_type_name",
      key: "breed_type_name",
      width: 250,
    },
    {
      title: "Animal Type",
      dataIndex: "animal_type_name",
      key: "animal_type_name",
      width: 150,
    },
    {
      title: "Animal Breed",
      dataIndex: "breed_name",
      key: "breed_name",
      width: 150,
    },
  ];

  /**
   * //  Transform roles data into options array
   */
  const breedOptions = breeds
    ?.filter((breed) => parseInt(breed.animal_type_id) === parseInt(animalType))
    .map((breed) => ({
      value: breed.id.toString(),
      label: breed.animal_breeds,
    }));

  /**
   * // Transform branches data into options array
   */

  const typeOptions = types?.map((type) => ({
    value: type.id.toString(),
    label: type.animal_types,
  }));
  const onSearch = (value) => {
    console.log("search:", value);
  };
  //    Filter `option.label` match the user type `input`
  const filterBreedOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterTypeOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  /**
   * generating and downloading a pdf
   * @argument event
   * @argument items animals array
   * @description function responsible for generating  and creating a pdf
   * @fires generatePDF function
   */
  const onDownloadPdf = (event, items) => {
    event.preventDefault();

    if (!Array.isArray(items)) {
      toast.error("Cannot Generate pdf");
      console.error("Data is not an array");
      return;
    }

    // Map animal type IDs to their names
    const animalTypeMap = {};
    types.forEach((type) => {
      animalTypeMap[type.id] = type.animal_types;
    });

    // Map animal breed IDs to their names
    const filteredBreeds = breeds.filter(
      (breed) => parseInt(breed.animal_type_id) === parseInt(animalType)
    );
    const animalBreedMap = {};
    filteredBreeds.forEach((breed) => {
      animalBreedMap[breed.id] = breed.animal_breeds;
    });

    // Construct the filter text
    const filterText = [
      animalType && `Animal Type: ${animalTypeMap[animalType]}`,
      animalBreed && `Animal Breed: ${animalBreedMap[animalBreed]}`,
    ]
      .filter(Boolean)
      .join(", ");

    const tableRows = [];
    const tableColumns = ["No", "Name", "Animal Type", "Animal Breed"];

    items.forEach((item, index) => {
      const rowData = [
        index + 1,
        item.breed_type_name,
        item.animal_type_name,
        item.breed_name,
      ];
      tableRows.push(rowData);
    });

    generatePDF(
      tableColumns,
      tableRows,
      "Animal Breed Types",
      new Date(),
      user?.data?.user?.name,
      filterText
    );
  };
  useEffect(() => {
    fetchBreedTypes();
  }, []);
  useEffect(() => {
    fetchBreedTypes();
  }, [animalBreed, animalType]);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <h1 className=" text-2xl float-start">Animal Breed Types </h1>
          <div className="flex flex-wrap justify-end">
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 lg:hidden"
              >
                Actions
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                  <button
                    className="block px-4 py-2 m-4 w-40 hover:bg-gray-300  text-left text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm  me-2 mb-2"
                    onClick={(e) => {
                      onDownloadPdf(e, breedTypes);
                      closeDropdown();
                    }}
                  >
                    Download Pdf
                  </button>
                  <button
                    className="block px-4 py-2 m-4 w-40 hover:bg-gray-300  text-left text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm  me-2 mb-2"
                    onClick={() => {
                      navigate.push("/register-breed-type");
                      closeDropdown();
                    }}
                  >
                    Add Breed Type
                  </button>
                  <button
                    className="block px-4 py-2 m-4 w-40 hover:bg-gray-300  text-left text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200 font-medium rounded-lg text-sm  me-2 mb-2"
                    onClick={toggleFilters}
                  >
                    Filters
                  </button>
                </div>
              )}
            </div>
            <div className="hidden lg:flex float-end">
              <button
                className=" text-white bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                onClick={(e) => {
                  onDownloadPdf(e, breedTypes);
                }}
              >
                Download Pdf
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate.push("/register-breed-type");
                }}
                className=" text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Add Breed Type
              </button>
              <button
                onClick={toggleFilters}
                className=" text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Filters
              </button>
            </div>
          </div>
          <Table
            dataSource={loading ? [] : breedTypes}
            columns={columns}
            scroll={{ x: true, y: 700 }}
            pagination={false}
            className=" whitespace-nowrap"
            locale={{
              emptyText: loading ? (
                <Skeleton active paragraph={{ rows: 16 }} />
              ) : (
                <Empty />
              ),
            }}
          />
        </div>
      </div>
      <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={onClose}
        open={showFilters}
        getContainer={false}
      >
        <div className="relative h-11 w-full min-w-[200px]">
          <label className="">Filter By Animal Type</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Animal Type"
              optionFilterProp="children"
              className=" w-full"
              onChange={(value, option) => {
                // Update branchId state with selected branch ID
                setAnimalType(value);
              }}
              onSearch={onSearch}
              filterOption={filterTypeOption}
              size="large"
              options={typeOptions}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Breed</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Animal Breed"
              optionFilterProp="children"
              className=" w-full"
              onChange={(value, option) => {
                // Update roleId state with selected role ID
                setAnimalBreed(value);
              }}
              onSearch={onSearch}
              filterOption={filterBreedOption}
              size="large"
              options={breedOptions}
            />
          </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-4">
          <button
            type="button"
            className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
            onClick={() => {
              setShowFilters(false);
            }}
          >
            Apply Filters
          </button>
          <button
            onClick={resetFilters}
            className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            Reset Filters
          </button>
        </div>
      </Drawer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

BreedTypes.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
export default BreedTypes;
