// rolesSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from '../../common/constants';


export const fetchUserRoles = createAsyncThunk(
  "roles/getRoles",
  async (_, { getState }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}roles`,
        {
          headers: {},
        }
      );
      return response.data.roles;
    } catch (error) {
      throw error;
    }
  }
);

const roleSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload;
      })
      .addCase(fetchUserRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default roleSlice.reducer;
