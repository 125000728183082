import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Divider } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { API_BASE_URL } from "../../../common/constants";

function UpdatePermission(props) {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [checkedPermissionIds, setCheckedPermissionIds] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [step2FormData, setStep2FormData] = useState([]);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      props.setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    props.setOpen(false);
    setEditMode(false);
  };
  const getRoleDetails = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}roles/role/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setRole(response.data.role);
        setCheckedPermissions(response.data.role.permissions);
        setCheckedPermissionIds(
          response.data.role.permissions.map((permission) => permission.id)
        );
        setStep2FormData(
          response.data.role.permissions.map((permission) => permission.id)
        );
      })
      .catch((error) => {
        toast.error("Error Occured fetching data");
      });
  };

  //   fetch all permissions
  const getPermissions = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}roles/permissions`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setPermissions(response.data.permissions);
      })
      .catch((error) => {
        toast.error("Error Occurred Fetching data ");
      });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCheckboxChange = (permId) => {
    // Check if the permission is already selected
    const index = checkedPermissionIds.indexOf(permId);
    if (index === -1) {
      // Permission not selected, add it to the array
      setCheckedPermissionIds([...checkedPermissionIds, permId]);
    } else {
      // Permission already selected, remove it from the array
      setCheckedPermissionIds(
        checkedPermissionIds.filter((id) => id !== permId)
      );
    }
  };

  const handlePermissionsUpdate = async (baseArray, newArray) => {
    setLoading(true);
    const baseSet = new Set(baseArray);
    const newIds = newArray.filter((id) => !baseSet.has(id));
    const missingIds = baseArray.filter((id) => !new Set(newArray).has(id));

    if (newIds.length > 0) {
      let data = JSON.stringify({
        role_id: role.id,
        permission_ids: newIds,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}roles/role/${props.id}/permissions`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
        data: data,
      };

      await axios
        .request(config)
        .then((response) => {
          toast.success("Permissions/Rights updated");
          props.setOpen(false);
          props.setRefresh(true);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Error Occured");
        });
    }

    if (missingIds.length > 0) {
      let data = JSON.stringify({
        role_id: role.id,
        permission_ids: missingIds,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}roles/role/3/remove-permissions`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
        data: data,
      };

      await axios
        .request(config)
        .then((response) => {
          toast.success("Permissions Updated");
          props.setOpen(false);
          props.setRefresh(true);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Error Occured");
        });
    }
  };

  useEffect(() => {
    props.id > 0 && getRoleDetails(props.id);
    getPermissions();
  }, [props.id]);

  return (
    <div>
      {" "}
      <Modal
        open={props.open}
        title="User Account Details"
        onOk={handleOk}
        width={900}
        onCancel={handleCancel}
        footer={[
          <>
            <button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleEdit}
              className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              disabled={editMode || loading}
            >
              Remove / add Permissions/Rights
            </button>
            ,
            {editMode && (
              <button
                type="button"
                className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                onClick={() => {
                  handlePermissionsUpdate(step2FormData, checkedPermissionIds);
                }}
              >
                {" "}
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                ) : (
                  "Update User Permissions/Rights"
                )}
              </button>
            )}{" "}
          </>,
          <button
            key="back"
            className="mt-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            onClick={handleCancel}
          >
            Close
          </button>,
        ]}
      >
        <p>
          <span className=" text-lg font-semibold">Account Name</span> :{" "}
          {role.display_name}
        </p>
        <p>
          <span className=" text-lg font-semibold">Account Nature</span> :{" "}
          {role.name}
        </p>
        {/* <h1>No Of Permissions : {role.permissions.length}</h1> */}
        <Divider>Permissions/Rights List </Divider>
        {editMode
          ? permissions.map((perm) => {
              return (
                <div key={perm.id} className="flex flex-row mt-2">
                  <input
                    type="checkbox"
                    id={`cb${perm.id}`}
                    onChange={() => handleCheckboxChange(perm.id)}
                    checked={checkedPermissionIds.includes(perm.id)}
                    className="appearance-none h-6 w-6 bg-gray-400 rounded-full  checked:bg-green-300 checked:scale-75transition-all duration-200 peer"
                  />
                  <div className="h-6 w-6 absolute rounded-full pointer-events-none peer-checked:border-green-300 peer-checked:border-2"></div>
                  <label
                    htmlFor={`cb-${perm.id}`}
                    className="flex flex-col justify-center px-2 peer-checked:text-green-400  select-none"
                  >
                    {perm.display_name}
                  </label>
                </div>
              );
            })
          : checkedPermissions.length > 0 &&
            checkedPermissions.map((permission) => (
              <div key={permission.id} className="flex bg-white px-6 mt-2">
                <div className=" border-l-2 border-dashed">
                  <div className="relative w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="ml-6">
                      <h4 className="font-bold text-blue-500">
                        {permission.display_name}
                      </h4>
                      <p className="mt-2 max-w-screen-sm text-sm text-gray-500">
                        {permission.description}
                      </p>
                      {/* <span className="mt-1 block text-xs font-semibold text-blue-300">
                        Last Updated At:{" "}
                        {new Date(role.updated_at).toLocaleString()}
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default UpdatePermission;
