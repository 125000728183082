// Import necessary modules
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";

// Define the async thunk for fetching the active branch
export const fetchActiveBranch = createAsyncThunk(
  "activeBranch/fetchActiveBranch",
  async (_, { getState }) => {
    try {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await axios.get(
        `${API_BASE_URL}farms/user/active-branch`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Extract the active_branch object from the response data
      const activeBranch = response.data.active_branch;
      return activeBranch || null; // Return null if active_branch is not found
    } catch (error) {
      throw error;
    }
  }
);

// Define the activeBranch slice
const activeBranchSlice = createSlice({
  name: "activeBranch",
  initialState: {
    activeBranch: [ ],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected actions for fetchActiveBranch
      .addCase(fetchActiveBranch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActiveBranch.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activeBranch = action.payload;
      })
      .addCase(fetchActiveBranch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default activeBranchSlice.reducer;
