import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/common";
import { API_BASE_URL } from "../../common/constants";
import Nav from "../nav";
import { Input, Select, Form } from "antd";
import ViewSales from "./ViewSales";

const { Option } = Select;

function AnimalSale() {
  const [animals, setAnimals] = useState([]);
  const [formData, setFormData] = useState([
    { animal_id: "", DOS: "", sale_price: "" },
  ]);
  const [openModal, setOpenModal] = useState(false);

  const handleModal = ()=>{
    setOpenModal(true)
  }

  const getAnimals = () => {
    const onSuccess = (response) => {
      setAnimals(response.animals);
    };

    const onError = (error) => {
      toast.error("Error Occurred Fetching Data", {
        theme: "colored",
      });
    };

    apiRequest("get", `${API_BASE_URL}animals`, "", "", onSuccess, onError);
  };

  const handleInputChange = (index, event) => {
    const values = [...formData];
    values[index][event.target.name] = event.target.value;
    setFormData(values);
  };
   const handleSelectChange = (index, value) => {
     const newFormData = [...formData];
     newFormData[index]["animal_id"] = value;
     setFormData(newFormData);
   };

  const handleAddAnimal = () => {
    setFormData([...formData, { animal_id: "", DOS: "", sale_price: "" }]);
  };

  const handleRemoveAnimal = (index) => {
    const values = [...formData];
    values.splice(index, 1);
    setFormData(values);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const onSuccess = () => {
      toast.success("Animal sales recorded successfully!");
       setFormData([{ animal_id: "", DOS: "", sale_price: "" }]);
    };

    const onError = () => {
      toast.error("Error occurred while recording sales.");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}animals/sales`,
      { animals: formData },
      "",
      onSuccess,
      onError
    );
  };

  const animalOptions =
   animals !== undefined &&
    animals?.map((animal) => ({
      value: animal.id,
      label: `${animal.tag_number} - ${animal.name}`,
    }));

  useEffect(() => {
    getAnimals();
  }, []);
  console.log(formData);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-24 lg:mt-14">
          <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-6">Record Animal Sales</h2>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                {formData?.map((item, index) => (
                  <div
                    key={index}
                    className="mb-4 p-4 md:p-6 border rounded-lg bg-white shadow-sm"
                  >
                    <div className="flex flex-col space-y-4">
                      <Form.Item label="Animal ID" required>
                        <Select
                          showSearch
                          placeholder="Select Animal"
                          value={item.animal_id}
                          onChange={(value) => handleSelectChange(index, value)}
                          options={animalOptions}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          className="w-full"
                        >
                          {animals.map((animal) => (
                            <Option key={animal.id} value={animal.id}>
                              {animal.id} - {animal.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Date of Sale" required>
                        <Input
                          type="date"
                          name="DOS"
                          value={item.DOS}
                          onChange={(event) => handleInputChange(index, event)}
                          max={new Date().toISOString().split("T")[0]}
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item label="Sale Price" required>
                        <Input
                          type="number"
                          name="sale_price"
                          value={item.sale_price}
                          onChange={(event) => handleInputChange(index, event)}
                          min="0"
                          step="0.01"
                          className="w-full"
                        />
                      </Form.Item>
                    </div>
                    {formData.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveAnimal(index)}
                        className="mt-4 text-red-600 hover:text-red-900"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>

              <button
                type="button"
                onClick={handleAddAnimal}
                className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Add Another Animal
              </button>
              <button
                type="submit"
                className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
              >
                Submit Sales
              </button>
            </form>
          </div>
          <button
            type="button"
            onClick={handleModal}
            className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
          >
            View  Animal Sales
          </button>
        </div>
      </div>
      <ViewSales open={openModal} setOpen={setOpenModal} animals={animals} />
    </div>
  );
}

export default AnimalSale;
