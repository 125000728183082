import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { API_BASE_URL } from "../../common/constants";

const initialState = {
  userPermissions: null,
  loading: false,
  error: null,
};


// Asynchronous thunk action
export const fetchUserPermissions = createAsyncThunk(
    'permissions/fetchUserPermissions',
    async (_, { getState, rejectWithValue }) => {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
  
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}roles/user-permissions`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        params: { user_id: user?.data?.user?.id }
      };
  
      try {
        const response = await axios.request(config);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const userPermissionsSlice = createSlice({
    name: 'user_permissions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUserPermissions.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchUserPermissions.fulfilled, (state, action) => {
          state.loading = false;
          state.userPermissions = action.payload;
        })
        .addCase(fetchUserPermissions.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  export default userPermissionsSlice.reducer;