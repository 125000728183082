import React, { useState } from "react";
import { Modal } from "antd";
import {toast, ToastContainer} from  'react-toastify';
import {apiRequest} from "../../common/common";
import { API_BASE_URL } from "../../common/constants";

function VaccinationModal(props) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    vaccineName: "",
    vaccinationDate: "",
    notes: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.vaccineName) {
      newErrors.vaccineName = "Vaccine Name is required";
    }
    if (!formData.vaccinationDate.trim()) {
      newErrors.vaccinationDate = "Vaccination Date is required";
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleOk = async () => {
    setLoading(true);
    if (!validateForm()) {
      return;
    }

    const data = JSON.stringify({
      vaccine_name: formData.vaccineName,
      vaccination_date: formData.vaccinationDate,
      notes: formData.notes
    });

    const onSuccess = (response) => {
      toast.success(response.message);
      props.setOpen(false);
      props.setRefresh(true);
    };

    const onError = (error) => {
      toast.error("Error Occurred ... Try Again");
    };

    apiRequest(
      "post",
      `${API_BASE_URL}animals/animal/${props.animal.id}/vaccination`,
      data,
      "",
      onSuccess,
      onError,
    );
  };

  const handleCancel = () => {
    props.setOpen(false);
  };
  return (
    <div>
      <Modal
        open={props.open}
        title="Add Vaccination/Treatment History"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button
            key="back"
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            key="submit"
            className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </button>,
        ]}
      >
        <p className="text-2xl  items-center">Vaccination / Treatment Form</p>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                  errorMessage.vaccineName && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.vaccineName}
                name="vaccineName"
                type="text"
              />
              {errorMessage.vaccineName && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.vaccineName}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Vaccine Name
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 ${
                  errorMessage.vaccinationDate && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.vaccinationDate}
                name="vaccinationDate"
                type="date"
              />
              {errorMessage.vaccinationDate && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.vaccinationDate}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Vaccination Date
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <textarea
                className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                placeholder=" "
                onChange={handleChange}
                value={formData.notes}
                rows={3}
                cols={3}
                name="notes"
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Notes
              </label>
            </div>
          </div>
        </form>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default VaccinationModal;
