import CryptoJS from "crypto-js";

// Define a constant seed value for key generation
const SEED_VALUE = "your_seed_value_here"; // Make sure to replace this with a secure value

// Generate the SECRET_KEY using a consistent method
const generateSecretKey = () => {
    const buffer = new TextEncoder().encode(SEED_VALUE);
    const hash = CryptoJS.SHA256(buffer).toString();
    return hash.substring(0, 32); // Ensure key length is 32 bytes (256 bits)
};

// Generate the secret key once when the module is imported
const SECRET_KEY = generateSecretKey();

export const encryptUserData = (userData) => {
  
    const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(userData),
        SECRET_KEY
    ).toString();
    localStorage.setItem("encryptedData", encryptedData);
};

export const decryptUserData = () => {
    const encryptedData = localStorage.getItem("encryptedData");
    if (encryptedData) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedData) {
                return JSON.parse(decryptedData);
            } else {
                console.error("Decrypted data is empty or invalid.");
                return null;
            }
        } catch (error) {
            console.error("Error decrypting user data:", error);
            return null;
        }
    }
    return null;
};



// Utility function to check if permission IDs exist in both data objects
export const checkPermissionForUsers = (id, dataObject1, dataObject2) => {
  const permissions1 = dataObject1?.permissions.map(permission => permission.id);
  const permissions2 = dataObject2?.permissions.map(permission => permission.id);
  
  return permissions1.includes(id) && permissions2.includes(id);
};