import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  FaTag,
  FaBirthdayCake,
  FaPalette,
  FaWeight,
  FaTint,
  FaHeartbeat,
  FaMapMarkerAlt,
  FaPhone,
  FaUser,
  FaVenusMars,
  FaAmericanSignLanguageInterpreting,
} from "react-icons/fa";
import { BsCalendar2DateFill } from "react-icons/bs";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { Modal, Tabs, Divider, Skeleton } from "antd";
import { apiRequest, apiRequestWithImage } from "../../common/common";
import moment from "moment";
import { API_BASE_URL } from "../../common/constants";
import ImageGallery from "./ImageGallery";
import imageCompression from "browser-image-compression";

const { TabPane } = Tabs;
function ViewAnimalDetails(props) {
  const [animalDetails, setAnimalDetails] = useState([]);
  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState(false);
  const [uploadNew, setUploadNew] = useState(false);
  const [animalAlbum, setAnimalAlbum] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1"); // Track active tab key
  const [formData, setFormData] = useState({
    date: "",
    images: [],
    comment: "",
  });
  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return null;
    const dob = moment(dateOfBirth);
    const diffDuration = moment.duration(moment().diff(dob));

    let years = diffDuration.years();
    let months = diffDuration.months();
    let days = diffDuration.days();

    let ageString = "";

    if (years > 0) {
      ageString += `${years} ${years > 1 ? "years" : "year"}`;
    }

    if (months > 0) {
      ageString += `${ageString.length > 0 ? ", " : ""}${months} ${
        months > 1 ? "months" : "month"
      }`;
    }

    if (days > 0) {
      ageString += `${ageString.length > 0 ? ", " : ""}${days} ${
        days > 1 ? "days" : "day"
      }`;
    }

    return ageString;
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key); // Update active tab key
  };

  useEffect(() => {
    getAnimalDetails();
  }, [props.id]);

  const getAnimalDetails = async () => {
    if (props.id !== undefined && props.id > 0) {
      const onSuccess = (response) => {
        setAnimalDetails(response.animal);
        setRefresh(true);
      };

      const onError = (error) => {
        toast.error("Error Occurred Fetching Data");
      };

      apiRequest(
        "get",
        `${API_BASE_URL}animals/animal/${props.id}`,
        "",
        "",
        onSuccess,
        onError
      );
    }
  };
  const handleOk = async () => {
    props.setOpen(false);
    setLoading(true);
  };

  const handleCancel = () => {
    props.setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...formData.images];

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);
          const previewURL = URL.createObjectURL(compressedFile);

          updatedImages.push({ file: compressedFile, previewURL });

          if (index === files.length - 1) {
            setFormData((prevState) => ({
              ...prevState,
              images: updatedImages.slice(0, 3),
            }));
          }
        } catch (error) {
          console.error("Error while compressing the image:", error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = () => {
    const data = new FormData();
    const onSuccess = (response) => {
      toast.success(response.message);
      setUploadNew(false);
      setFormData({
        date: "",
        images: [],
        comment: "",
      });
      setRefresh(!refresh);
    };
    const onError = (error) => {
      console.log(error);
      toast.error(error?.response?.data?.message);
    };

    data.append("animal_id", props.id);
    data.append("date_uploaded", formData.date);
    data.append("comment", formData.comment);
    formData.images.forEach((image) => {
      data.append("images[]", image.file);
    });

    apiRequestWithImage(
      "post",
      `${API_BASE_URL}animals/image-upload`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const fetchImages = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAnimalAlbum(response.images);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };
    let data = {
      animal_id: props.id,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}animals/animal-album`,
      "",
      data,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (props.id) {
      fetchImages();
    }
  }, [props.id]);

  useEffect(() => {
    fetchImages();
  }, [refresh]);

 useEffect(() => {
   // Ensure animalDetails has data before proceeding
   if (
     animalDetails?.id &&
     animalDetails?.created_at &&
     props.id &&
     !animalAlbum.find(
       (item) =>
         item.animal_id === animalDetails.id &&
         item.created_at === animalDetails.created_at
     )
   ) {
     const dateUploaded = new Date(animalDetails.created_at)
       .toISOString()
       .split("T")[0];

     const newRecord = {
       id: animalAlbum.length ? animalAlbum[0].id - 1 : 1,
       animal_id: animalDetails.id,
       date_uploaded: dateUploaded,
       images: animalDetails.image_paths,
       created_at: animalDetails.created_at,
       updated_at: animalDetails.updated_at,
     };

     const updatedImageData = [newRecord, ...animalAlbum];
     setAnimalAlbum(updatedImageData);
   }
 }, [animalDetails, animalAlbum, props.id]);


  return (
    <div>
      <Modal
        open={props.open}
        title="Animal Details"
        onOk={handleOk}
        width={900}
        onCancel={handleCancel}
        footer={[
          <>
            <button
              key="back"
              className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </button>
            {activeTabKey === "1" && !uploadNew && (
              <button
                className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                key="upload-button"
                onClick={() => setUploadNew(true)}
              >
                Add New Images{" "}
              </button>
            )}
            ,
            {activeTabKey === "1" && uploadNew && (
              <button
                onClick={handleSubmit}
                className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                type="button"
                key="create-button"
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
                ) : (
                  "Upload Images"
                )}
              </button>
            )}
          </>,
        ]}
      >
        <Tabs
          defaultActiveKey="2"
          onChange={handleTabChange}
          centered
          activeKey={activeTabKey}
        >
          <TabPane tab={<span className=" text-xl">Animal Album</span>} key="1">
            {uploadNew ? (
              <>
                <h1 className="text-xl mb-4">Upload Animal Images</h1>
                <div className="max-w-md mx-auto p-4">
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                      id="date"
                      name="date"
                      type="date"
                      value={formData.date}
                      onChange={handleChange}
                      placeholder=" "
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Date of Upload
                    </label>
                  </div>
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <textarea
                      className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0"
                      id="comment"
                      name="comment"
                      value={formData.comment}
                      onChange={handleChange}
                      placeholder="Add a comment"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleImageChange}
                      className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                    />
                  </div>
                  <div className="mt-2 flex space-x-4">
                    {formData.images?.map((image, index) => (
                      <div key={index} className="relative">
                        <img
                          src={image.previewURL}
                          alt={`animal ${index}`}
                          className="h-24 w-24 object-cover rounded-md"
                        />
                        <button
                          type="button"
                          onClick={() => handleDeleteImage(index)}
                          className="absolute top-0 right-0 rounded-full bg-white p-1 shadow-sm"
                        >
                          <svg
                            className="h-4 w-4 text-red-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <h1 className="text-2xl bg-gradient-to-r from-red-500 via-blue-500 to-green-500 text-transparent bg-clip-text">
                  Animal Album
                </h1>

                {loading ? (
                  <Skeleton.Image active={loading} />
                ) : (
                  animalAlbum?.map((animal) => (
                    <div
                      key={animal.id}
                      className="mb-6 p-4 bg-slate-100 rounded-lg shadow-md"
                    >
                      <div className="block text-lg font-medium mb-2">
                        Comment: {animal?.comment}
                      </div>
                      <ImageGallery images={JSON.parse(animal?.images)} />
                    </div>
                  ))
                )}
              </div>
            )}
          </TabPane>
          <TabPane tab={<span className=" text-xl">Details</span>} key="2">
            <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
              <div className="p-8">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-semibold text-indigo-500 lg:text-3xl uppercase tracking-wide">
                    {animalDetails?.breed_type?.name}
                  </div>
                  <div className="text-sm text-gray-500 lg:text-xl">
                    <span className="text-red-500">
                      {" "}
                      {animalDetails?.status?.toUpperCase()}
                    </span>
                  </div>
                </div>
                <h1 className="mt-2 text-2xl font-medium  text-black">
                  {animalDetails?.name}
                </h1>

                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <FaTag className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Tag Number:{" "}
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.tag_number}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaBirthdayCake className="text-gray-500  text-xl mr-2" />
                    <p className="text-gray-700 text-lg">
                      Date of Birth:{" "}
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.date_of_birth}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaAmericanSignLanguageInterpreting className="text-gray-500  text-xl mr-2" />
                    <p className="text-gray-700 text-lg">
                      Age:
                      <span className="text-blue-500">
                        {" "}
                        {calculateAge(animalDetails?.date_of_birth)}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaPalette className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Color Markings:{" "}
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.color_markings}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaWeight className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Weight:
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.weight} kg{" "}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaTint className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Purpose:{" "}
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.purpose?.name}{" "}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaTint className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Milk Production:{" "}
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.milk_litres_production || 0} L/day
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaHeartbeat className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Health Status:{" "}
                      <span className="text-blue-500">
                        {animalDetails?.health_status?.name}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Origin:
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.purchase_origin?.name}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <BsCalendar2DateFill className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Date Purchased / Recieved:
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.DOP}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <BiSolidPurchaseTag className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Purchase Price:
                      <span className="text-blue-500">
                        {" "}
                        {parseInt(
                          animalDetails?.purchase_price
                        ).toLocaleString()}
                      </span>
                    </p>
                  </div>

                  {animalDetails && (
                    <>
                      <div className="flex items-center">
                        <FaUser className="text-gray-500 mr-2 text-xl" />
                        <p className="text-gray-700 text-lg">
                          Previous Owner:{" "}
                          <span className="text-blue-500">
                            {" "}
                            {animalDetails?.previous_owner?.farmName}
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center">
                        <FaPhone className="text-gray-500 mr-2 text-xl" />
                        <p className="text-gray-700 text-lg">
                          Contact:
                          <span className="text-blue-500">
                            {" "}
                            {animalDetails?.previous_owner?.contact}
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Location:{" "}
                      <span className="text-blue-500">
                        {animalDetails?.location?.name}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaVenusMars className="text-gray-500 mr-2 text-xl" />
                    <p className="text-gray-700 text-lg">
                      Gender:
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.gender?.gender}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="container mx-auto p-4">
                <h1 className="text-xl font-semibold">Images</h1>
                {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {animalDetails?.image_paths && JSON.parse(animalDetails?.image_paths).map((image, index) => (
                    <div
                      key={index}
                      className="overflow-hidden rounded-lg shadow-lg"
                    >
                      <img
                        src={`${process.env.REACT_APP_PROD_IMG_URL}${image}`}
                        alt={`Animal ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div> */}
                <ImageGallery
                  images={
                    animalDetails?.image_paths &&
                    JSON.parse(animalDetails?.image_paths)
                  }
                />
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={<span className=" text-xl">Parent Information</span>}
            key="3"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-4 py-2">
              {/* Mother Details Column */}
              <div>
                <h1 className="bg-gradient-to-r from-red-500 via-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-xl">
                  Mother Details
                </h1>
                {animalDetails?.dam ? (
                  <>
                    {" "}
                    <span className="block text-lg">
                      Name :{" "}
                      <span className="text-blue-500">
                        {animalDetails?.dam?.name}
                      </span>
                    </span>
                    <span className="block text-lg">
                      {" "}
                      Tag Number :
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.dam?.tag_number}{" "}
                      </span>
                    </span>{" "}
                  </>
                ) : (
                  "None"
                )}
              </div>

              {/* Father Details Column */}
              <div>
                <h1 className="bg-gradient-to-r from-red-500 via-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-xl">
                  Father Details
                </h1>
                {animalDetails?.sire_id ? (
                  animalDetails?.sire ? (
                    <>
                      <span className="block text-lg">
                        Name :{" "}
                        <span className="text-blue-500">
                          {animalDetails?.sire?.name}
                        </span>
                      </span>
                      <span className="block text-lg">
                        {" "}
                        Tag Number :{" "}
                        <span className="text-blue-500">
                          {animalDetails?.sire?.tag_number}{" "}
                        </span>
                      </span>
                    </>
                  ) : (
                    <span className="block text-lg">
                      {" "}
                      Nature :
                      <span className="text-blue-500">
                        {" "}
                        {animalDetails?.sire_id}
                      </span>
                    </span>
                  )
                ) : (
                  "None"
                )}
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={<span className=" text-xl">Breeding History</span>}
            key="4"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-4 py-2">
              {animalDetails.length > 0 &&
              animalDetails.breeding_history.length > 0
                ? animalDetails.breeding_history.map((history, index) => (
                    <div key={index}>
                      <p className="text-gray-700 font-medium">Mating Date: </p>
                      <p> {history.mating_date}</p>
                      <p className="text-gray-700 font-medium">
                        Breeding Method:
                      </p>
                      <p> {history.breeding_method}</p>
                      <p className="text-gray-700 font-medium">
                        {" "}
                        Pregnancy Status:
                      </p>
                      <p> {history.pregnancy_status}</p>
                    </div>
                  ))
                : "No breeding History"}
            </div>
          </TabPane>
          <TabPane
            tab={<span className=" text-xl">Vaccination History</span>}
            key="5"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-4 py-2">
              {animalDetails &&
              animalDetails.vaccination_history &&
              animalDetails.vaccination_history.length > 0
                ? animalDetails.vaccination_history.map((vaccine, index) => (
                    <div key={index}>
                      <p className="text-gray-700 font-medium">Vaccine Name:</p>
                      <p> {vaccine.vaccine_name}</p>
                      <p className="text-gray-700 font-medium">
                        Vaccination Date:
                      </p>
                      <p> {vaccine.vaccination_date}</p>
                      <p className="text-gray-700 font-medium">Notes: </p>{" "}
                      <p> {vaccine.notes}</p>
                      <Divider />
                    </div>
                  ))
                : "No Vaccination History"}
            </div>
          </TabPane>
        </Tabs>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewAnimalDetails;
