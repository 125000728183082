import React, { useState } from "react";
import { Modal } from "antd";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import { API_BASE_URL } from "../../common/constants";
import { apiRequest, apiRequestWithImage } from "../../common/common";

function ReportDeathModal({ open, setOpen, setRefresh, animal }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    deathCause: "",
    deathDate: "",
    images: [],
  });
  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const validateForm = () => {
    const newErrors = {};

    if (!formData.deathCause) {
      newErrors.deathCause = "Cause of Death is required";
    }
    if (!formData.deathDate) {
      newErrors.deathDate = "Date Of Death is Required";
    }

    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const updatedImages = [...formData.images];

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const options = {
            maxSizeMB: 1, // Maximum size in MB
            maxWidthOrHeight: 800, // Maximum width or height
            useWebWorker: true, // Use multi-threading for better performance
          };

          const compressedFile = await imageCompression(file, options);
          const previewURL = URL.createObjectURL(compressedFile);

          updatedImages.push({ file: compressedFile, previewURL });

          if (index === files.length - 1) {
            setFormData((prevState) => ({
              ...prevState,
              images: updatedImages.slice(0, 3), // Limit to maximum 3 images
            }));
          }
        } catch (error) {
          console.error("Error while compressing the image:", error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const handleOk = () => {
    const data = new FormData();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);

      return;
    }
    setLoading(true);

    // const data = JSON.stringify({
    //     cause_of_death: formData.deathCause,
    //     date_of_death: formData.deathDate,
    //     images: formData.images
    // });

    data.append("cause_of_death", formData.deathCause);
    data.append("date_of_death", formData.deathDate);
    Object.keys(formData).forEach((key) => {
      if (key === "images") {
        formData.images.forEach((image) => {
          data.append("images[]", image.file);
        });
      }
    });
    const onSuccess = (response) => {
      toast.success(response.message); // Assuming the response contains a message property
      setLoading(false);
      setOpen(false);
      setRefresh(true);
      setFormData({
        deathCause: "",
        deathDate: "",
        images: [],
      })
    };

    const onError = (error) => {
      toast.error("Error occurred while marking the animal as dead");
      // toast.error(error?.response?.data?.message?.images[0])
      console.log(error);
      setLoading(false);
    };

    apiRequestWithImage(
      "post",
      `${API_BASE_URL}animals/dead/${animal?.tag_number}`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  return (
    <div>
      <Modal
        open={open}
        title="Report Death"
        onClick={handleCancel}
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="button"
            key="create-button"
            data-ripple-light="true"
            onClick={handleOk} // Attach onClick event handler to call handleOk function
            // disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : (
              "Mark as Dead"
            )}
          </button>,
        ]}
      >
        <div className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-4 flex flex-col gap-6">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                  errorMessage.deathCause && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.deathCause}
                name="deathCause"
                type="text"
              />
              {errorMessage.deathCause && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.deathCause}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Cause of Death
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                  errorMessage.deathDate && "border-red-500"
                }`}
                placeholder=" "
                onChange={handleChange}
                value={formData.deathDate}
                name="deathDate"
                type="date"
              />
              {errorMessage.deathDate && (
                <span className="text-xs text-red-500 mt-2">
                  {errorMessage.deathDate}
                </span>
              )}
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Date of Death
              </label>
            </div>
            <h1 className="text-xl mb-4">Upload Animal Images</h1>
            <div className="max-w-md mx-auto p-4">
              <div className="mb-4">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                />
              </div>
              <div className="mt-2 flex space-x-4">
                {formData.images?.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.previewURL}
                      alt={`Imag ${index}`}
                      className="h-24 w-24 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleDeleteImage(index)}
                      className="absolute top-0 right-0 rounded-full bg-white p-1 shadow-sm"
                    >
                      <svg
                        className="h-4 w-4 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>{" "}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

ReportDeathModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
  animal: PropTypes.object,
};
export default ReportDeathModal;
