import JSPDF from 'jspdf';
import 'jspdf-autotable';

const generatePDF = (tableColumns, tableRows, title, date, user, filter) => {
  const doc = new JSPDF('landscape');

  // ticket title. and margin-top + margin-left
  doc.text(title, 8, 15);
  doc.setFontSize(12).setFont('Helvetica');
  doc.text(`Date Printed: ${date}`, 8, 25);
  doc.text(`Generated By User: ${user}`, 8, 35);
  doc.text(`Filter(s): ${filter}`, 8, 45);

  // Calculate column widths based on content
  const columnWidths = tableColumns.map((col, index) => {
    const maxColumnWidth = 100; // Maximum column width in units (adjust as needed)
    const columnTexts = tableRows.map(row => {
      const value = row[index];
      if (value === null || value === undefined) {
        return ''; // Handle null or undefined values
      }
      return value.toString();
    });
    // eslint-disable-next-line max-len
    const maxTextWidth = Math.max(...columnTexts.map(text => doc.getStringUnitWidth(text) * doc.internal.getFontSize()));
    return Math.min(maxColumnWidth, maxTextWidth + 6); // Add some padding
  });

  // startY is basically margin-top
  doc.autoTable({
    columns: tableColumns,
    body: tableRows,
    startY: 60,
    columnWidth: columnWidths,
    theme: 'striped',
  });

  // Enable auto-print when the PDF is opened in a browser
  doc.autoPrint({ variant: 'non-conform' });

  // we define the name of our PDF file.
  doc.save(`${title}.pdf`);
};

export default generatePDF;
