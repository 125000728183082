import { Modal } from 'antd'
import React, { useState } from 'react'
import { API_BASE_URL } from '../../common/constants';
import { apiRequest } from '../../common/common';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/slice/authSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function PasswordReset({open, setOpen}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.username && !user?.data?.user.username) {
      newErrors.username = "User Name is required";
    }
    if (!formData.password) {
      newErrors.password = "Password is Required";
    }
    if (!formData.password_confirmation) {
      newErrors.password_confirmation = "Re-enter password";
    }

    if (formData.password !== formData.password_confirmation) {
      toast.error("passwords do not match");
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    const data = JSON.stringify({
      username: formData.username || user?.data?.user.username ,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    });

    const onSuccess = (responseData) => {
      toast.success(responseData.message);
      const navigateAfterDelay = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1500));
        await dispatch(logoutUser()); // Dispatch loginUser action with form data

        setLoading(false);
      setOpen(false);

        history.push("/login");
      };
      navigateAfterDelay();
      
    };

    const onError = (error) => {
      toast.error("Password reset failed");
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}auth/password/reset`,
      data,
      "",
      onSuccess,
      onError
    );
  };


  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Reset Password"
        footer={[
          <>
            {" "}
            <button
              className="mt-6 m-2  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
              data-ripple-light="true"
              onClick={handlePasswordReset}
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Reset Password"
              )}
            </button>
            <button className="mt-6  m-2 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
              Cancel
            </button>
          </>,
        ]}
      >
        <div>
          <p className="mt-1 block font-sans text-base font-normal leading-relaxed text-gray-700 antialiased">
            Set new password.
          </p>
          <form>
            <div className="mb-4 flex flex-col gap-6">
              <div className="relative h-11 w-full min-w-[200px]">
                <input
                  id="username"
                  name="username"
                  type="text"
                  disabled
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0  ${
                    errorMessage.username && "border-red-500"
                  }`}
                  placeholder=" "
                  onChange={handleChange}
                  value={formData.username || user?.data?.user.username}
                />
                {errorMessage.username && (
                  <span className="text-xs text-red-500 mt-2">
                    {errorMessage.username}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  UserName
                </label>
              </div>
              <div className="relative h-11 w-full min-w-[200px]">
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0  ${
                    errorMessage.password && "border-red-500"
                  }`}
                  placeholder=" "
                  onChange={handleChange}
                  value={formData.password}
                />
                {errorMessage.password && (
                  <span className="text-xs text-red-500 mt-2">
                    {errorMessage.password}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  New Password
                </label>
              </div>
              <div className="relative h-11 w-full min-w-[200px]">
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0  ${
                    errorMessage.password_confirmation && "border-red-500"
                  }`}
                  placeholder=" "
                  onChange={handleChange}
                  value={formData.password_confirmation}
                />
                {errorMessage.password_confirmation && (
                  <span className="text-xs text-red-500 mt-2">
                    {errorMessage.password_confirmation}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Repeat New Password
                </label>
              </div>
            </div>
          </form>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Modal>
    </div>
  );
}

export default PasswordReset