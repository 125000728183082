import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { toast, ToastContainer } from "react-toastify";
import {useHistory} from 'react-router-dom';
import { API_BASE_URL } from "../../../common/constants";
import {apiRequest} from "../../../common/common";

function CreateUserGroupModal(props) {

  const navigate = useHistory();
  const [role, setRole] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [step1FormData, setStep1FormData] = useState({
    name: "",
    display_name: "",
  });
  const [step2FormData, setStep2FormData] = useState({
    permissions: [],
  });
  const [errorMessage, setErrorMessage] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStep1FormData({ ...step1FormData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const validateStep1Form = () => {
    const newErrors = {};
    if (!step1FormData.name) {
      newErrors.name = "Name is required";
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCheckboxChange = (permId) => {
    // Check if the permission is already selected
    const index = step2FormData.permissions.indexOf(permId);
    if (index === -1) {
      // Permission not selected, add it to the array
      setStep2FormData((prevData) => ({
        ...prevData,
        permissions: [...prevData.permissions, permId],
      }));
    } else {
      // Permission already selected, remove it from the array
      setStep2FormData((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.filter((id) => id !== permId),
      }));
    }
  };

  const handleStep1Next = async () => {
    if (validateStep1Form()) {
      setLoading(true);
        const data = JSON.stringify({
          name: step1FormData.name,
          display_name: step1FormData.display_name,
        });
  
        const onSuccess = (response) => {
          setRole(response.role);
          setCurrentStep(2);
          setLoading(false);
          toast.success(response.message);
        };
  
        const onError = (error) => {
          toast.error("Error occurred while Creating Roles");
          setLoading(false);
        };
  
        await apiRequest(
          "post",
          `${API_BASE_URL}roles/create`,
          data,
          "",
          onSuccess,
          onError,
         
        );
    }
  };
  const handleStep2Submit = async () => {
    setLoading(true);

    const data = JSON.stringify({
      role_id: role.id,
      permission_ids: step2FormData.permissions,
    });
  
    const onSuccess = (response) => {
      toast.success(response.message);
      const navigateAfterDelay = async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
        props.setOpen(false);
        navigate.push("/account-types");
        props.setRefresh(true);
      };
      navigateAfterDelay();
    };
  
    const onError = (error) => {
      toast.error("Error occurred");
      setLoading(false);
    };
  
    await apiRequest(
      "post",
      `${API_BASE_URL}roles/role/2/permissions`,
      data,
      "",
      onSuccess,
      onError,
    );
  };

  //   fetch all permissions
  const getPermissions = async () => {
    const onSuccess = (response) => {
      setPermissions(response.permissions);
    };
  
    const onError = (error) => {
      toast.error("Error Occurred Fetching data");
    };
  
    await apiRequest(
      "get",
      `${API_BASE_URL}roles/permissions`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const handleOk = () => {
    if (currentStep === 1) {
      handleStep1Next();
    } else if (currentStep === 2) {
      handleStep2Submit();
    }
  };
  const handleCancel = () => {
    currentStep === 1 ? props.setOpen(false) : setCurrentStep(1);
  };

  useEffect(() => {
    getPermissions();
  }, []);
  return (
    <div>
      <Modal
        open={props.open}
        title={
          currentStep === 1
            ? "Step 1: Account Type information"
            : "Step 2: Assigning Permission/Right"
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button
            className="mt-6 mr-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            {currentStep === 1 ? "Cancel" : "Back"}
          </button>,
          <button
            className="mt-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            key="create-button"
            data-ripple-light="true"
            onClick={handleOk}
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : currentStep === 1 ? (
              "Next"
            ) : (
              "Create Account Type"
            )}
          </button>,
        ]}
      >
        {" "}
        <>
          {currentStep === 1 && (
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
              <div className="mb-4 flex flex-col gap-6">
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 ${
                      errorMessage.name && "border-red-500"
                    }`}
                    placeholder=" "
                    onChange={handleChange}
                    value={step1FormData.name}
                    type="text"
                    name="name"
                  />
                  {errorMessage.name && (
                    <span className="text-xs text-red-500 mt-2">
                      {errorMessage.name}
                    </span>
                  )}
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:}}">
                    Name
                  </label>
                </div>
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 ${
                      errorMessage.display_name && "border-red-500"
                    }`}
                    placeholder=" "
                    onChange={handleChange}
                    value={step1FormData.display_name}
                    type="text"
                    name="display_name"
                  />
                  {errorMessage.display_name && (
                    <span className="text-xs text-red-500 mt-2">
                      {errorMessage.display_name}
                    </span>
                  )}
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:}}">
                    Display Name
                  </label>
                </div>
              </div>
            </form>
          )}
          {currentStep === 2 && (
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
              {permissions.length === 0 ? (
                <p>No Permissions/Rights Found</p>
              ) : (
                permissions.map((perm, key) => {
                  return (
                    <div key={key} className="flex flex-row mt-2">
                      <input
                        type="checkbox"
                        id="cb1"
                        value="cb1"
                        onChange={() => handleCheckboxChange(perm.id)}
                        checked={step2FormData.permissions.includes(perm.id)}
                        className="appearance-none h-6 w-6 bg-gray-400 rounded-full  checked:bg-green-300 checked:scale-75transition-all duration-200 peer"
                      />
                      <div className="h-6 w-6 absolute rounded-full pointer-events-none peer-checked:border-green-300 peer-checked:border-2"></div>
                      <label
                        htmlFor="cb1"
                        className="flex flex-col justify-center px-2 peer-checked:text-green-400  select-none"
                      >
                        {perm.display_name}
                      </label>
                    </div>
                  );
                })
              )}
            </form>
          )}
        </>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CreateUserGroupModal;
